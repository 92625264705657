import React from 'react';
import { Typography, Stack } from '@mui/material';
import Calendar from '../components/calendar/Scheduler';


function UserCalendar() {
    return (
        <>
            <Stack>
                <Typography variant="h4" sx={{ margin: '20px 0 0 20px' }} >
                    Calendar
                </Typography>
            </Stack>
            <Calendar/>
        </>
    )
}

export default UserCalendar;