// // Function to fetch all appointments
import network_conn from "../utils/network_config";

export const fetchAppointments = async (fetchreq) => {
    console.log(fetchreq)
    try {

        const response = await fetch(network_conn.api_endpoint_v1+'calender_service/fetch_events', {

            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(fetchreq),
        });
        // if (!response.ok) {
        //   throw new Error('Failed to fetch appointments');
        // }
        const appointments = await response.json();
        const zus_event = await process_events(appointments);
        console.log(zus_event)
        return zus_event;
    } catch (error) {
        console.error('Error fetching appointments:', error.message);
        return [];
    }
};

// Function to add a new appointment
export const addAppointment = async (newAppointment) => {
    try {

        const response = await fetch(network_conn.api_endpoint_v1+'calender_service/insert_event', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newAppointment),
        });
        if (!response.ok) {
            throw new Error('Failed to add appointment');
        }
        const addedAppointment = await response.json();
        return addedAppointment;
    } catch (error) {
        console.error('Error adding appointment:', error.message);
        return null;
    }
};

// Function to update an existing appointment
export const updateAppointment = async (updatedAppointment) => {
    try {

        const response = await fetch(network_conn.api_endpoint_v1+'calender_service/update_events', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedAppointment),
        });
        if (!response.ok) {
            throw new Error('Failed to update appointment');
        }
        const updated = await response.json();
        return updated;
    } catch (error) {
        console.error('Error updating appointment:', error.message);
        return null;
    }
};

// Function to delete an appointment
export const deleteAppointment = async (deleteAppointment) => {
    try {

        const response = await fetch(network_conn.api_endpoint_v1+'calender_service/delete_event', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(deleteAppointment),
        });
        if (!response.ok) {
            throw new Error('Failed to delete appointment');
        }
        const deletedAppointment = await response.json();
        return deletedAppointment;
    } catch (error) {
        console.error('Error deleting appointment:', error.message);
        return false;
    }
};

// Example usage:
// Fetch all appointments
// fetchAppointments()
// .then(appointments => {
//   console.log('Appointments:', appointments);
// })
// .catch(error => {
//   console.error('Error:', error);
// });

const process_events = async (api_events) => {
    let zus_event = []
    for (let i = 0; i < api_events.data.length; i++) {
        const utcDate = new Date(api_events.data[i].session_details.session_start_date);
        const offsetMinutes = utcDate.getTimezoneOffset();
        const localTimestamp = new Date(utcDate.getTime() - (offsetMinutes * 60000));
        const localDateString = localTimestamp.toISOString();

        const utcDate1 = new Date(api_events.data[i].session_details.session_end_date);
        const offsetMinutese = utcDate1.getTimezoneOffset();
        const localTimestamp1 = new Date(utcDate1.getTime() - (offsetMinutese* 60000));
        const localDateString1 = localTimestamp1.toISOString();
        
        zus_event.push(
            {
                "id": api_events.data[i].session_details.meeting_id,
                "title": api_events.data[i].session_details.session_title,
                "startDate": localDateString,
                "endDate": localDateString1,
                "AllDay": "",
                "notes": api_events.data[i].session_details.session_desc,
                "customField": api_events.data[i].location_details.location_name,
                "InviteMentee": "",
            }
        )
    }
    return zus_event
};
