import React, { useState, useEffect } from 'react';
import {
    Grid,
    Avatar,
    TextField,
    InputAdornment,
    FormControl,
    InputLabel,
    OutlinedInput,
    IconButton,
    Button,
    Checkbox,
    Alert,
    Stack,
    Typography,
    Box,
    Divider
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { blue, red } from '@mui/material/colors';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from 'react-router';
import { activeEmail, fetchUser, loginUser } from '../../api/user_related_service';
import useAdminStore from '../../store/Admin_session';
import userSess from '../../store/user_session_control';
import Verify_Email from '../Forgot_Password/Verify_Email';
import { Link } from 'react-router-dom';
const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

function Login() {
    const { isAdmin } = useAdminStore();
    const [showPassword, setShowPassword] = useState(false);
    const [emailInput, setEmailInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const { updateSess, user_token } = userSess();
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [formValid, setFormValid] = useState();
    const [success, setSuccess] = useState();
    const [inputInvalid, setInputInvalid] = useState();
    const [forgotPass, setForgotPass] = useState(false);
    const [verifyCheck, setVerifyCheck] = useState(false);

    const handleForgot = () => {
        setForgotPass(true)
    }

    useEffect(() => {
        const rememberedEmail = localStorage.getItem('rememberedEmail');
        const rememberedPassword = localStorage.getItem('rememberedPassword');
        const rememberedChecked = localStorage.getItem('rememberMeChecked') === 'true';

        if (rememberedChecked) {
            setEmailInput(rememberedEmail);
            setPasswordInput(rememberedPassword);
            setRememberMe(rememberedChecked);
        }
    }, []);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const handleEmail = () => {
        if (!isEmail(emailInput)) {
            setEmailError(true);
            return;
        }
        setEmailError(false);
    };

    const handlePassword = () => {
        if (!passwordInput || passwordInput.length < 5 || passwordInput.length > 20) {
            setPasswordError(true);
            return;
        }
        setPasswordError(false);
    };

    const navigate = useNavigate();
    const handleSubmit = async () => {
        setSuccess(null);
        setInputInvalid(null)
        if (emailError || !emailInput) {
            setFormValid("Email is Invalid. Please Re-Enter");
            return;
        }

        if (passwordError || !passwordInput) {
            setFormValid("Password is set between 5 - 20 characters long. Please Re-Enter");
            return;
        }

        setFormValid(null);

        const d = {
            username: emailInput,
            password: passwordInput
        };

        try {
            const userExists = await fetchUser({ email: emailInput.trim() });
            console.log(userExists);
            const userData = userExists.data; // access the data property
            const user = userData.find((user) => user.email === emailInput.trim());

            if (user) {
                if (user.is_active) {
                    const aptS = await loginUser(d);

                    if (aptS.hasOwnProperty("access_token")) {
                        updateSess(aptS["access_token"]);
                        if (isAdmin) {
                            navigate('/d/Admin');
                        }
                        else {

                            navigate('/d/dashboard');
                        }
                        setSuccess("Form Submitted Successfully");
                        if (rememberMe) {
                            localStorage.setItem('rememberedEmail', emailInput);
                            localStorage.setItem('rememberedPassword', passwordInput);
                            localStorage.setItem('rememberMeChecked', true);
                        } else {
                            localStorage.removeItem('rememberedEmail');
                            localStorage.removeItem('rememberedPassword');
                            localStorage.removeItem('rememberMeChecked');
                        }
                    } else {
                        setInputInvalid("Your email or password is invalid");
                        setVerifyCheck(false);
                        return;
                    }
                    setInputInvalid(null)

                } else {
                    setInputInvalid("User is not verfied. Please");
                    setVerifyCheck(true);
                    return;
                }
                setInputInvalid(null)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setInputInvalid("Your email or password is invalid");
            return;
        }
    };

    const handleVerify = async () => {
        try {
            await activeEmail({
                email: emailInput.trim(),
            });
        } catch (error) {
            console.error('Error during email verification:', error);

        }
    }

    return (
        <>
            <Grid container direction="column" alignItems="center">
                <Grid container alignItems="center" direction="column">
                    <Avatar sx={{ bgcolor: '#2f3348' }}><LockIcon /></Avatar>
                    <Typography variant="h5" align="center" sx={{ marginBottom: '2rem' }}>LogIn</Typography>
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Your email"
                        error={emailError}
                        value={emailInput}
                        style={{ marginBottom: '1rem' }}
                        onBlur={handleEmail}
                        onChange={(e) => setEmailInput(e.target.value)}
                    />
                    <FormControl sx={{ width: '100%' }} variant="outlined" >
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            error={passwordError}
                            onBlur={handlePassword}
                            onChange={(event) => setPasswordInput(event.target.value)}
                            value={passwordInput}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '100%' }}>
                        <Stack direction="row" alignItems="center" >
                            <Checkbox
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            <Typography variant="body2">Remember Me</Typography>
                        </Stack>
                        <Link to={`/:forgot_pass`}>
                            <Button color="primary" size="small">Forgot Password?</Button>
                        </Link>

                    </Stack>
                    <Button variant="contained" fullWidth onClick={handleSubmit} sx={{ marginTop: '1rem' }}>LOGIN</Button>
                    {formValid && <Alert severity="error">{formValid}</Alert>}
                    {success && <Alert severity="success">{success}</Alert>}
                    {inputInvalid && <Alert severity="error" sx={{ display: "flex", alignItems: "center" }}>{inputInvalid} {verifyCheck && (<Link onClick={handleVerify} underline="hover" sx={{ ml: 1, ':hover': { cursor: 'pointer' } }} variant="body2">Verify Now</Link>)}</Alert>}

                    {/* {forgotPass ? (<Verify_Email/> ): (<></>)} */}
                </Grid>
                <Grid item>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '1rem' }}>
                        <Divider sx={{ width: '100%' }} />
                        <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>or connect with</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '0.5rem' }}>
                            <GoogleIcon sx={{ color: red[500] }} />
                            <MicrosoftIcon />
                            <GitHubIcon />
                            <LinkedInIcon color="primary" />
                            <FacebookIcon color="primary" />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Login;