import network_conn from "../utils/network_config";
export const addPlan = async (plan) => {
    try {
        const response = await fetch(network_conn.api_endpoint_v1+'plan_path/insert_plan', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(plan)
        });

        if (response.ok) {
            const responseData = await response.json();
            console.log('Data sent successfully:', responseData);
        } else {
            throw new Error('Failed to send data: ' + response.statusText);
        }
    } catch (error) {
        console.error('Error sending data:', error);
        throw error;
    }
};

//insert into existing plan path
export const addExistingPlan = async (plan) => {
    try {
        const response = await fetch(network_conn.api_endpoint_v1+'plan_path/insert_existing_plan', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(plan)
        });

        if (response.ok) {
            const responseData = await response.json();
            console.log('Data sent successfully:', responseData);
        } else {
            throw new Error('Failed to send data: ' + response.statusText);
        }
    } catch (error) {
        console.error('Error sending data:', error);
        throw error;
    }
};

export const fetch_plan_path = async (fetchreq) => {
    console.log(fetchreq);
    try {
        const response = await fetch(network_conn.api_endpoint_v1+'plan_path/fetch_plan_path', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(fetchreq),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch plan path');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching plan path:', error);
        throw error;
    }
};

export const deleteplanPath = async (plan) => {
    try {

        const response = await fetch(network_conn.api_endpoint_v1+'plan_path/delete_plan_path', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(plan),
        });
        if (!response.ok) {
            throw new Error('Failed to delete planPath');
        }
        const deletedPlanPath = await response.json();
        return deletedPlanPath;
    } catch (error) {
        console.error('Error deleting planPath:', error.message);
        return false;
    }
};