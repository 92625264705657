/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  useTheme,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  Tooltip,
  Menu,
  MenuItem,
  Badge,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
//Search
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import "../Menu/User_left_panel_drawer.css";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import MergeIcon from "@mui/icons-material/Merge";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import WorkIcon from "@mui/icons-material/Work";
import { useNavigate } from "react-router";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AccountCircle, AdminPanelSettings } from "@mui/icons-material";
import userSess from "../../store/user_session_control";
import { userLogout } from "../../api/user_related_service";
import { fetchAppointments } from "../../api/scheduler_related_services";
import useEventDataStore from "../../store/SchedulerData";
import { fetchDashSession } from "../../api/session_service";
import useUserPrefSess from "../../store/dash_session";
import { fetchUserMentSession } from "../../api/session_service";
import useUserMentorSess from "../../store/user_mentor_session";
import { fetch_plan_path } from "../../api/planPath_related_services";
import usePlanpath from "../../store/PlanPath_data";
import { fetchSponsor } from "../../api/sponsor_related_service";
import useStore from "../../store/sponsor_data";
import useAdminStore from "../../store/Admin_session";
import { fetchProfile } from "../../api/profile_related_service";
import useProfile from "../../store/profile_data";
import Notification from "../../components/Notification/notification";
import { fetchNotification } from "../../api/notification_related_service";
import DeleteAccount from "../delete_account/delete";
import { delete_account } from "../../api/delete_account";
import FeedbackIcon from '@mui/icons-material/Feedback';
import Cookies from 'js-cookie';

const drawerWidth = 240;

function GlobalNav({ render_user_ana, render_user_cal }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "80vh",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(3),
      width: "20vh",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = (toggle) => () => {
    setOpen(toggle);
  };

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));
  const settings = ["Profile", "Account", "Dashboard"];
  const navigate = useNavigate();
  const handleClick_plan = async () => {
    // const plan_path = await fetch_plan_path({ token: user_token });
    // setUser(plan_path);
    // plan_path.forEach((path) => {
    //   const { details, course, date } = path;
    //   usePlanpath.getState().add_row(details, course, date);
    // });

    navigate("course");
  };
  // const { user_token, updateSess } = userSess()
  const { setAppointments } = useEventDataStore();
  const { setuseUserPrefSess } = useUserPrefSess();
  const { setuseUserMentorSess } = useUserMentorSess();
  // if (user_token == ''){
  //   const user_token = localStorage.getItem("user_tk")
  //   updateSess(user_token)
  // }
  const user_token = Cookies.get("connectedMe")

  const handleClick_cal = async () => {
    const app_events = await fetchAppointments({ token: user_token });
    setAppointments(app_events);
    navigate("calendar");
  };
  const handleClick_ana = async () => {
    // const app_events = await fetchDashSession({ token: user_token });
    // setuseUserPrefSess(app_events);
    navigate("dashboard");
  };

  const handleClick_ses = async () => {
    // const app_events = await fetchAppointments({ token: user_token });
    // setAppointments(app_events);
    //User booked sessions
    // const ment_events = await fetchUserMentSession({ "token": user_token })
    // setuseUserMentorSess(ment_events)
    navigate("UserSess");
  };

  const handleClick_c = () => {
    navigate("UserCourse");
  };

  const handleClick_recc = () => {
    navigate("UserRecom");
  };

  const { setUser } = useProfile();
  const handleClick_m = async () => {
    navigate("MyProfile");
    handleMenuClose();
  };

  const { setSponsorData } = useStore();
  const handleClick_s = async () => {
    const jobData = await fetchSponsor({ user_token: user_token });
    setSponsorData(jobData);
    navigate("Sponsor");
  };

  const handleClick_KPI = () => {
    navigate("KPI");
  };

  const handleClick_j = () => {
    navigate("Job");
  };

  const handleClick_ac = () => {
    navigate("Admincourse");
  };

  const handleClick_feedback = () => {
    navigate("create-feedback-form");
  };
  

  const { isAdmin, setIsAdmin } = useAdminStore();
  console.log(isAdmin, "isAdmin");
  const handleClick_A = () => {
    navigate("Admin");
  };
  const handlelogout = () => {
    localStorage.removeItem("user_tk");
    localStorage.removeItem("isAdmin");
    userLogout();
    navigate("/");
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteloading, setDeleteLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleOpenDelete = () => {
    setOpenDeleteDialog(true);
  };
  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };
  const handleConfirmDelete = async () => {
    handleCloseDelete();
    setDeleteLoading(true);
    try {
      const fetchreq = { token: user_token };
      const response = await delete_account(fetchreq);

      setTimeout(() => {
        setDeleteLoading(false);
      }, 500);

      if (response.data && response.data.status === "success") {
        setSnackbarMessage("Account successfully deleted!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        setTimeout(() => {
          handlelogout();
        }, 2000);
      } else {
        setSnackbarMessage("Failed to delete the account. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setTimeout(() => {
        setDeleteLoading(false);
      }, 500);
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const [anchorE2, setAnchorE2] = useState(null);
  const handleOpenNotification = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleCloseNotification = (event) => {
    setAnchorE2(null);
  };

  const [notifications, setNotifications] = useState([]); // Store notification data
  const [unreadCount, setUnreadCount] = useState(0); // State for unread notifications count
  const [loading, setLoading] = useState(true); // State for loading notifications

  // Fetch notifications when the application loads
  React.useEffect(() => {
    const fetchInitialNotifications = async () => {
      try {
        const fetchReq = { token: user_token };
        const response = await fetchNotification(fetchReq);
        const notificationData = response.data || [];
        setNotifications(
          Array.isArray(notificationData) ? notificationData : []
        );
        const unreadCount = notificationData.filter(
          (notification) => !notification.read_status
        ).length;
        setUnreadCount(unreadCount);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
        setLoading(false);
      }
    };
    fetchInitialNotifications();
    // Optional: Set up polling to regularly update the notification count
    const intervalId = setInterval(fetchInitialNotifications, 60000); // Update every 60 seconds
    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);
  const [drawView, setDrawView] = React.useState(true);
  const handleDrawer_view = (toggle) => () => {
    setDrawView(toggle);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {settings.map((setting) => (
        <MenuItem
          key={setting}
          onClick={setting === "Profile" ? handleClick_m : null}
        >
          <Typography textAlign="center">{setting}</Typography>
        </MenuItem>
      ))}
      <MenuItem onClick={handlelogout}>Logout</MenuItem>
      <MenuItem onClick={handleOpenDelete}>Delete Account </MenuItem>
      <DeleteAccount
        open={openDeleteDialog}
        onClose={handleCloseDelete}
        onConfirm={handleConfirmDelete}
      />
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right", // Align with the right edge
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right", // Ensure it opens from the right side
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      sx={{
        "& .MuiPaper-root": {
          marginTop: "43px", // Adjust the top margin
          right: 0, // Align to the right edge of the screen
          left: "auto", // Prevent left alignment pushing it away
          position: "fixed", // Fix the position to the viewport
          width: "150px",
          marginRight: "0px",
        },
      }}
    >
      <MenuItem onClick={handleClick_m} sx={{ padding: "8px" }}>
        <IconButton
          size="medium"
          aria-label="account of current user"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          color="inherit"
          sx={{ padding: "4px" }}
        >
          <AccountCircleOutlinedIcon sx={{ p: 0, mr: 1, fontSize: "20px" }} />{" "}
          {/* Reduce icon size */}
        </IconButton>
        <Typography sx={{ fontSize: "14px" }}>Profile</Typography>{" "}
        {/* Reduce font size */}
      </MenuItem>

      <MenuItem sx={{ padding: "8px" }}>
        <IconButton
          onClick={handleOpenNotification}
          color="inherit"
          size="medium" // Reduce icon button size for mobile
          sx={{ padding: "4px" }} // Reduce padding around icon
        >
          <Badge color="error" badgeContent={unreadCount}>
            <NotificationsNoneIcon sx={{ mr: 2, fontSize: "20px" }} />{" "}
            {/* Reduce icon size */}
          </Badge>
        </IconButton>
        <Typography sx={{ fontSize: "14px" }}>Notifications</Typography>{" "}
        {/* Reduce font size */}
      </MenuItem>

      <MenuItem sx={{ padding: "8px" }}>
        <IconButton
          size="medium" // Reduce icon button size for mobile
          aria-label="show 4 new mails"
          color="inherit"
          sx={{ padding: "4px" }} // Reduce padding around icon
        >
          <Badge>
            <ForumOutlinedIcon sx={{ mr: 1, fontSize: "20px" }} />{" "}
            {/* Reduce icon size */}
          </Badge>
        </IconButton>
        <Typography sx={{ fontSize: "14px" }}>Messages</Typography>{" "}
        {/* Reduce font size */}
      </MenuItem>

      <MenuItem onClick={handlelogout} sx={{ padding: "8px" }}>
        <IconButton
          size="medium" // Reduce icon button size for mobile
          aria-label="logout"
          color="inherit"
          sx={{ padding: "4px" }} // Reduce padding around icon
        >
          <Badge>
            <LogoutIcon sx={{ mr: 1, fontSize: "20px" }} />{" "}
            {/* Reduce icon size */}
          </Badge>
        </IconButton>
        <Typography sx={{ fontSize: "14px" }}>Logout</Typography>{" "}
        {/* Reduce font size */}
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {isSmallScreen ? (
        <AppBar
          position="fixed"
          xs={12}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            "background-color": "#2f3348",
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Stack direction={"row"}>
              <IconButton onClick={handleDrawerOpen(!open)}>
                <MenuIcon />
              </IconButton>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Stack>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreVertIcon />
              </IconButton>
              {renderMobileMenu}
              {renderMenu}
            </Box>
          </Toolbar>
        </AppBar>
      ) : (
        <AppBar
          position="fixed"
          sx={{
            width: drawView
              ? `calc(100% - 49px)`
              : `calc(100% - ${drawerWidth}px)`,
            ml: drawView ? `49px` : `${drawerWidth}px`,
            "background-color": "#2f3348",
            transition: "all 0.5s ease-out",
          }}
        >
          <Toolbar>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
            {/* </Toolbar> */}
            {/* <Box sx={{ flexGrow: 1 }} /> */}
            <Box
              sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
            >
              <ForumOutlinedIcon sx={{ mr: 1 }} />
              {/* <InfoOutlinedIcon sx={{ mr: 1 }} /> */}
              <IconButton onClick={handleOpenNotification} color="inherit">
                <Badge color="error" badgeContent={unreadCount}>
                  <NotificationsNoneIcon />
                </Badge>
              </IconButton>
              <Notification
                anchorEl={anchorE2}
                handleClose={handleCloseNotification}
                notifications={notifications}
                loading={loading}
                setNotifications={setNotifications}
                setUnreadCount={setUnreadCount}
                user_token={user_token}
              />
              <Tooltip title="Open settings" sx={{ mr: 1 }}>
                <AccountCircleOutlinedIcon
                  onClick={handleProfileMenuOpen}
                  sx={{ p: 0, ml: 2 }}
                />
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                // anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                // open={Boolean(anchorElUser)}
                // onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>

              {renderMenu}
            </Box>
          </Toolbar>
        </AppBar>
      )}
      {/* ---DRAWER--- */}
      {isSmallScreen ? (
        <Drawer
          className="drawerwrapper"
          variant="tempopary"
          anchor="left"
          open={open}
          // onClose={handleDrawerOpen(false)}
          onClose={handleDrawerOpen(false)}
          sx={{
            width: "190px",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "190px",
              boxSizing: "border-box",
              marginTop: "56px",
            },
          }}
        >
          <Stack direction={"column"} mt={1} onClick={handleDrawerOpen(false)}>
            <Button
              key="one"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={handleClick_ana}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <GridViewOutlinedIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Dashboard
                </Typography>
              </Stack>
            </Button>
            <Button
              key="two"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={handleClick_cal}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <CalendarMonthIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Calendar
                </Typography>
              </Stack>
            </Button>

            <Button
              key="four"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={handleClick_plan}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <MergeIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Career Path
                </Typography>
              </Stack>
            </Button>
            <Button
              key="four"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={handleClick_ses}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <LaptopMacIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  My Bookings
                </Typography>
              </Stack>
            </Button>
            <Button
              key="four"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={handleClick_recc}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <StarBorderIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Recommendation
                </Typography>
              </Stack>
            </Button>
            <Button
              key="four"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={handleClick_c}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <AutoStoriesIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Courses
                </Typography>
              </Stack>
            </Button>

            <Button
              key="four"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={handleClick_s}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <MonetizationOnIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  Sponsor
                </Typography>
              </Stack>
            </Button>

            <Button
              key="four"
              sx={{
                borderBottom: "0",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={handleClick_KPI}
            >
              <Stack spacing={1} direction={"row"} sx={{ color: "white" }}>
                <AutoGraphIcon />
                <Typography sx={{ textTransform: "capitalize" }}>
                  KPI
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Drawer>
      ) : (
        <Drawer
          className="drawerwrapper"
          sx={{
            width: drawView ? "50px" : drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawView ? "50px" : drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {/* Drawer Header */}
          {drawView ? (
            <Toolbar
              sx={{
                "padding-left": "0px",
              }}
            >
              <IconButton
                sx={{
                  color: "white",
                  display: "flex",
                  "justify-content": "center",
                  transform: "translateX(-20px)",
                }}
                onClick={handleDrawer_view(false)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Toolbar>
          ) : (
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block", color: "white" } }}
              >
                TUSSP
              </Typography>
              <IconButton
                sx={{
                  "margin-left": "auto",
                  color: "white",
                }}
                onClick={handleDrawer_view(true)}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Toolbar>
          )}
          {drawView ? (
            <Stack direction={"column"}>
              {isAdmin ? (
                <>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_A}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <AdminPanelSettings />
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_j}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <WorkIcon />
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_ac}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <AutoStoriesIcon />
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_feedback}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <FeedbackIcon />
                    </Stack>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    key="one"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_ana}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <GridViewOutlinedIcon />
                    </Stack>
                  </Button>
                  <Button
                    key="two"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_cal}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <CalendarMonthIcon />
                    </Stack>
                  </Button>
                  {/* <Button
                    key="three"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_m}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <PersonIcon />
                    </Stack>
                  </Button> */}
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_plan}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <MergeIcon />
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_ses}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <LaptopMacIcon />
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_recc}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <StarBorderIcon />
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_c}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <AutoStoriesIcon />
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_s}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <MonetizationOnIcon />
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_KPI}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <AutoGraphIcon />
                    </Stack>
                  </Button>
                </>
              )}
            </Stack>
          ) : (
            <Stack direction={"column"}>
              {isAdmin ? (
                <>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_A}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <AdminPanelSettings />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Admin
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_j}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <WorkIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Jobs
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <AutoStoriesIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Courses
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_feedback}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <FeedbackIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Create Feedback
                      </Typography>
                    </Stack>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    key="one"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_ana}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <GridViewOutlinedIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Dashboard
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="two"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_cal}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <CalendarMonthIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Calender
                      </Typography>
                    </Stack>
                  </Button>
                  {/* <Button
                    key="three"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_m}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <PersonIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Profile
                      </Typography>
                    </Stack>
                  </Button> */}
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_plan}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <MergeIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Career Path
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_ses}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <LaptopMacIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        My Bookings
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_recc}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <StarBorderIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Recommendation
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_c}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <AutoStoriesIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Courses
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_s}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <MonetizationOnIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Sponsor
                      </Typography>
                    </Stack>
                  </Button>
                  <Button
                    key="four"
                    sx={{
                      borderBottom: "0",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={handleClick_KPI}
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ color: "white" }}
                    >
                      <AutoGraphIcon />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        KPI
                      </Typography>
                    </Stack>
                  </Button>
                </>
              )}
            </Stack>
          )}
        </Drawer>
      )}
      {deleteloading && (
        <Box
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <CircularProgress color="secondary" size={60} />
        </Box>
      )}
      {/* Snackbar for success message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Account successfully Deleted!
        </Alert>
      </Snackbar>
    </>
  );
}

export default GlobalNav;
