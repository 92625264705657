import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Avatar, Box, Button, Card, Stack, Typography, Dialog, DialogContent, DialogActions, TextField, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router';
import NotificationIcon from '@mui/icons-material/Notifications';
import InfoIcon from '@mui/icons-material/Info';
import { deleteUserMentSession, addNotification } from '../../api/session_service';
import userSess from '../../store/user_session_control';
import useUserMentorSess from '../../store/user_mentor_session';
import {RescheduleMailResponse} from '../../api/notification_related_service'
import Cookies from 'js-cookie';

function CardGridView() {
    const navigate = useNavigate();
    const user_token = Cookies.get("connectedMe")
    const [sessions, setSessions] = useState([]);
    const { sess_men } = useUserMentorSess();

    useEffect(() => {
        setSessions(sess_men);
    }, [sess_men]);

    const [selectedSession, setSelectedSession] = useState(null);
    const [open, setOpen] = useState(false);

    const [openReasonDialog, setOpenReasonDialog] = useState(false);
    const [unbookReason, setUnbookReason] = useState('');
    const [sessionToUnbook, setSessionToUnbook] = useState(null);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = useState('xs');
    const handleClose = () => {
        setOpen(false);
    };

    const handleAccept = (meetingId) => {
        addNotification({ 'meeting_id': meetingId, 'token': user_token, 'is_ack': true });
        RescheduleMailResponse({'meeting_id': meetingId, 'token': user_token,'response_message':'accepted'})
        handleClose();
    };
    const handleDecline =(meetingId) => {
        addNotification({ 'meeting_id': meetingId, 'token': user_token, 'is_ack': true });
        RescheduleMailResponse({'meeting_id': meetingId, 'token': user_token,'response_message':'declined'})
        handleClose();
    }

    const handleCardAction = () => {
        navigate('cardD', { state: { name: 'Session' } });
    };

    const handleUnbookRequest = (session) => {
        setSessionToUnbook(session);
        setOpenReasonDialog(true);
    };

    const handleUnbookConfirm = async () => {
        if (sessionToUnbook) {
            await deleteUserMentSession({ 'meeting_id': sessionToUnbook.meeting_id, 'token': user_token, 'reason': unbookReason });
            setSessions(prevSessions => prevSessions.filter(session => session.meeting_id !== sessionToUnbook.meeting_id));
            setOpenReasonDialog(false);
            setUnbookReason('');
        }
    };


    const StyledCard = styled(Card)(({ theme }) => ({
        padding: theme.spacing(1),
        border: '1px solid #2f3348',
        borderRadius: theme.spacing(1),
        boxShadow: '0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }));

    const NotificationContainer = styled(Box)(({ theme }) => ({
        height: '32px',
    }));

    const convertToLocalDate = (utrDateString) => {
        const utcDate = new Date(utrDateString);
        const localDate = utcDate.toLocaleDateString();
        const localTime = utcDate.toLocaleTimeString();
        return `${localDate} ${localTime}`;
    };

    return (
        <>
            <Grid2 container spacing={2}>
                <Grid2 item xs={12}>
                    <Grid2 container justifyContent="space-evenly" spacing={5}>
                        {sessions.map(s =>
                            <Grid2 key={s.meeting_id} item>
                                <StyledCard sx={{ 'width': '350px' }}>
                                    <NotificationContainer>
                                        {s.has_notification && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row-reverse',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectedSession(s);
                                                        setOpen(true);
                                                    }}
                                                    style={{ color: 'black' }}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </NotificationContainer>
                                    <Stack sx={{ 'margin': '10px' }} gap={1}>
                                        <Typography variant="body1">{s.title}</Typography>
                                        <Box sx={{ 'width': '130px', 'height': '130px' }}>
                                            <Typography variant='subtitle1'>{s.Desc.length > 40 ? `${s.Desc.substring(0, 40)}...` : s.Desc}</Typography>
                                        </Box>
                                        <Typography variant="overline" sx={{ 'color': '#f65a4c' }}>{convertToLocalDate(s.startDate)}</Typography>
                                        <Button key={s.meeting_id} variant="contained" onClick={() => handleUnbookRequest(s)}>UNBOOK</Button>
                                    </Stack>
                                    <Stack direction={'row'} gap={1} sx={{
                                        'height': '16%',
                                        'width': '100%',
                                        'background': '#1d1933'
                                    }}>
                                        <Avatar sx={{ margin: '5px' }} src={s.image ? s.image : undefined} />
                                        <Stack>
                                            <Typography variant='body2' sx={{ 'color': '#ffffff' }}>{s.Session_author}</Typography>
                                            <Typography variant='caption' sx={{ 'color': '#ffffff' }}>{s.Session_auth_job_title}</Typography>
                                        </Stack>
                                    </Stack>
                                </StyledCard>
                            </Grid2>
                        )}
                    </Grid2>
                </Grid2>
            </Grid2>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Typography variant="h6" color="error">{`Your session has been rescheduled`}</Typography>
                    {selectedSession && (
                        <Box textAlign="center" marginTop={2}>
                            <Typography>{`Start Date: ${convertToLocalDate(selectedSession.updatedStartDate)}`}</Typography>
                            <Typography>{`End Date: ${convertToLocalDate(selectedSession.updatedEndDate)}`}</Typography>
                        </Box>
                    )}
                    <Box textAlign="center" marginTop={2}>
                        {selectedSession && (
                            <>
                            <Button
                                    variant="outlined"
                                    onClick={() => handleDecline(selectedSession.meeting_id)} 
                                >
                                    Decline
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => handleAccept(selectedSession.meeting_id)}
                                    style={{ marginLeft: '10px' }} 
                                >
                                    Accept
                                </Button>
                                
                            </>
                        )}
                    </Box>

                </DialogContent>
            </Dialog>
            <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={openReasonDialog} onClose={() => setOpenReasonDialog(false)}>
                <DialogContent>
                    <Typography variant="h6">Reason for Unbooking</Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Reason"
                        fullWidth
                        variant="standard"
                        value={unbookReason}
                        multiline
                        maxRows={4}
                        onChange={(e) => setUnbookReason(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenReasonDialog(false)} color="primary">Cancel</Button>
                    <Button onClick={handleUnbookConfirm} color="primary" disabled={!unbookReason.trim()}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CardGridView;
