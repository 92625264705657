import { create } from "zustand";

const useProfile = create((set) => ({
  user: {
    "first_name": "",
    "middle_name": "",
    "last_name": "",
    "email_id": "",
    "secondary_email": "",
    "phone_country_code": "",
    "phone_number": null,
    "date_of_birth": "",
    "gender": "",
    skills: [
      {
        skill_name: "",
        skill_level: 0,
      },
    ],
    "professional_experience": [
      {
        "jobTitle": "",
        "organization": "",
        "startYear": "",
        "endYear": "",
        "still_working": null,
      },
    ],
    "education": [
      {
        "institution": "",
        "course": "",
        "year": null,
      },
    ],
    "certification": [
      {
        "certificateTitle": "",
        "issuer": "",
        "certificateNo": "",
        "issuedate": "",
        "expireDate": "",
      },
    ],
    professional_ambitions: [
      {
        skill_name: "",
        skill_level: 0,
      },
    ],
    "currentRole":"",
    "desirableRole":"",
  },
  setUser:(newUser)=>set({user:newUser}),
  // updateUser: (newUserData) =>
  //   set((state) => ({ user: { ...state.user, ...newUserData } })),

}));

export default useProfile;