import { create } from 'zustand';

const useEventDataStore = create((set) => ({
  appointments: [
    {
      "id": "",
      "title": "",
      "startDate": "",
      "endDate": "",
      "AllDay": "",
      "notes": "",
      "customField": "",
      "InviteMentee": "",
    },
  ],
  setAppointments: (newAppointments) => set({ appointments: newAppointments }),
  setMentorSessions: (newMentorSessions) => set({ mentorSessions: newMentorSessions }),
}));

export default useEventDataStore;