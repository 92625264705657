import { IconButton, Stack, Typography } from '@mui/material';
import * as React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useLocation, useNavigate } from 'react-router';

function UserCardInfo() {
    const page_Ref = useLocation();
    const navigate = useNavigate();
    const handlenav = () => {
        navigate(-1)
    };
    return (
        <>
            <Stack gap={2}>
                <Stack gap={2} direction={'row'}>
                    <IconButton onClick={handlenav}>
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <Typography>
                        {page_Ref.state.name}
                    </Typography>
                </Stack>
                <Typography variant="body1">Online Session</Typography>
                <Typography variant='h6'>Project Planning:</Typography>
                <Typography variant='subtitle1'>Define project Scope</Typography>
                <Typography variant="overline" sx={{ 'color': '#f65a4c' }}>February 20th(Tue) at 6:00pm</Typography>
                <Typography variant='body2' sx={{ 'color': '#ffffff' }}>John Smith</Typography>
                <Typography variant='caption' sx={{ 'color': '#ffffff' }}>Manager Sales</Typography>
            </Stack>
        </>
    )
}

export default UserCardInfo;