import network_conn from "../utils/network_config";

export const fetchRecommendedMentors = async (fetchreq) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 +
        "recommendation_service/mentor_recommendation",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchreq),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch sponsor");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
export const fetchRecommendedCourses = async (fetchreq) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 +
        "recommendation_service/course_recommendation",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchreq),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch sponsor");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const Book_mentor = async (fetchreq) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 +
        "recommendation_service/book_mentor",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchreq),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch sponsor");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
export const Book_courses = async (fetchreq) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "course_service/insert_booked_courses",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchreq),
      }
    );

    if (!response.ok) {
      const errorMessage = await response.text(); 
      throw new Error(`Failed to book course: ${errorMessage}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in Book_courses:", error); 
    throw error;
  }
};

export const fetchBookedMentors = async (fetchreq) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 +
        "recommendation_service/fetch_booked_mentors",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchreq),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch sponsor");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};