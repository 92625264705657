import React, { useEffect, useState } from "react";
import {
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
  TextField,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  FormControl,
  NativeSelect,
  Alert,
  Snackbar,
  Chip,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Optionbar from "../Optionbar/Optionbar";
import { MoreVert } from "@mui/icons-material";
import {
  fetchCoursePublish,
  insertCoursePublish,
  updateCourseStatus,
} from "../../api/course_publish_related_service";
import Archivecourse from "./Archivecourse";
import Appliedcourse from "./Appliedcourse";
import Closedcourse from "./Closedcourse";
import Confirmation from "../Optionbar/Confirmation";
import Cookies from 'js-cookie';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Admincourse = () => {
  const user_token = Cookies.get("connectedMe")
  const [open, setOpen] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [link, setLink] = useState("");
  const [tags, setTags] = useState("");
  const [details, setDetails] = useState("");
  const [status, setStatus] = useState("Open");
  const [alert, setAlert] = useState(false);
  const [value, setValue] = useState("1");
  const [courseData, setCourseData] = useState([]);
  const [confirm, setConfirm] = useState(false); // State for confirmation dialog
  const [showSnackbar, setShowSnackbar] = useState(false); // State for Snackbar

  const handleChange = (event, newValue) => {
    setValue(newValue);
    fetchCoursePublishData();
  };

  useEffect(() => {
    fetchCoursePublishData();
  }, []);

  const fetchCoursePublishData = async () => {
    try {
      const response = await fetchCoursePublish();
      setCourseData(response);
    } catch (error) {
      console.error("Error fetching course data", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePublish = () => {
    return new Promise((resolve, reject) => {
      const payload = {
        course_name: courseName,
        course_link: link,
        course_tag: tags,
        course_details: details,
        course_status: status,
      };

      insertCoursePublish({ ...payload, token: user_token })
        .then(() => {
          handleClose();
          setCourseName("");
          setLink("");
          setTags("");
          setDetails("");
          fetchCoursePublishData().then(resolve).catch(reject);
        })
        .catch((error) => {
          console.error("Error publishing course:", error);
          reject(error);
        });
    });
  };

  const handleAlert = () => {
    setAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  const handleConfirm = () => {
    setConfirm(true);
  };

  const handleConfirmYes = () => {
    handlePublish().then(() => {
      setConfirm(false);
      setShowSnackbar(true);
      return fetchCoursePublishData();
    });
  };

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#2f3348",
      },
    },
  });

  const handleStatusChange = async (event, courseId) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    const payload = {
      course_id: courseId,
      course_status: newStatus,
      // token: userToken,
    };

    updateCourseStatus({ ...payload, token: user_token })
      .then(() => {
        fetchCoursePublishData();
      })
      .catch((error) => {
        console.error("Failed to insert mentee matches:", error);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Stack>
          <Typography variant="h4" sx={{ margin: "20px 0 0 20px" }}>
            Courses
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ margin: "0 0 20px 20px", color: "grey" }}
          >
            Explore Our Top Courses: Expertly Crafted Learning Journeys for Your
            Success
          </Typography>
        </Stack>
        {courseData.length === 0 ? (
          <Stack
            sx={{
              height: "75vh",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h5">No Courses Available</Typography>
          </Stack>
        ) : (
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Active Courses" value="1" />
                  <Tab label="Archive Courses" value="2" />
                  <Tab label="Applied Courses" value="3" />
                  <Tab label="Closed Courses" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <TableContainer sx={{ margin: "5px" }} component={Paper}>
                  <Table>
                    <TableHead sx={{ backgroundColor: "#2f3348" }}>
                      <TableRow sx={{ display: "flex" }}>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            flex: 1,
                            height: "50px",
                            borderRight: "1px solid #e0e0e0",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Course Name
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            flex: 1,
                            height: "50px",
                            borderRight: "1px solid #e0e0e0",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Course Id
                        </TableCell>
                        <TableCell
                          sx={{
                            flex: 1,
                            fontWeight: "bold",
                            color: "white",
                            borderRight: "1px solid #e0e0e0",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Link
                        </TableCell>
                        <TableCell
                          sx={{
                            flex: 1,
                            fontWeight: "bold",
                            color: "white",
                            borderRight: "1px solid #e0e0e0",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Tag
                        </TableCell>
                        <TableCell
                          sx={{
                            flex: 2,
                            fontWeight: "bold",
                            color: "white",
                            borderRight: "1px solid #e0e0e0",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Details
                        </TableCell>
                        <TableCell
                          sx={{
                            flex: 1,
                            fontWeight: "bold",
                            color: "white",
                            borderRight: "1px solid #e0e0e0",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Rating
                        </TableCell>
                        <TableCell
                          sx={{
                            flex: 1,
                            fontWeight: "bold",
                            color: "white",
                            borderRight: "1px solid #e0e0e0",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {courseData.map(
                        (course, index) =>
                          course.course_status === "Open" && (
                            <TableRow key={index} sx={{ display: "flex" }}>
                              <TableCell
                                sx={{
                                  flex: 1,
                                  borderRight: "1px solid #e0e0e0",
                                }}
                              >
                                {course.course_name}
                              </TableCell>
                              <TableCell
                                sx={{
                                  flex: 1,
                                  borderRight: "1px solid #e0e0e0",
                                }}
                              >
                                {course.course_id}
                              </TableCell>
                              <TableCell
                                sx={{
                                  flex: 1,
                                  borderRight: "1px solid #e0e0e0",
                                }}
                              >
                                <a
                                  href={course.course_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {course.course_link}
                                </a>
                              </TableCell>
                              <TableCell
                                sx={{
                                  flex: 1,
                                  borderRight: "1px solid #e0e0e0",
                                }}
                              >
                                {course.course_tag.map((tag, index) => (
                                  <Chip
                                    key={index}
                                    label={tag}
                                    sx={{
                                      margin: "2px",
                                      // backgroundColor: "#2f3348",
                                    }}
                                  />
                                ))}
                              </TableCell>
                              <TableCell
                                sx={{
                                  flex: 2,
                                  borderRight: "1px solid #e0e0e0",
                                }}
                              >
                                {course.course_details}
                              </TableCell>
                              <TableCell
                                sx={{
                                  flex: 1,
                                  borderRight: "1px solid #e0e0e0",
                                }}
                              >
                                NA
                                {/* {course.course_rating} */}
                              </TableCell>
                              <TableCell
                                sx={{
                                  flex: 1,
                                  borderRight: "1px solid #e0e0e0",
                                }}
                              >
                                <Stack
                                  display={"flex"}
                                  flexDirection={"row"}
                                  justifyContent={"space-between"}
                                >
                                  <Stack>
                                    <FormControl fullWidth>
                                      <NativeSelect
                                        defaultValue={course.course_status}
                                        inputProps={{
                                          name: "Status",
                                          id: "status",
                                        }}
                                        onChange={(event) =>
                                          handleStatusChange(
                                            event,
                                            course.course_id
                                          )
                                        } // Add this onChange handler
                                      >
                                        <option value="Open">Open</option>
                                        <option value="Close">Close</option>
                                        <option value="Archive">Archive</option>
                                      </NativeSelect>
                                    </FormControl>
                                  </Stack>
                                  <Stack display={"flex"} flexDirection={"row"}>
                                    <IconButton
                                      size="large"
                                      aria-label="display more actions"
                                      edge="end"
                                      color="inherit"
                                    >
                                      <MoreVert />
                                    </IconButton>
                                  </Stack>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value="2">
                <Archivecourse />
              </TabPanel>
              <TabPanel value="3">
                <Appliedcourse />
              </TabPanel>
              <TabPanel value="4">
                <Closedcourse />
              </TabPanel>
            </TabContext>
          </Box>
        )}
        <Stack onClick={handleClickOpen}>
          <Optionbar onClick={handleClickOpen} />
        </Stack>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          sx={{
            "& .MuiDialog-paper": {
              minWidth: "800px",
            },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Add Courses
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: 3,
              }}
            >
              <Stack sx={{ width: "300px" }}>
                <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
                  Course Name*:
                </Typography>
                <TextField
                  autoFocus
                  type="text"
                  variant="standard"
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                />
              </Stack>
              <Stack sx={{ width: "300px", marginTop: "20px" }}>
                <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
                  Link*:
                </Typography>
                <TextField
                  type="text"
                  variant="standard"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </Stack>
              <Stack sx={{ width: "300px" }}>
                <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
                  Tags*:
                </Typography>
                <TextField
                  type="text"
                  variant="standard"
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1" sx={{ marginBottom: "5px" }}>
                  Details*:
                </Typography>
                <TextField
                  type="text"
                  variant="standard"
                  multiline
                  rows={4}
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </Stack>
            </Box>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 3,
              }}
            >
              <Stack
                sx={{ display: "flex", width: "100%" }}
                direction={"row"}
                p={2}
                justifyContent={"space-between"}
              >
                <Button
                  onClick={() => {
                    if (courseName && link && details && tags) {
                      handleConfirm();
                    } else {
                      handleAlert();
                    }
                  }}
                  variant="contained"
                >
                  Publish
                </Button>
                <Button onClick={handleClose} variant="contained">
                  Close
                </Button>
              </Stack>
            </DialogActions>
          </DialogContent>
        </BootstrapDialog>
        {confirm && (
          <Confirmation
            open={confirm}
            onClose={handleConfirmClose}
            submit={handleConfirmYes}
          />
        )}

        <Snackbar
          open={showSnackbar} // Render Snackbar when showSnackbar is true
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Course Publish Successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={alert}
          autoHideDuration={2000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Please fill the details!
          </Alert>
        </Snackbar>
      </Stack>
    </ThemeProvider>
  );
};

export default Admincourse;
