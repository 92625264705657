import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
// import Mentor_nav from '../components/Navbar/global_nav';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import MainLayout from "../components/Navbar/home_page_nav";
import { Outlet } from "react-router";

function UserDash() {
  const [usePref, setUserPref] = React.useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const render_user_cal = () => {
    setUserPref(false);
  };
  const render_user_ana = () => {
    setUserPref(true);
  };
  const paddingStyles = {
    paddingLeft: isSmallScreen ? `0px` : `52px`,
  };
  return (
    <>
      <Box>
        {/* <Mentor_nav/> */}
        <MainLayout
          render_user_ana={render_user_ana}
          render_user_cal={render_user_cal}
        />
        <Grid2
          container
          align-items="stretch"
          direction={isSmallScreen ? "column" : "row"}
          spacing={1}
          sx={{
            "margin-top": "0px",
            background: "#ffffff",
            "padding-top": "60px",
            ...paddingStyles,
          }}
        >
          <Grid2
            item
            sx={{
              "box-shadow": "-4px 0px 10px -3px rgba(0,0,0,0.75)",
            }}
            lg={15.8}
            md={12}
            xs={12}
          >
            <Outlet />
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
}
export default UserDash;
