import React, { useRef, useState } from 'react'
import Jobform from './Jobform';
import Optionbar from '../Optionbar/Optionbar';
import { Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, IconButton, InputLabel, Menu, MenuItem, NativeSelect, Paper, Radio, RadioGroup, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import useStore from '../../store/sponsor_data';
import { Delete, ModeEdit, MoreVert } from '@mui/icons-material';
import { addJob, getJob, update_status } from '../../api/job_advertisement_related_service';
import userSess from '../../store/user_session_control';
import ArchiveJobs from './ArchiveJobs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const CloseJobs = (props) => {
    const Jobevents = props.Jobevents;
const col = ['Starting Date', 'Job Id', 'Role', 'Mode', 'Description', 'Skill Match', 'Status'];
  return (
    <>
       <Stack>

<Table className="fullWidthTable" sx={{ minWidth: 350 }} style={{ fontSize: '3rem' }}>

    <TableHead sx={{ backgroundColor: '#2f3348' }}>
        <TableRow>

            {[...Array(7)].map((_, index) => (
                <TableCell key={index} style={{ fontWeight: 'bold', width: '10px', height: '50px', borderRight: '1px solid #e0e0e0', color: 'white' }}>{col[index]}</TableCell>
            ))}
        </TableRow>
    </TableHead>
    <TableBody>
        {
            Jobevents && Jobevents.map(index => {
                if (index.status === 'Close') {
                    return (
                        <TableRow >

                            <TableCell style={{verticalAlign:"top"}} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_date} </TableCell>
                            <TableCell style={{verticalAlign:"top"}} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_id}</TableCell>
                            <TableCell style={{verticalAlign:"top"}} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_role}</TableCell>
                            <TableCell style={{verticalAlign:"top"}} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_mode}</TableCell>
                            <TableCell style={{verticalAlign:"top"}} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_description}</TableCell>
                            <TableCell style={{verticalAlign:"top"}} sx={{ borderRight: '1px solid #e0e0e0' }}>
                            {index.advertisement_skill_match && index.advertisement_skill_match.split(',').map((skill, index) => (
                                                    <Chip key={index} label={skill} style={{ marginRight: 8 }}/>
                                                ))}
                            </TableCell>

                            <TableCell style={{ borderRight: '1px solid #e0e0e0' }}>
                                <Stack display={'fex'} flexDirection={'row'} justifyContent={'space-between'}>
                                    <Stack>
                                        <FormControl fullWidth>
                                            {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                State
                                            </InputLabel> */}
                                            <NativeSelect
                                                defaultValue={index.status}

                                                inputProps={{
                                                    name: 'Status',
                                                    id: 'status',

                                                }}
                                                onChange={(event) => {
                                                    let id = index.advertisement_id
                                                    console.log(id + "id")
                                                    props.handleChange(event, id)
                                                }}
                                            >
                                                <option value="Open" >Open</option>
                                                <option value="Close" >Close</option>
                                                <option value="Archive" >Archive</option>


                                            </NativeSelect>
                                        </FormControl>
                                    </Stack>
                                    <Stack display={'fex'} flexDirection={'row'}>

                                        <IconButton
                                            size="large"
                                            aria-label="display more actions"
                                            edge="end"
                                            color="inherit"
                                        >
                                            <MoreVert onClick={(event) => {

                                                props.handleClick(event);
                                            }} />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </TableCell>

                        </TableRow >
                    )
                }
            }

            )
        }


    </TableBody>
</Table>
</Stack>
    </>
  )
}

export default CloseJobs
