import {
  Alert,
  Avatar,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import { Navigate, useNavigate, useParams } from 'react-router';
//   import { useNavigate, useParams } from 'react-router';/
import { blue } from "@mui/material/colors";
import { updatePassword, validateOTP } from "../../api/user_related_service";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const isPasswordValid = (password) => {
  const criteria = [
    /.{8,}/,
    /[A-Z]/,
    /[a-z]/,
    /[0-9]/,
    /[!@#$%^&*(),.?":{}|<>]/
  ];
  return criteria.every((regex) => regex.test(password));
};

const Set_Password = () => {
  const navigate = useNavigate();
  const { emailInput } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [formValid, setFormValid] = useState();
  const [passwordInput, setPasswordInput] = useState('');
  // const [verify, setVerify] = useState(false);
  const [confirmPasswordInput, setConfirmPasswordInput] = useState('');
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const [open, setOpen] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };

  const handlePassword = () => {
    if (!isPasswordValid(passwordInput)) {
      setPasswordError(true);
      return;
    }
    setPasswordError(false);
  };

  const handleConfirmPassword = () => {
    if (confirmPasswordInput !== passwordInput) {
      setConfirmPasswordError(true);
      return;
    }
    setConfirmPasswordError(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleClick = async () => {
    handlePassword();
    handleConfirmPassword();
    if (passwordError || !passwordInput) {
      setFormValid("Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.");
      return;
    }
    if (confirmPasswordError || !confirmPasswordInput) {
      setFormValid("Password does not match.");
      return;
    }
    setFormValid(null);

    try {

      const response = await updatePassword({
        email: emailInput,
        password_hash: confirmPasswordInput,

      })
      if (response["message"] == "Your Password can not be same as old password.") {
        setFormValid("Your Password can not be same as old password.");
      } else {
        setFormValid(null);
        setOpen(true)
        navigate("/")
      }

    } catch (error) {
      console.log(error.message);
    }
  }
  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Snackbar open={open} autoHideDuration={8000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            Password Updated
          </Alert>
        </Snackbar>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Paper style={{ height: "100%", padding: "20px" }}>
              <h2>Advertisement</h2>
              <p>Ad content goes here...</p>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper style={{ height: "100%", padding: "20px" }}>
              <Grid container alignItems="center" direction="column">
                <Avatar sx={{ bgcolor: "#2F3348" }}>
                  <LockIcon />
                </Avatar>
                <Typography
                  variant="h5"
                  align="center"
                  sx={{ marginBottom: "2rem" }}
                >
                  Set Password
                </Typography>
              </Grid>
              <Stack display={"flex"}>
                <FormControl sx={{ width: '100%' }} variant="outlined" >
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    error={passwordError}
                    onBlur={handlePassword}
                    onChange={(event) => setPasswordInput(event.target.value)}
                    value={passwordInput}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <FormControl sx={{ width: '100%', marginTop: '1rem' }} variant="outlined" >
                  <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                  <OutlinedInput
                    error={confirmPasswordError}
                    onBlur={handleConfirmPassword}
                    onChange={(event) => setConfirmPasswordInput(event.target.value)}
                    value={confirmPasswordInput}
                    type={showConfirmPassword ? 'text' : 'password'}
                    label="Confirm Password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {formValid && (<Alert severity="error">
                  <Stack display={'flex'} direction={'row'}>
                    {formValid}


                  </Stack>
                </Alert>)}
                <Stack style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} margin={"20px"}>
                  <Button variant="contained" onClick={handleClick} style={{ width: '100px' }}>
                    Update
                  </Button>
                  {/* <Button variant="contained" onClick={()=>{ navigate(-1)}} style={{width: '100px'}}> Back</Button> */}
                </Stack>

                {/* {verified && (
                <Stack style={{ display: 'flex',flexDirection: 'row', justifyContent: 'space-around' }}>
                <Button variant="contained" style={{width: '100px'}}>
                  Next
                </Button>
                <Button variant="contained" style={{width: '100px'}} onClick={handleBack}> Back</Button>
                </Stack>
              )} */}
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Set_Password
