import * as React from "react";
import {
  Avatar,
  Box,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

function CardListView() {
  return (
    <TableContainer sx={{ margin: "5px" }} component={Paper}>
      <Table>
        <TableHead sx={{ backgroundColor: "#2f3348"  }}>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", color: "white", borderRight: '1px solid #e0e0e0' }}>
              Date & Time
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "white" , borderRight: '1px solid #e0e0e0'  }}>
              Mode
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "white" , borderRight: '1px solid #e0e0e0'  }}>
              Task
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "white" , borderRight: '1px solid #e0e0e0'  }}>
              Description
            </TableCell>

            <TableCell sx={{ fontWeight: "bold", color: "white" , borderRight: '1px solid #e0e0e0'  }}>
              Mentor
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[0, 1, 2, 3, 4, 5].map((value) => (
            <TableRow key={value}>
              <TableCell sx={{ borderRight: '1px solid #e0e0e0' }}>
                <Stack direction="column" spacing={1}>
                  <Typography>Jan 9, 2014</Typography>
                  <Typography>10:00 AM</Typography>
                </Stack>
              </TableCell>
              <TableCell sx={{ borderRight: '1px solid #e0e0e0' }}>Online Session</TableCell>
              <TableCell sx={{ borderRight: '1px solid #e0e0e0' }}>Project Planning</TableCell>
              <TableCell sx={{ borderRight: '1px solid #e0e0e0' }}>Define project Scope</TableCell>

              <TableCell sx={{ borderRight: '1px solid #e0e0e0' }} component="th" scope="row">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Avatar />
                  <Typography>John Smith</Typography>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CardListView;
