import { Outlet, Navigate } from 'react-router-dom'
import { userValidate } from '../api/user_related_service'
import Cookies from 'js-cookie';

const PrivateRoutes = () => {
    let user_token = ""
    if(Cookies.get("connectedMe")){
        user_token = userValidate(Cookies.get("connectedMe"));
    }
    return(
        user_token != "" ? <Outlet/> : <Navigate to="/"/>
    )
}

export default PrivateRoutes