import network_conn from "../utils/network_config";

export const fetchSkills = async (fetchreq) => {
    try {
        const response = await fetch(network_conn.api_endpoint_v1+'profile_service/fetch_skills', {

            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(fetchreq),
        });
        const skill_list = await response.json();
        return skill_list;
    } catch (error) {
        console.error('Error fetching skill_list:', error.message);
        return [];
    }
};

export const fetchRoles = async () => {
    try {
        const response = await fetch(network_conn.api_endpoint_v1+'fetch_sfia_role/fetch_role', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const roleList = await response.json();
        return roleList.roles;
    } catch (error) {
        console.error('Error fetching roles:', error.message);
        return [];
    }
};

export const fetchCurrentSkills = async (roleId) => {
    try {
        const response = await fetch(network_conn.api_endpoint_v1 + 'fetch_sfia_role/fetch_skills', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ role_id: roleId }), 
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const skill_list = await response.json();
        return skill_list.skills || [];
    } catch (error) {
        console.error('Error fetching skill_list:', error.message);
        return [];
    }
};

export const fetchDesirableSkills = async (roleId) => {
    try {
        const response = await fetch(network_conn.api_endpoint_v1 + 'fetch_sfia_role/fetch_desirable_skill', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ role_id: roleId }), 
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const skill_list = await response.json();
        return skill_list.skills || [];
    } catch (error) {
        console.error('Error fetching skill_list:', error.message);
        return [];
    }
};

export const fetchcountry = async (fetchreq) => {
    try {
        const response = await fetch(network_conn.api_endpoint_v1+'profile_service/fetch_country', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(fetchreq),
        });
        const country_list = await response.json();
        return country_list;
    } catch (error) {
        console.error('Error fetching country_list:', error.message);
        return [];
    }
};

// fetch flash card details
export const fetchSkillGap = async ({ user_token, skill }) => {
    try {
        const response = await fetch(network_conn.api_endpoint_v1 + 'flash_card/skillcard', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_token, skill })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching skill gap:', error.message);
        return { skill_level_gap: 0, total_desirable_skills: 0 }; 
    }
};

