import network_conn from "../utils/network_config";

export const fetchSponsor = async (fetchreq) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "sponsor_service/fetch_sponsor",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchreq),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch sponsor");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchMentee = async (fetchreq) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/fetch_mentee",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchreq),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch sponsor");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const insertSponsor = async (profile) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/insert_sponsor",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profile),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
    } else {
      throw new Error("Failed to send data: " + response.statusText);
    }
  } catch (error) {
    throw error;
  }
};

export const insertMenteeMatches = async (profile) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/insert_mentee_matches",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profile),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
    } else {
      throw new Error("Failed to send data: " + response.statusText);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchMenteeMacthes = async (fetchreq) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/fetch_mentee_matches",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchreq),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch sponsor");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const removeMenteeMacthes = async (fetchreq) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/remove_mentee_matches",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchreq),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch sponsor");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const addMentee = async (Mentee) => {
  console.log(Mentee);
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/insert_job_application",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Mentee),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    } else {
      throw new Error("Failed to add Mentee: " + response.statusText);
    }
  } catch (error) {
    throw error;
  }
};
