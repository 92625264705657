import { React, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Card,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import { styled } from "@mui/system";
import { fetchBookedMentors } from "../../api/recommendation_related_service";
import Cookies from 'js-cookie';

function BookedMentors() {
  const [mentor, setMentor] = useState([]); // Initialize as an empty array
  const user_token = Cookies.get("connectedMe")
  const navigate = useNavigate();

  useEffect(() => {
    fetchRecommendedMentorData();
  }, []);

  const fetchRecommendedMentorData = async () => {
    try {
      const response = await fetchBookedMentors({ token: user_token });
      setMentor(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error("Error fetching recommended mentors:", error);
      setMentor([]);
    }
  };

  const handleMentorClick = (mentorData) => {
    navigate(`/d/dashboard/mentorProfile`, { state: { mentorData } });
  };

  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(1),
    border: "1px solid #2f3348",
    borderRadius: theme.spacing(1),
    boxShadow: "0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)",
    width: isMobileScreen ? "80%" : isSmallScreen ? "25%" : "20%",
    margin: "10px auto",
  }));

  return (
    <Box sx={{ width: "100%", marginLeft: isMobileScreen ? "0px" : "60px" }}>
      {mentor.length === 0 ? (
        <StyledCard
          sx={{
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <PeopleIcon
              sx={{ fontSize: 64, color: "green", marginBottom: "20px" }}
            />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#333", marginBottom: "10px" }}
            >
              No Mentor Available
            </Typography>
          </Box>
        </StyledCard>
      ) : (
        Array.isArray(mentor) &&
        mentor.map(
          (
            mentorData,
            index // Ensure mentor is an array before mapping
          ) => (
            <StyledCard
              key={index}
              sx={{ height: "350px", display: "flex", flexDirection: "column" }}
            >
              <Stack sx={{ flexGrow: 1, justifyContent: "center" }}>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  justifyContent="center"
                  gap={1}
                >
                  {mentorData.skills.map((skill, skillIndex) => (
                    <Badge
                      key={skillIndex}
                      badgeContent={skill.skill_level}
                      color="error"
                      sx={{
                        "& .MuiBadge-badge": {
                          right: 7,
                          top: 7,
                          border: `2px solid white`,
                          padding: "0 2px",
                        },
                      }}
                    >
                      <Chip
                        label={skill.skill_name}
                        sx={{
                          margin: 1,
                          fontSize: "0.75rem",
                          height: "24px",
                          "& .MuiChip-label": { padding: "0 8px" },
                        }}
                      />
                    </Badge>
                  ))}
                </Stack>
              </Stack>

              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  background: "#1d1933",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={() => handleMentorClick(mentorData)}
              >
                <Avatar sx={{ marginRight: "10px" }} src={mentorData.image} />
                <Stack>
                  <Typography variant="body2" sx={{ color: "#fff" }}>
                    {mentorData.first_name} {mentorData.last_name}
                  </Typography>
                  <Typography variant="caption" sx={{ color: "#ccc" }}>
                    {mentorData.professional_info}
                  </Typography>
                </Stack>
              </Stack>
            </StyledCard>
          )
        )
      )}
    </Box>
  );
}

export default BookedMentors;
