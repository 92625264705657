import React from 'react';
import { Typography, Stack } from '@mui/material';
import Sponsor from '../components/Sponsor/Sponsor';


function UserSponsor() {
    return (
        <>
            <Stack>
                <Typography variant="h4" sx={{ margin: '20px 0 0 20px' }} >
                    Sponsor
                </Typography>
                <Typography variant="subtitle2" sx={{ margin: '0 0 20px 20px', color: 'grey' }}>
                    Meet Our Valued Sponsors: Powering Success Through Collaborative Partnerships
                </Typography>
            </Stack>
            <Sponsor/>
        </>
    )
}

export default UserSponsor;