import * as React from "react";
import {
  Box,
  Button,
  Card,
  Typography,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { fetchBookedCourse } from "../../api/course_publish_related_service";
import Cookies from 'js-cookie';

function BookedCourses() {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [bookedCourses, setBookedCourses] = React.useState([]);

  React.useEffect(() => {
    const user_token = Cookies.get("connectedMe")

    const BookedCourseData = async () => {
      try {
        const response = await fetchBookedCourse({ token: user_token });
        // Check if response has data and set bookedCourses accordingly
        if (response && response.data && Array.isArray(response.data)) {
          setBookedCourses(response.data);
        } else {
          setBookedCourses([]);
        }
      } catch (error) {
        console.error("Error fetching booked courses:", error);
      }
    };

    BookedCourseData();
  }, []);

  const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(1),
    border: "1px solid #2f3348",
    borderRadius: theme.spacing(1),
    boxShadow: "0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)",
    width: isMobileScreen ? "280px" : "300px",
    display: "flex",
    flexDirection: "column",
    height: "300px",
    position: "relative",
  }));

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1400px",
        margin: "0 auto",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "16px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {bookedCourses.length > 0 ? (
          bookedCourses.map((courseData, index) => (
            <StyledCard key={index}>
              <Box sx={{ p: 1.5, borderBottom: "1px solid #2f3348" }}>
                <Tooltip title={courseData.course_name}>
                  <Typography
                    variant="h6"
                    component="div"
                    noWrap
                    sx={{ fontSize: "1.1rem" }}
                  >
                    {courseData.course_name}
                  </Typography>
                </Tooltip>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  overflow: "auto",
                  p: 1.5,
                  mb: 12,
                  "&::-webkit-scrollbar": { width: "6px" },
                  "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "0.875rem" }}
                >
                  {courseData.course_details.length > 200
                    ? `${courseData.course_details.substring(0, 200)}...`
                    : courseData.course_details}
                </Typography>
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  p: 1.25,
                  borderTop: "1px solid #D3D3D3",
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                  backgroundColor: "white",
                }}
              >
                <Tooltip title="UNBOOK COURSE">
                  <Button
                    variant="outlined"
                    sx={{
                      border: "1px solid #2f3348",
                      fontSize: "0.75rem",
                      padding: "6px 12px",
                    }}
                  >
                    <DeleteOutlineIcon sx={{ fontSize: "1.2rem" }} />
                  </Button>
                </Tooltip>
                <Button
                  variant="contained"
                  component={Link}
                  to={courseData.course_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    backgroundColor: "#2f3348",
                    fontSize: "0.75rem",
                    padding: "6px 12px",
                    "&:hover": { backgroundColor: "#3b3f55" },
                  }}
                >
                  Start Course
                </Button>
              </Box>
            </StyledCard>
          ))
        ) : (
          <Typography variant="body1" color="text.secondary">
            No booked courses found.
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default BookedCourses;
