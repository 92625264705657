import network_conn from "../utils/network_config";

export const insertCoursePublish = async (profile) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "course_publish_service/insert_course",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profile),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      // Use responseData here
      console.log("Data inserted successfully:", responseData);
    } else {
      throw new Error("Failed to send data: " + response.statusText);
    }
  } catch (error) {
    throw error;
  }
};

export const updateCourseStatus = async (profile) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "course_publish_service/update_status",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profile),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      // Use responseData here
      console.log("Data inserted successfully:", responseData);
    } else {
      throw new Error("Failed to send data: " + response.statusText);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCoursePublish = async (fetchreq) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "course_publish_service/fetch_course",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchreq),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch sponsor");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
export const fetchBookedCourse = async (fetchreq) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "course_service/fetch_booked_courses",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchreq),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch sponsor");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
