import network_conn from "../utils/network_config";
import Cookies from 'js-cookie';

export const addUser = async (userData) => {
  const response = await fetch(network_conn.api_auth_endpoint + "sign_up", {
    method: "POST",
    body: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const totalUser = async (userData) => {
  const response = await fetch(
    network_conn.api_endpoint_v1 + "user/total_user",
    {
      method: "POST",
      body: JSON.stringify(userData),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.json();
};

export const loginUser = async (loginData) => {
  let formData = new FormData();
  for (const key in loginData) {
    if (loginData.hasOwnProperty(key)) {
      formData.append(key, loginData[key]);
    }
  }

  const response = await fetch(network_conn.api_auth_endpoint + "passauth", {
    method: "POST",
    body: formData,
    credentials: 'include'
  });
  const rp = await response.json();
  if (rp.hasOwnProperty("access_token")) {
    localStorage.setItem("user_tk", rp.access_token);
    Cookies.set('connectedMe', rp.access_token, { domain: '.tussp.com', secure: true, sameSite: 'None' });
    return rp;
  } else {
    return { login: "unsuccessfull" };
  }
};

export const userValidate = async (tk_check) => {
  console.log("USER VALIDATE TOKEN");
  const res_body = { tk: tk_check };
  const response = await fetch(network_conn.api_endpoint_v1 + "user_validate", {
    method: "POST",
    body: JSON.stringify(res_body),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const rp = await response.json();
  if (rp.status == "active") {
    return rp.token;
  } else {
    return null;
  }
};

export const userLogout = async () => {
  const response = await fetch(network_conn.api_auth_endpoint + "logout", {
    method: "POST",
  });
};

export const fetchUser = async (fetchreq) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "user/fetch_user",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchreq),
      }
    );
    const country_list = await response.json();
    return country_list;
  } catch (error) {
    console.error("Error fetching user_list:", error.message);
    return [];
  }
};

export const verifyEmail = async (req) => {
  try {
    console.log(req);
    
    const response = await fetch(
      network_conn.api_endpoint_v1 + "validation_service/update_status",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
        
      }
    );
    let responseData = null;
    if (response.ok) {
      responseData = await response.json();
      // console.log("Status update successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to update status: " + response.statusText);
    }
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};

export const activeEmail = async (req) => {
  try {
    console.log(req);
    
    const response = await fetch(
      network_conn.api_endpoint_v1 + "validation_service/verify_email",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
        
      }
    );
    let responseData = null;
    if (response.ok) {
      responseData = await response.json();
      console.log("Status update successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to update status: " + response.statusText);
    }
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};

export const validateEmail = async (req) => {
  try {
    console.log(req);
    
    const response = await fetch(
      network_conn.api_endpoint_v1 + "forgot_password/create_otp",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
        
      }
    );
    let responseData = null;
    if (response.ok) {
      responseData = await response.json();
      console.log("Status update successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to update status: " + response.statusText);
    }
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};

export const validateOTP = async (req) => {
  try {
    console.log(req);
    
    const response = await fetch(
      network_conn.api_endpoint_v1 + "forgot_password/validate_otp",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
        
      }
    );
    let responseData = null;
    if (response.ok) {
      responseData = await response.json();
      console.log("Status update successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to update status: " + response);
    }
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};

export const updatePassword = async (req) => {
  try {
    console.log(req);
    
    const response = await fetch(
      network_conn.api_endpoint_v1 + "forgot_password/update_password",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
        
      }
    );
    let responseData = null;
    if (response.ok) {
      responseData = await response.json();
      console.log("Status update successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to update status: " + response);
    }
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};