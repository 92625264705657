import React from "react";
import Avatar from "@mui/material/Avatar";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ArrowBackOutlined } from '@mui/icons-material'
import { Typography, Box, Chip, Badge, Button, IconButton } from "@mui/material";
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { formatDistance } from "date-fns";

const MentorProfilePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const mentorData = location.state?.mentorData;
    const calculateDuration = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = endDate ? new Date(endDate) : new Date();
        return formatDistance(start, end, { addSuffix: false });
    };
    function convertDateToMonthYear(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'short' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        return formattedDate;
    }
    return (
        <>
            <IconButton aria-label="back" size="large" onClick={() =>
                navigate(-1)
            }>
                <ArrowBackOutlined fontSize='large' color='#000000' />
            </IconButton>
            <Box
                sx={{
                    maxWidth: "800px",
                    margin: "0 auto",
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "white",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: 2,
                    }}
                >
                    <Avatar
                        sx={{
                            height: "150px",
                            width: "150px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            marginBottom: 2,
                            border: "4px solid #2f3348",
                        }}
                        alt={mentorData?.first_name}
                        src={mentorData?.image}
                    />
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
                        {mentorData?.first_name} {mentorData?.last_name}
                    </Typography>
                    <Typography variant="subtitle1">
                        {mentorData?.professional_info}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 2,
                    }}
                >
                    {mentorData.location && (
                        <>
                            <LocationOnIcon sx={{ marginRight: "5px" }} />
                            <Typography variant="subtitle2">{mentorData?.location}</Typography>
                        </>

                    )}

                </Box>
            </Box>
            {/* *********************Experience*************** */}
            <Box
                sx={{
                    maxWidth: "800px",
                    margin: "0 auto",
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "white",
                    marginTop: "10px"
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: 'bold' }} gutterBottom>Experience</Typography>
                {mentorData?.experience?.map((ex, index) => (
                    <Box sx={{ marginLeft: "40px" ,marginTop: 2, marginBottom:2,}}>
                        <Box>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{ex.organization}</Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="subtitle2" sx={{ marginRight: "4px" }}>Full-time .</Typography>
                                <Typography variant="subtitle2">{calculateDuration(ex.start_date, ex.end_date)}</Typography>
                            </Box>
                        </Box>

                        <Box >
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{ex.job_title} </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="subtitle2" sx={{ marginRight: "4px" }}>{convertDateToMonthYear(ex.start_date)} -</Typography>
                                <Typography variant="subtitle2">{ex.end_date ? convertDateToMonthYear(ex.end_date) : "Present"}</Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
            {/* *********************Skills*************** */}
            <Box
                sx={{
                    maxWidth: "800px",
                    margin: "0 auto",
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "white",
                    marginTop: "10px"
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: 'bold' }} gutterBottom>Skills</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", gap: 2, marginLeft: "40px" }}>
                    {mentorData?.skills?.slice(0, 3).map((skill, index) => (
                        <Badge
                            key={index}
                            color="error"
                            badgeContent={skill.skill_level}
                        >
                            <Chip label={skill.skill_name} />
                        </Badge>
                    ))}
                </Box>
                {mentorData?.skills?.length > 3 && (
                    <Link to={`/d/dashboard/mentorProfile/mentorProfile`} state={{ skills: mentorData.skills }} style={{ textDecoration: "none", width: "100%" }}>
                        <Button
                            variant="outlined"
                            sx={{
                                width: "100%",
                                marginTop: 2,
                                borderBlockColor: "#2f3348",
                                color: "#2f3348",
                                "&:hover": {
                                    backgroundColor: "#2f3348",
                                    borderColor: "#2f3348",
                                    color: "#ffffff",
                                },
                            }}
                        >
                            Show all {mentorData?.skills.length} skills <ArrowRightAltIcon />
                        </Button>
                    </Link>
                )}

            </Box>

        </>
    );
};

export default MentorProfilePage;
