import { create } from 'zustand';
import { format } from 'date-fns';


const useStore = create((set) => ({

  SponsorData: [
   
  ],
  selectedAvatars: [
  
  ],



  setSelectedAvatars: (index, selectedIndexes) =>
    set((state) => {
      const updatedSelectedAvatars = [...state.setSelectedAvatars];
      updatedSelectedAvatars[index].selected = selectedIndexes;
      return { selectedAvatars: updatedSelectedAvatars };
    }),

  handleAvatarClick: (rowIndex, index) =>
    set((state) => {
      const updatedSelectedAvatars = [...state.selectedAvatars];
      updatedSelectedAvatars[rowIndex].selected =
        !updatedSelectedAvatars[rowIndex].selected;
      return { selectedAvatars: updatedSelectedAvatars };
    }),

  handleReferClick: (selectedAvatarIndex) => {
    console.log("Refer button clicked for avatar:", selectedAvatarIndex);
  },

  handleJobSubmit: (formData) => {
    set((state) => {
      const data = {
        postingDateTime:
          format(new Date(formData.startDate), "MMM do, yyyy") +
          " " +
          formData.startTime,
        role: formData.role,
        mode: formData.mode,
        description: formData.description,
        skillMatch: formData.skillMatch,
        selected: [0, 1, 2, 3],
      };
      const updatedData = [...state.SponsorData, data];
      return { SponsorData: updatedData };
    });
  },

  setUser: (setSelectedAvatars) => set({ selectedAvatars: setSelectedAvatars }),

  setSponsorData: (newSponsorData) => set({ SponsorData: newSponsorData }),
}));

export default useStore;
