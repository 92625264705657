import { MoreVert } from '@mui/icons-material';
import { Box, Chip, Container, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, NativeSelect, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useState } from 'react'
import { getJob, update_status } from '../../api/job_advertisement_related_service';
import userSess from '../../store/user_session_control';

const ArchiveJobs = (props) => {
    // const [anchorEl, setAnchorEl] = useState(null);
    // const [Jobevents, SetJobevents] = useState();
    // const [isArchived, setIsArchived] = useState(false);
    // const [Archived, setArchived] = useState([]);
    // const { user_token, updateSess } = userSess()
    const col = ['Starting Date', 'Job Id', 'Role', 'Mode', 'Description', 'Skill Match', 'Status'];

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    // const handleChange = async (event, id) => {
    //     let value = event.target.value;
    //     console.log(value, id)
    //     const body = {
    //         "advertisement_id": id,
    //         "status": value
    //     }
    //     const response = await update_status({ ...body, 'token': user_token });
    //     console.log(response);
    //     if (response.status === 200) {
    //         const app_events = await getJob(user_token)
    //         SetJobevents(app_events)
    //     }
    // };

    // const [count, SetCount] = useState(0)
    // const handleSubmit = () => {
    //     SetCount(count + 1)
    // }

    // React.useEffect(() => {
    //     const handleClick_cal = async () => {

    //         const app_events = await getJob(user_token)
    //         SetJobevents(app_events)

    //     };
    //     handleClick_cal();
    // }, [count]);
    const Jobevents = props.Jobevents;

    return (

        <Box sx={{ width: '100%', typography: 'body1' }}>
            <Table className="fullWidthTable" sx={{ minWidth: 350 }} style={{ fontSize: '3rem' }}>

                <TableHead sx={{ backgroundColor: '#2f3348' }}>
                    <TableRow>

                        {[...Array(7)].map((_, index) => (
                            <TableCell key={index} style={{ fontWeight: 'bold', width: '10px', height: '50px', borderRight: '1px solid #e0e0e0', color: 'white' }}>{col[index]}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Jobevents && Jobevents.map(index => {

                            if (index.status === 'Archive') {
                                return (
                                    <TableRow >

                                        <TableCell style={{verticalAlign:"top"}} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_date} </TableCell>
                                        <TableCell style={{verticalAlign:"top"}} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_id}</TableCell>
                                        <TableCell style={{verticalAlign:"top"}} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_role}</TableCell>
                                        <TableCell style={{verticalAlign:"top"}} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_mode}</TableCell>
                                        <TableCell style={{verticalAlign:"top"}} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_description}</TableCell>
                                        <TableCell style={{verticalAlign:"top"}} sx={{ borderRight: '1px solid #e0e0e0' }}>
                                        {([index.advertisement_skill_match]).map((skill, index) => (
                                            <Chip key={index} label={skill} />
                                        ))}
                                        </TableCell>

                                        <TableCell style={{ borderRight: '1px solid #e0e0e0' ,verticalAlign:"top"}}>
                                            <Stack display={'fex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                <Stack>
                                                    <FormControl fullWidth>
                                                        {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                            State
                                                        </InputLabel> */}
                                                        <NativeSelect
                                                            defaultValue={index.status}
                                                            inputProps={{
                                                                name: 'Status',
                                                                id: 'status',
                                                            }}
                                                            onChange={(event) => {
                                                                let id = index.advertisement_id
                                                                console.log(id + "id")
                                                                props.handleChange(event, id)
                                                            }}
                                                        >
                                                            <option value="Close">Close</option>
                                                            <option value="Open">Open</option>
                                                            <option value="Archive">Archive</option>


                                                        </NativeSelect>
                                                    </FormControl>
                                                </Stack>
                                                <Stack display={'fex'} flexDirection={'row'}>

                                                    <IconButton
                                                        size="large"
                                                        aria-label="display more actions"
                                                        edge="end"
                                                        color="inherit"
                                                    >
                                                        <MoreVert onClick={props.handleClick} />
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        </TableCell>

                                    </TableRow >
                                )
                            }
                        }
                        )


                    }


                </TableBody>
            </Table>

        </Box>
    )
}

export default ArchiveJobs
