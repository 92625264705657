import React, { useState } from 'react';
import {
    Grid,
    Avatar,
    TextField,
    InputAdornment,
    FormControl,
    InputLabel,
    OutlinedInput,
    IconButton,
    Link,
    Button,
    Checkbox,
    Alert,
    Stack,
    Typography,
    Box,
    Divider,
    Snackbar
} from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { blue, red } from '@mui/material/colors';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { activeEmail, addUser } from '../../api/user_related_service';
import { fetchUser } from '../../api/user_related_service';

// Email Validation
const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
const isPasswordValid = (password) => {
    const criteria = [
        /.{8,}/,
        /[A-Z]/,
        /[a-z]/,
        /[0-9]/,
        /[!@#$%^&*(),.?":{}|<>]/
    ];
    return criteria.every((regex) => regex.test(password));
};
const containsSpecialChars = (str) => /[^a-zA-Z]/.test(str);

function Signup({ toggleForm }) {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [usernameInput, setUsernameInput] = useState('');
    const [lastnameInput, setLastnameInput] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [confirmPasswordInput, setConfirmPasswordInput] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);
    const [formValid, setFormValid] = useState();
    const [success, setSuccess] = useState();
    const [verifyCheck, setVerifyCheck] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    const handleEmail = () => {
        if (!isEmail(emailInput)) {
            setEmailError(true);
            return;
        }
        setEmailError(false);
    };

    const handlePassword = () => {
        if (!isPasswordValid(passwordInput)) {
            setPasswordError(true);
            return;
        }
        setPasswordError(false);
    };

    const handleConfirmPassword = () => {
        if (confirmPasswordInput !== passwordInput) {
            setConfirmPasswordError(true);
            return;
        }
        setConfirmPasswordError(false);
    };

    const handleFirstName = () => {
        if (!usernameInput.trim() || containsSpecialChars(usernameInput)) {
            setFirstNameError(true);
            return;
        }
        setFirstNameError(false);
    };

    const handleLastName = () => {
        if (!lastnameInput.trim() || containsSpecialChars(lastnameInput)) {
            setLastNameError(true);
            return;
        }
        setLastNameError(false);
    };

    const handleSubmit = async () => {
        setSuccess(null);
        handleFirstName();
        handleLastName();
        handleEmail();
        handlePassword();
        handleConfirmPassword();
        if (!rememberMe) {
            setCheckboxError(true);
            setFormValid("You must agree to tussp's Terms of Service and Privacy Policy.");
            return;
        }
        setCheckboxError(false);
        if (emailError || !emailInput) {
            setFormValid("Email is Invalid. Please Re-Enter");
            return;
        }
        if (passwordError || !passwordInput) {
            setFormValid("Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.");
            return;
        }
        if (confirmPasswordError || !confirmPasswordInput) {
            setFormValid("Password does not match.");
            return;
        }
        if (firstNameError || !usernameInput.trim()) {
            setFormValid("First Name is required and must not contain special characters.");
            return;
        }
        if (lastNameError || !lastnameInput.trim()) {
            setFormValid("Last Name is required and must not contain special characters.");
            return;
        }
        setFormValid(null);
        try {
            const userExists = await fetchUser({ email: emailInput.trim() });
            console.log(userExists);
            const userData = userExists.data; // access the data property
            const user = userData.find((user) => user.email === emailInput.trim());

            if (user) {
                if (user.is_active) {
                    setFormValid("User already exists and is active. Please try logging in..");
                    setVerifyCheck(false);
                } else {
                    setFormValid("User already exists but is inactive. Please");
                    setVerifyCheck(true);
                }
                return;
            }
            // if (userExists.data.includes(emailInput.trim())) {
            //     setFormValid("User already exists. Please try logging in.");
            //     return;
            // }
        } catch (error) {
            console.error('Error fetching user:', error);
            setFormValid("Failed to verify user. Please try again.");
            return;
        }

        const d = {
            username: emailInput.trim(),
            password: confirmPasswordInput,
            email: emailInput.trim(),
            first_name: usernameInput.trim(),
            last_name: lastnameInput.trim(),
            provider: 'local'
        };

        try {
            await addUser(d);
            setSuccess("Account created successfully");
            setOpen(true)
            setTimeout(()=>{
                toggleForm()
            },2000); // Switch to login form after successful signup
        } catch (error) {
            console.error('Error creating account:', error);
            setFormValid("Failed to create account. Please try again.");
        }
    };

    const [open, setOpen] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };

    const handleVerify = async () => {
        try {
            await activeEmail({
                email: emailInput.trim(),
            });
            setOpen(true);
        } catch (error) {
            console.error('Error during email verification:', error);

        }
    }


    return (
        <>
            <Grid container direction="column" alignItems="center">
                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert
                        onClose={handleClose}
                        severity="info"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Verification is sent to your email !!
                    </Alert>
                </Snackbar>
                <Grid container alignItems="center" direction="column">
                    <Avatar sx={{ bgcolor: '#2f3348' }}><FaceIcon /></Avatar>
                    <Typography variant="h5" align="center" sx={{ marginBottom: '2rem' }}>Signup</Typography>
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="First Name"
                        error={firstNameError}
                        value={usernameInput}
                        style={{ marginBottom: '1rem' }}
                        onChange={(e) => setUsernameInput(e.target.value)}
                        onBlur={handleFirstName}
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Last Name"
                        error={lastNameError}
                        value={lastnameInput}
                        style={{ marginBottom: '1rem' }}
                        onChange={(e) => setLastnameInput(e.target.value)}
                        onBlur={handleLastName}
                    />

                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Your email"
                        error={emailError}
                        value={emailInput}
                        style={{ marginBottom: '1rem' }}
                        onBlur={handleEmail}
                        onChange={(e) => setEmailInput(e.target.value)}
                    />
                    <FormControl sx={{ width: '100%' }} variant="outlined" >
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            error={passwordError}
                            onBlur={handlePassword}
                            onChange={(event) => setPasswordInput(event.target.value)}
                            value={passwordInput}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%', marginTop: '1rem' }} variant="outlined" >
                        <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            error={confirmPasswordError}
                            onBlur={handleConfirmPassword}
                            onChange={(event) => setConfirmPasswordInput(event.target.value)}
                            value={confirmPasswordInput}
                            type={showConfirmPassword ? 'text' : 'password'}
                            label="Confirm Password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownConfirmPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '100%' }}>
                        <Stack direction="row" alignItems="center">
                            <Checkbox
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            <Typography variant="body2">I agree to tussp's Terms of Service and Privacy Policy.</Typography>
                        </Stack>

                    </Stack>
                    <Button variant="contained" fullWidth onClick={handleSubmit} sx={{ marginTop: '1rem' }}>Create Account</Button>

                    {formValid && (<Alert severity="error">
                        <Stack display={'flex'} direction={'row'}>
                            {formValid}

                            {verifyCheck && (<Link onClick={handleVerify} underline="hover" sx={{ ':hover': { cursor: 'pointer' } }} variant="body2"><Typography fontSize={14} marginLeft={1}>Verify Now</Typography></Link>)}
                        </Stack>
                    </Alert>)}

                    {success && <Alert severity="success">{success}</Alert>}
                </Grid>
                <Grid item>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '1rem' }}>
                        <Divider sx={{ width: '100%' }} />
                        <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>or connect with</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '0.5rem' }}>
                            <Link href="http://127.0.0.1:8000/login/google"><GoogleIcon sx={{ color: red[500] }} /></Link>
                            <MicrosoftIcon />
                            <GitHubIcon />
                            <LinkedInIcon color="primary" />
                            <FacebookIcon color="primary" />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Signup;
