import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import usePlanpath from "../../store/PlanPath_data";
import { addPlan } from "../../api/planPath_related_services";
import userSess from "../../store/user_session_control";
import Cookies from 'js-cookie';
function AddNewPlanPath({ open, onClose, onAddcourse }) {
  const [details, setDetails] = useState("");
  const [course, setCourse] = useState("");
  const [date, setDate] = useState("");
  const data = usePlanpath((state) => state.path_data);
  const add_row = usePlanpath((state) => state.add_row);
  const user_token = Cookies.get("connectedMe")
  const handleSubmit = async () => {
    if (details && course && date) {
      const randomId = Math.floor(Math.random() * 9000) + 1000;
      console.log("Submitting new plan path:", {
        details,
        course,
        date,
        plan_path_id: randomId,
      });
      add_row(details, course, date,randomId);
      onClose();
      setDetails("");
      setCourse("");
      setDate("");
      try {
        await addPlan({
          details,
          course,
          date,
          plan_path_id: randomId,
          token: user_token,
        });
        console.log("PlanPath data saved successfully");
      } catch (error) {
        console.error("Failed to save plan data:", error);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const handleCancel = () => {
    setDetails("");
    setCourse("");
    setDate("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Add Plan path</DialogTitle>
      <DialogContent sx={{ width: "800px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ marginBottom: 2 }}>
            <TextField
              label="Details"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              sx={{ marginTop: "10px", width: "100%" }}
              required
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={8} sx={{ marginBottom: 2 }}>
            <TextField
              label="Course"
              value={course}
              onChange={(e) => setCourse(e.target.value)}
              sx={{ width: "100%" }}
              required
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={4} sx={{ marginBottom: 2 }}>
            <TextField
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              sx={{ width: "100%" }}
              required
            />
          </Grid>

          <Grid
            container
            spacing={3}
            sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}
          >
            <Grid item xs={3}>
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{ width: "100%", mt: 2 }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ width: "100%", mt: 2 }}
              >
                Publish
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AddNewPlanPath;
