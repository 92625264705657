import { Typography, Box, Chip, Badge, IconButton} from "@mui/material";
import { ArrowBackOutlined } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router';
const Mentor_skill = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { skills } = location.state || [];
    return (
        <>
            <IconButton aria-label="back" size="large" onClick={() =>
                navigate(-1)
            }>
                <ArrowBackOutlined fontSize='large' color='#000000' />
            </IconButton>
            <Box
                sx={{
                    maxWidth: "800px",
                    margin: "0 auto",
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "white",
                    marginTop: "10px"
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: 'bold' }} gutterBottom>Skills</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", gap: 2, marginLeft: "40px" }}>
                    {skills.map((skill, index) => (
                        <Badge key={index} color="error" badgeContent={skill.skill_level}>
                            <Chip label={skill.skill_name} />
                        </Badge>
                    ))}
                </Box>
            </Box>
        </>

    )
}

export default Mentor_skill;