import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import {
  Container,
  Typography,
  Button,
  TextField,
  MenuItem,
  Select,
  Stack,
  Autocomplete,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Chip,
  Badge,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { fetchcountry } from "../../api/skill_catalog";
import {
  addProfile,
  addProfileImage,
  fetchProfileImage,
  deleteProfileImg,
  fetchProfile,
} from "../../api/profile_related_service";
import {
  fetchRoles,
  fetchCurrentSkills,
  fetchDesirableSkills,
} from "../../api/skill_catalog";
import { useMediaQuery } from "@mui/material";
import Cookies from 'js-cookie';

const ProfilePage = () => {
  const user_token = Cookies.get("connectedMe")
  const [errors, setErrors] = useState({
    professional: {},
    certifications: {},
  });
  const [isEditing, setIsEditing] = useState(false);
  const [originalUser, setOriginalUser] = useState({});
  const [suggestedRole, setSuggestedRole] = useState([]);
  const [suggestedCountry, setSuggestedCountry] = useState([]);
  const [suggestedCurrentSkills, setSuggestedCurrentSkills] = useState([]);
  const [suggestedDesirabletSkills, setSuggestedDesirableSkills] = useState([]);
  const [profilePicture, setProfilePicture] = useState(null);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [tempProfilePicture, setTempProfilePicture] = useState(null);
  const [tempProfileFile, setTempProfileFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email_id: "",
    secondary_email: "",
    phone_country_code: "",
    phone_number: "",
    date_of_birth: "",
    gender: "",
    education: [
      {
        institution: "",
        course: "",
        year: null,
      },
    ],
    professional_experience: [
      {
        jobTitle: "",
        organization: "",
        startYear: "",
        endYear: "",
        still_working: null,
      },
    ],
    certification: [
      {
        certificateTitle: "",
        issuer: "",
        certificateNo: "",
        issuedate: "",
        expireDate: "",
      },
    ],
    currentRole: "",
    skills: [
      {
        skill_name: "",
        skill_level: 0,
      },
    ],
    desirableRole: "",
    professional_ambitions: [
      {
        skill_name: "",
        skill_level: 0,
      },
    ],
  });
  const [editedUser, setEditedUser] = useState(user);
  const [selected, setSelected] = useState(
    user.professional_experience[0]?.still_working === 0
  );
  const [educationRow, setEducationRow] = useState([
    {
      institution: "",
      course: "",
      year: "",
      index: 0,
    },
  ]);
  const [originalEducationRow, setOriginalEducationRow] =
    useState(educationRow);
  const [proffesionalRow, setProffesionalRow] = useState([
    {
      jobTitle: "",
      organization: "",
      startYear: "",
      endYear: "",
      still_working: null,
    },
  ]);

  const [certificationRow, setCertificationRow] = useState([
    {
      certificateTitle: "",
      issuer: "",
      certificateNo: "",
      issuedate: "",
      expireDate: "",
    },
  ]);

  useEffect(() => {
    if (isEditing) {
      setEditedUser({ ...user });
    }
  }, [isEditing, user]);
  useEffect(() => {
    const fetchProfileData = async () => {
      const profile = await fetchProfile({ user_token: user_token });
      setUser(profile);
    };
    fetchProfileData();
  }, []);
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const validatePhoneNumber = (phoneNumber, country) => {
    let re;
    if (country === "India") {
      re = /^[0-9]{10}$/;
    } else if (country === "Australia") {
      re = /^[0-9]{9}$/;
    } else {
      re = /^[0-9]{10}$/;
    }
    return re.test(String(phoneNumber));
  };
  const validateDateOfBirth = (date_of_birth) => {
    const selectedDate = new Date(date_of_birth);
    const today = new Date();

    const age = today.getFullYear() - selectedDate.getFullYear();
    const monthDiff = today.getMonth() - selectedDate.getMonth();
    const dayDiff = today.getDate() - selectedDate.getDate();

    if (
      age > 100 ||
      age < 18 ||
      (age === 100 && (monthDiff > 0 || (monthDiff === 0 && dayDiff > 0))) ||
      (age === 18 && (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)))
    ) {
      return false;
    }

    return true;
  };
  const validprofessionalDate = (startYear, endYear) => {
    const currentDate = new Date();
    const minYear = new Date("1960-01-01");

    if (startYear) {
      const startDate = new Date(startYear);
      if (startDate < minYear || startDate > currentDate) return false;
    }

    if (endYear) {
      const endDate = new Date(endYear);
      if (endDate > currentDate) return false;
    }

    if (startYear && endYear) {
      const startDate = new Date(startYear);
      const endDate = new Date(endYear);
      return startDate < endDate;
    }

    return true;
  };
  const validateCertificationDate = (issuedate, expireDate) => {
    const currentDate = new Date();
    const minYear = new Date("1960-01-01");

    if (issuedate) {
      const issueDate = new Date(issuedate);
      if (issueDate < minYear || issueDate > currentDate) return false;
    }

    if (expireDate) {
      const expDate = new Date(expireDate);
      if (expDate > currentDate) return false;
    }

    if (issuedate && expireDate) {
      const issueDate = new Date(issuedate);
      const expDate = new Date(expireDate);
      return issueDate < expDate;
    }

    return true;
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const maxDate = getTodayDate();
  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    let processedValue = value;
    if (field === "phone_number") {
      if (/^\d*$/.test(value)) {
        processedValue = value;
      } else {
        setErrors((prev) => ({
          ...prev,
          [field]: "Phone number must be numeric",
        }));
        return;
      }
    }
    setUser({ ...user, [field]: processedValue });
    // Email validation
    if (field === "secondary_email") {
      if (value && !validateEmail(value)) {
        setErrors((prev) => ({ ...prev, [field]: "Invalid email format" }));
      } else {
        setErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors[field];
          return newErrors;
        });
      }
    }

    if (field === "secondary_email") {
      if (value === editedUser.email_id) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          secondary_email:
            "Primary and secondary email addresses must be different",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          secondary_email: "",
        }));
      }
    }
    // Phone number validation
    if (field === "phone_number") {
      const country = user.phone_country_code;
      if (value && !validatePhoneNumber(value, country)) {
        setErrors((prev) => ({
          ...prev,
          [field]: `Invalid phone number format for ${country}`,
        }));
      } else {
        setErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors[field];
          return newErrors;
        });
      }
    }

    if (field === "date_of_birth") {
      if (!validateDateOfBirth(value)) {
        setErrors((prev) => ({
          ...prev,
          [field]: "Date of birth must be between 18 and 100 years ago",
        }));
      } else {
        setErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors[field];
          return newErrors;
        });
      }
    }
  };

  useEffect(() => {
    setProffesionalRow(user.professional_experience);
  }, [user.professional_experience]);

  useEffect(() => {
    setEducationRow(user.education);
  }, [user.education]);

  useEffect(() => {
    setCertificationRow(user.certification);
  }, [user.certification]);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const roleResponse = await fetchRoles();
        setSuggestedRole(roleResponse);
      } catch (error) {
        console.error("Error fetching suggested roles:", error);
      }
    };
    if (isEditing) {
      fetchRole();
    }
  }, [isEditing]);

  // useEffect(() => {
  //   const fetch_current_skill = async (roleId) => {
  //     try {
  //       const skillsResponse = await fetchCurrentSkills(roleId);
  //       setSuggestedCurrentSkills(skillsResponse.skill_name);
  //       const selectedRole =suggestedRole.find(role => role.role_id === roleId);
  //       setSelectedCurrentRole(selectedRole?.name);
  //     } catch (error) {
  //       console.error("Error fetching skills:", error);
  //     }
  //   };
  //   if (roleId) {
  //     fetch_current_skill();
  //   }
  // }, [roleId]);

  useEffect(() => {
    // Fetch country
    const suggestedfetchcountry = async () => {
      try {
        const countryResponse = await fetchcountry();
        const countryList = countryResponse.data;
        if (Array.isArray(countryList)) {
          // Check if skillList is an array
          setSuggestedCountry(countryList);
        } else {
          console.error("Error fetching suggested skills: Invalid data format");
        }
      } catch (error) {
        console.error("Error fetching suggested county:", error);
      }
    };
    if (isEditing) {
      suggestedfetchcountry();
    }
  }, [isEditing]);

  const handleInputChangeProf = (field, index) => (event) => {
    const updatedProfessionalExp = [...proffesionalRow];
    updatedProfessionalExp[index][field] = event.target.value;

    if (field === "startYear" || field === "endYear") {
      const { startYear, endYear } = updatedProfessionalExp[index];
      if (!validprofessionalDate(startYear, endYear)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          professional: {
            ...prevErrors.professional,
            [`${index}`]: "Invalid date range.",
          },
        }));
      } else {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.professional[`${index}`];
          return newErrors;
        });
      }
    }

    setProffesionalRow(updatedProfessionalExp);
    setUser({ ...user, professional_experience: updatedProfessionalExp });
  };

  const handleInputChangeEdu = (field, index) => (event) => {
    const value = event.target.value;
    const updatedEducation = [...educationRow];

    if (field === "year") {
      if (/^\d*$/.test(value)) {
        updatedEducation[index][field] =
          value === "" ? "" : parseInt(value, 10);
        setErrors((prev) => ({
          ...prev,
          [index]: { ...prev[index], [field]: "" },
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [index]: { ...prev[index], [field]: "Year must be numeric" },
        }));
        return;
      }
    } else {
      updatedEducation[index][field] = value;
    }

    setEducationRow(updatedEducation);
    setUser({ ...user, education: updatedEducation });
  };

  const handleInputChangeCert = (field, index) => (event) => {
    const updatedCertification = [...certificationRow];
    updatedCertification[index][field] = event.target.value;

    if (field === "issuedate" || field === "expireDate") {
      const { issuedate, expireDate } = updatedCertification[index];
      if (!validateCertificationDate(issuedate, expireDate)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          certifications: {
            ...prevErrors.certifications,
            [`${index}`]: "Invalid date range.",
          },
        }));
      } else {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.certifications[`${index}`];
          return newErrors;
        });
      }
    }

    setCertificationRow(updatedCertification);
    setUser({ ...user, certification: updatedCertification });
  };

  const handleRoleSelect = async (event, newValue) => {
    const selectedRole = suggestedRole.find((role) => role.name === newValue);
    const roleId = selectedRole?.role_id;

    setUser({ ...user, currentRole: newValue });

    if (roleId) {
      try {
        const skillsResponse = await fetchCurrentSkills(roleId);
        const skillsWithLevels = skillsResponse.map((skill) => ({
          skill_name: skill.skill_name,
          skill_level: skill.skill_level,
        }));
        setSuggestedCurrentSkills(skillsWithLevels);
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    }
  };
  const handleDesirableRoleSelect = async (event, newValue) => {
    const selectedRole = suggestedRole.find((role) => role.name === newValue);
    const roleId = selectedRole?.role_id;
    setUser({ ...user, desirableRole: newValue });

    if (roleId) {
      try {
        const skillsResponse = await fetchDesirableSkills(roleId);
        const skillsWithLevels = skillsResponse.map((skill) => ({
          skill_name: skill.skill_name,
          skill_level: skill.skill_level,
        }));
        setSuggestedDesirableSkills(skillsWithLevels);
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    }
  };
  const handleSkillSelect = (event, newValue) => {
    const updatedSkills = newValue.map((skill) => {
      if (typeof skill === "string") {
        const skillObj = suggestedCurrentSkills.find(
          (s) => s.skill_name === skill
        );
        return skillObj ? skillObj : { skill_name: skill, skill_level: 0 };
      }
      return skill;
    });

    setUser({ ...user, skills: updatedSkills });
  };

  const handelAmbitionSelect = (event, newValue) => {
    const updatedSkills = newValue.map((skill) => {
      if (typeof skill === "string") {
        const skillObj = suggestedDesirabletSkills.find(
          (s) => s.skill_name === skill
        );
        return skillObj ? skillObj : { skill_name: skill, skill_level: 0 };
      }
      return skill;
    });
    setUser({ ...user, professional_ambitions: updatedSkills });
  };
  const handelCountrySelect = (event, newValue) => {
    let updatedValue = newValue;

    if (!Array.isArray(newValue)) {
      updatedValue = [newValue];
    }
    setUser({ ...user, phone_country_code: newValue });
  };
  const handleEditClick = () => {
    setIsEditing(true);
    setOriginalUser({ ...editedUser });
  };
  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedUser(originalUser);
    setEducationRow(originalEducationRow);
  };
  const professionalDetailsFilled = () => {
    return proffesionalRow.some((row) => {
      return (
        row.jobTitle &&
        row.organization &&
        row.startYear &&
        (row.endYear || row.still_working)
      );
    });
  };
  const validateInputs = () => {
    const newErrors = {};

    proffesionalRow.forEach((row, index) => {
      if (!row.jobTitle || /^\s*$/.test(row.jobTitle)) {
        newErrors.professional = newErrors.professional || {};
        newErrors.professional[`${index}_jobTitle`] =
          "Job title must be filled";
      }
      if (!row.organization || /^\s*$/.test(row.organization)) {
        newErrors.professional = newErrors.professional || {};
        newErrors.professional[`${index}_organization`] =
          "Organization must be filled";
      }
      if (!row.startYear || /^\s*$/.test(row.startYear)) {
        newErrors.professional = newErrors.professional || {};
        newErrors.professional[`${index}`] = "Start date must be filled";
      }
      if ((!row.endYear || /^\s*$/.test(row.endYear)) && !row.still_working) {
        newErrors.professional = newErrors.professional || {};
        newErrors.professional[`${index}`] = "End date must be filled ";
      } else if (
        !row.still_working &&
        !validprofessionalDate(row.startYear, row.endYear)
      ) {
        newErrors.professional = newErrors.professional || {};
        newErrors.professional[`${index}`] = "Invalid date range.";
      }
    });

    if (!professionalDetailsFilled()) {
      newErrors.general =
        "At least one set of professional details must be fully filled";
    }
    if (
      editedUser.secondary_email &&
      !validateEmail(editedUser.secondary_email)
    ) {
      newErrors.secondary_email = "Invalid email address";
    }
    if (
      editedUser.phone_number &&
      !validatePhoneNumber(editedUser.phone_number, user.phone_country_code)
    ) {
      newErrors.phone_number = `Invalid phone number format for ${user.phone_country_code}`;
    }

    if (!validateDateOfBirth(editedUser.date_of_birth)) {
      newErrors.date_of_birth =
        "Date of birth must be between 18 and 100 years ago";
    }

    proffesionalRow.forEach((row, index) => {
      if (!validprofessionalDate(row.startYear, row.endYear)) {
        newErrors.professional[index] = "Invalid date range.";
      }
    });

    certificationRow.forEach((row, index) => {
      if (!validateCertificationDate(row.issuedate, row.expireDate)) {
        newErrors.certifications[index] = "Invalid date range.";
      }
    });
    if (editedUser.skills.length === 0) {
      newErrors.skills = "Please fill out the required fields";
    }
    if (editedUser.professional_ambitions.length === 0) {
      newErrors.professional_ambitions = "Please fill out the required fields";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSaveClick = async () => {
    if (!validateInputs()) {
      return;
    }
    setIsEditing(false);
    setUser(editedUser);
    setOriginalUser(editedUser);
    setOriginalEducationRow(educationRow);
    try {
      // Send profile data to the backend
      await addProfile({ ...editedUser, token: user_token });
      console.log("Profile data saved successfully");
    } catch (error) {
      console.error("Failed to save profile data:", error);
    }
  };

  const handelEducationRow = () => {
    const newIndex = educationRow.length;
    setEducationRow([
      ...educationRow,
      { institution: "", course: "", year: "", index: newIndex },
    ]);
  };
  const handelProffesionalRow = () => {
    const newIndex = proffesionalRow.length;
    setProffesionalRow([
      ...proffesionalRow,
      {
        jobTitle: "",
        organization: "",
        startYear: "",
        endYear: "",
        still_working: 0,
        index: newIndex,
      },
    ]);
  };
  const handelCertificationRow = () => {
    const newIndex = certificationRow.length;
    setCertificationRow([
      ...certificationRow,
      {
        certificateTitle: "",
        issuer: "",
        certificateNo: "",
        issuedate: "",
        expireDate: "",
        index: newIndex,
      },
    ]);
  };
  const handleDeleteProffesionalRow = (index) => {
    const updatedRows = proffesionalRow.filter((row) => row.index !== index);
    setProffesionalRow(updatedRows);
  };
  const handleDeleteEducationRow = (index) => {
    const updatedRows = educationRow.filter((row) => row.index !== index);
    setEducationRow(updatedRows);
  };
  const handleDeleteCertificationRow = (index) => {
    const updatedRows = certificationRow.filter((row) => row.index !== index);
    setCertificationRow(updatedRows);
  };
  // const updateProfessionalStillWorking = (index, stillWorking) => {
  //   useProfile.setState((state) => {
  //     const updatedProfessionalExperience =
  //       state.user.professional_experience.map((exp, i) =>
  //         i === index ? { ...exp, still_working: stillWorking } : exp
  //       );
  //     return {
  //       user: {
  //         ...state.user,
  //         professional_experience: updatedProfessionalExperience,
  //       },
  //     };
  //   });
  // };

  const handleToggle = () => {
    setSelected((prev) => !prev);
    const updatedProfessionalExp = [...proffesionalRow];
    updatedProfessionalExp[0].still_working = !selected ? 1 : 0;
    setProffesionalRow(updatedProfessionalExp);
    setUser({ ...user, professional_experience: updatedProfessionalExp });
  };

  useEffect(() => {
    fetchImage();
  }, []);

  const fetchImage = async () => {
    try {
      const imageUrl = await fetchProfileImage({ user_token });
      setProfilePicture(imageUrl);
    } catch (error) {
      console.error("Error fetching profile image:", error);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setTempProfilePicture(fileURL);
      setTempProfileFile(file);
      setIsEditProfile(true);
    }
  };

  const handleSave = async () => {
    try {
      await addProfileImage({ token: user_token, image: tempProfileFile });
      setProfilePicture(URL.createObjectURL(tempProfileFile));
      setIsEditProfile(false);
      console.log("Profile image saved successfully");
    } catch (error) {
      console.error("Failed to save profile image:", error);
    }
    setOpen(false);
  };

  const handleRemove = () => {
    deleteProfileImg({ user_token });
    setProfilePicture(null);
    setTempProfilePicture(null);
    setTempProfileFile(null);
    setOpen(false);
  };

  const handleCancel = () => {
    setTempProfilePicture(null);
    setTempProfileFile(null);
    setIsEditProfile(false);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTempProfilePicture(null);
    setTempProfileFile(null);
    setIsEditProfile(false);
    setOpen(false);
  };
  const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black", // Change the color of the text
    },
    // '& .MuiInputLabel-root.Mui-disabled': {
    //   color: 'black', // Change the color of the label
    // },
    "& .MuiInput-underline:before": {
      borderBottomColor: "black", // Change the color of the underline
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black", // Change the color of the underline
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "black", // Ensuring the text color changes
    },
  }));

  return (
    <Container>
      <Stack
        sx={{
          margin: { xs: "20px auto", sm: "20px auto" },
          position: "relative",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: { xs: "100px", sm: "150px" },
            width: { xs: "100px", sm: "150px" },
            position: "relative",
          }}
        >
          <Avatar
            sx={{
              height: "100%",
              width: "100%",
              borderRadius: "50%",
              overflow: "hidden",
            }}
            src={
              tempProfilePicture || profilePicture
                ? tempProfilePicture || profilePicture
                : undefined
            }
            onClick={handleClickOpen}
          />
          <IconButton
            aria-label="edit"
            sx={{
              position: "absolute",
              bottom: "4px",
              right: "4px",
              backgroundColor: "#2f3348",
              zIndex: 2,
              "&:hover": {
                backgroundColor: "#2f3348",
                zIndex: 3,
              },
            }}
            // onClick={() => document.getElementById("fileInput").click()}
            onClick={handleClickOpen}
          >
            <ModeEditIcon
              sx={{ color: "white", fontSize: { xs: "10px", sm: "20px" } }}
            />
          </IconButton>
        </Box>

        <input
          id="fileInput"
          type="file"
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleFileChange}
        />
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>
            Profile Photo
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Avatar
              sx={{
                height: { xs: "100px", sm: "150px" },
                width: { xs: "100px", sm: "150px" },
                borderRadius: "50%",
                overflow: "hidden",
                margin: "0 auto",
              }}
              src={
                tempProfilePicture || profilePicture
                  ? tempProfilePicture || profilePicture
                  : undefined
              }
            />
          </DialogContent>
          <DialogActions>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Stack direction="row" spacing={2}>
                <Tooltip title="Edit">
                  <IconButton
                    aria-label="edit"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <ModeEditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remove Photo">
                  <IconButton aria-label="remove" onClick={handleRemove}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack direction="row" spacing={2}>
                {isEditProfile && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Stack>
            </Stack>
          </DialogActions>
        </Dialog>
      </Stack>
      {!isEditing && (
        <Stack
          direction={"row"}
          sx={{
            margin: { xs: "10px", sm: "20px" },
            marginTop: { xs: "15px", sm: "30px" },
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          <Button
            variant="contained"
            onClick={handleEditClick}
            sx={{
              background: "#2f3348",
              color: "white",
              "&:hover": {
                background: "#2f3348",
              },
              padding: { xs: "8px 16px", sm: "10px 20px" },
              fontSize: { xs: "0.8rem", sm: "1rem" },
            }}
          >
            Update Profile
          </Button>
        </Stack>
      )}
      {isEditing && (
        <Stack
          direction={"row"}
          gap={2}
          sx={{
            margin: { xs: "10px", sm: "20px" },
            marginTop: { xs: "15px", sm: "30px" },
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          <Button
            variant="contained"
            onClick={handleCancelClick}
            sx={{
              background: "#2f3348",
              color: "white",
              "&:hover": {
                background: "#2f3348",
              },
              padding: { xs: "8px 16px", sm: "10px 20px" },
              fontSize: { xs: "0.8rem", sm: "1rem" },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveClick}
            sx={{
              background: "#2f3348",
              color: "white",
              "&:hover": {
                background: "#2f3348",
              },
              padding: { xs: "8px 16px", sm: "10px 20px" },
              fontSize: { xs: "0.8rem", sm: "1rem" },
            }}
          >
            Save
          </Button>
        </Stack>
      )}
      <Stack
        direction={"column"}
        sx={{
          margin: { xs: "10px", sm: "20px" },
        }}
      >
        <Stack>
          <Typography
            variant="body1"
            sx={{
              margin: "0 0 10px 0",
              color: isEditing ? "inherit" : "gray",
            }}
          >
            Full Name:
          </Typography>
        </Stack>

        {/* Full Name Fields */}
        <Stack
          direction="row" // Set direction to row for all screen sizes
          spacing={2} // Adjust spacing between fields
        >
          <TextField
            variant="standard"
            size="small"
            label="First Name"
            value={user.first_name}
            onChange={handleInputChange("first_name")}
            sx={{
              flex: 1, // Allow the field to grow
              "& .MuiInputBase-input": {
                fontSize: { xs: "0.75rem", sm: "1rem" }, // Reduce font size for mobile
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            disabled={!isEditing}
          />

          <TextField
            variant="standard"
            label="Middle Name"
            size="small"
            value={user.middle_name}
            onChange={handleInputChange("middle_name")}
            sx={{
              flex: 1, // Allow the field to grow
              "& .MuiInputBase-input": {
                fontSize: { xs: "0.75rem", sm: "1rem" }, // Reduce font size for mobile
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            disabled={!isEditing}
          />

          <TextField
            variant="standard"
            label="Last Name"
            size="small"
            value={user.last_name}
            onChange={handleInputChange("last_name")}
            sx={{
              flex: 1, // Allow the field to grow
              "& .MuiInputBase-input": {
                fontSize: { xs: "0.75rem", sm: "1rem" }, // Reduce font size for mobile
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            disabled={!isEditing}
          />
        </Stack>
      </Stack>

      <Stack
        direction={"column"}
        sx={{
          margin: { xs: "10px", sm: "20px" },
        }}
      >
        <Stack>
          <Typography sx={{ color: isEditing ? "inherit" : "gray" }}>
            Emails:
          </Typography>
        </Stack>

        {/* Email Fields */}
        <Stack
          direction="row" // Set direction to row for all screen sizes
          spacing={2} // Adjust spacing between fields
          sx={{
            margin: { xs: "5px 0 0 0", sm: "10px 0 0 0" },
          }}
        >
          <TextField
            variant="standard"
            label="Primary Email"
            size="small"
            value={user.email_id}
            onChange={handleInputChange("email_id")}
            sx={{
              marginBottom: { xs: "10px", sm: "0px" },
              "& .MuiInputBase-input": {
                fontSize: { xs: "0.75rem", sm: "1rem" }, // Reduce font size for mobile
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            disabled={!isEditing}
          />

          <TextField
            variant="standard"
            label="Secondary Email"
            size="small"
            value={user.secondary_email}
            onChange={handleInputChange("secondary_email")}
            sx={{
              marginBottom: { xs: "10px", sm: "0px" },
              "& .MuiInputBase-input": {
                fontSize: { xs: "0.75rem", sm: "1rem" }, // Reduce font size for mobile
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            disabled={!isEditing}
            error={!!errors.secondary_email}
            helperText={errors.secondary_email}
          />
        </Stack>
      </Stack>

      <Stack direction={"column"} sx={{ margin: { xs: "10px", sm: "20px" } }}>
        <Stack
          direction="row" // Set direction to row for all screen sizes
          spacing={2} // Adjust spacing between fields
          sx={{
            margin: { xs: "5px 0", sm: "10px 0 0 0" },
          }}
        >
          {/* Location Section */}
          <Stack sx={{ flex: 1 }}>
            {" "}
            {/* Flex to take equal space */}
            <Typography sx={{ color: isEditing ? "inherit" : "gray" }}>
              Location:
            </Typography>
            <Autocomplete
              freeSolo
              disabled={!isEditing}
              options={suggestedCountry}
              value={user.phone_country_code}
              onChange={(event, newValue) =>
                handelCountrySelect(event, newValue)
              }
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Country"
                  size="small"
                  sx={{
                    width: { xs: "100%", sm: "190px" },
                    marginBottom: { xs: "10px", sm: "0" }, // Margin for mobile
                    "& .MuiInputBase-input": {
                      fontSize: { xs: "0.75rem", sm: "1rem" }, // Reduce font size for mobile
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                />
              )}
            />
          </Stack>

          {/* Contact Section */}
          <Stack sx={{ flex: 1 }}>
            {" "}
            {/* Flex to take equal space */}
            <Typography sx={{ color: isEditing ? "inherit" : "gray" }}>
              Contact:
            </Typography>
            <TextField
              variant="standard"
              label="Phone Number"
              size="small"
              value={user.phone_number || ""}
              onChange={handleInputChange("phone_number")}
              sx={{
                width: { xs: "100%", sm: "190px" },
                marginBottom: { xs: "10px", sm: "0" }, // Margin for mobile
                "& .MuiInputBase-input": {
                  fontSize: { xs: "0.75rem", sm: "1rem" }, // Reduce font size for mobile
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              disabled={!isEditing}
              error={!!errors.phone_number}
              helperText={errors.phone_number}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction="row" // Set direction to row for all screen sizes
        spacing={2} // Adjust spacing between fields
        sx={{ margin: { xs: "10px", sm: "20px" } }} // Adjust margins
      >
        {/* Date of Birth Section */}
        <Stack sx={{ flex: 1 }}>
          <Typography sx={{ color: isEditing ? "inherit" : "gray" }}>
            Date of Birth:
          </Typography>
          <TextField
            variant="standard"
            size="small"
            type="date" // Use lowercase "date"
            value={user.date_of_birth}
            onChange={handleInputChange("date_of_birth")}
            sx={{
              marginTop: "10px",
              width: { xs: "100%", sm: "190px" },
              "& .MuiInputBase-input": {
                fontSize: { xs: "0.75rem", sm: "1rem" },
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            disabled={!isEditing}
            inputProps={{
              max: maxDate,
              autoComplete: "off",
            }}
            error={!!errors.date_of_birth}
            helperText={errors.date_of_birth}
          />
        </Stack>

        {/* Gender Section */}
        <Stack sx={{ flex: 1 }}>
          <Typography sx={{ color: isEditing ? "inherit" : "gray" }}>
            Gender:
          </Typography>
          <Select
            variant="standard"
            label="Gender"
            size="small"
            value={user.gender || ""}
            onChange={handleInputChange("gender")}
            sx={{
              marginTop: "10px",
              width: { xs: "100%", sm: "190px" },
              "& .MuiSelect-root.Mui-disabled": {
                color: "black",
              },
              "& .MuiInputLabel-root.Mui-disabled": {
                color: "black",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "black",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "black",
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
              },
              "& .MuiInputBase-input": {
                fontSize: { xs: "0.75rem", sm: "1rem" }, // Reduce font size for mobile
              },
            }}
            disabled={!isEditing}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </Stack>
      </Stack>

      <Stack sx={{ margin: { xs: "10px", sm: "20px" } }}>
        {/* Heading */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ marginBottom: "10px" }}
        >
          <Typography sx={{ color: isEditing ? "inherit" : "gray" }}>
            Educational Details:
          </Typography>
          {isEditing && (
            <ControlPointIcon
              onClick={handelEducationRow}
              sx={{ marginLeft: "10px" }}
            />
          )}
        </Stack>

        {/* Editing Instruction */}
        {isEditing && (
          <Typography
            sx={{ fontSize: ".85rem", fontWeight: "500", marginBottom: "10px" }}
          >
            *( fill your highest education first)
          </Typography>
        )}

        {/* Educational Fields */}
        {educationRow.map((row, index) => (
          <Grid
            key={index}
            container
            spacing={2}
            alignItems="center"
            wrap="nowrap" // Ensures the content stays in one row
            sx={{ marginBottom: "10px" }}
          >
            {/* Institution Field */}
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                variant="standard"
                disabled={!isEditing}
                size="small"
                label="Institution"
                value={educationRow[index].institution}
                onChange={handleInputChangeEdu("institution", index)}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "0.8rem", sm: "1rem" }, // Smaller font size for mobile
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            {/* Course Field */}
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                variant="standard"
                disabled={!isEditing}
                size="small"
                label="Course"
                value={educationRow[index].course}
                onChange={handleInputChangeEdu("course", index)}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "0.8rem", sm: "1rem" }, // Smaller font size for mobile
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            {/* Year Field */}
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                variant="standard"
                disabled={!isEditing}
                size="small"
                label="Year"
                value={educationRow[index].year}
                onChange={handleInputChangeEdu("year", index)}
                error={!!(errors[index] && errors[index].year)}
                helperText={errors[index] && errors[index].year}
                sx={{
                  width: { xs: "80px", sm: "120px" }, // Adjust width for mobile
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "0.8rem", sm: "1rem" }, // Smaller font size for mobile
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            {/* Remove Icon */}
            {isEditing && index !== 0 && (
              <Grid item xs={12} sm={1}>
                <RemoveCircleOutlineIcon
                  onClick={() => handleDeleteEducationRow(row.index)}
                  sx={{ cursor: "pointer" }}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </Stack>

      <Stack sx={{ margin: { xs: "10px", sm: "20px" } }} direction={"column"}>
        {/* Heading */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ marginBottom: "10px" }}
        >
          <Typography sx={{ color: isEditing ? "inherit" : "gray" }}>
            <span style={{ color: "red" }}> *</span>Professional Details:
          </Typography>
          {isEditing && (
            <ControlPointIcon
              onClick={handelProffesionalRow}
              sx={{ marginLeft: "10px" }}
            />
          )}
        </Stack>

        {/* Professional Fields */}
        {proffesionalRow.map((row, index) => (
          <Grid
            container
            key={index}
            spacing={2}
            alignItems="center"
            wrap="nowrap" // Prevents wrapping to a new line
            sx={{ marginBottom: "10px" }}
          >
            {/* Job Title */}
            <Grid item xs={6} sm={3}>
              <TextField
                fullWidth
                variant="standard"
                disabled={!isEditing}
                label="Job Title"
                size="small"
                value={row.jobTitle}
                onChange={handleInputChangeProf("jobTitle", index)}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "0.75rem", sm: "1rem" }, // Adjust font size
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                error={
                  !!errors.professional &&
                  !!errors.professional[`${index}_jobTitle`]
                }
                helperText={
                  errors.professional &&
                  errors.professional[`${index}_jobTitle`]
                }
              />
            </Grid>

            {/* Organization */}
            <Grid item xs={6} sm={3}>
              <TextField
                fullWidth
                variant="standard"
                disabled={!isEditing}
                size={"small"}
                label="Organization"
                value={row.organization}
                onChange={handleInputChangeProf("organization", index)}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "0.75rem", sm: "1rem" }, // Adjust font size
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                error={
                  !!errors.professional &&
                  !!errors.professional[`${index}_organization`]
                }
                helperText={
                  errors.professional &&
                  errors.professional[`${index}_organization`]
                }
              />
            </Grid>

            {/* Start Date */}
            <Grid item xs={6} sm={2}>
              <TextField
                fullWidth
                type="date"
                variant="standard"
                disabled={!isEditing}
                size={"small"}
                label="Start Date"
                value={row.startYear}
                onChange={handleInputChangeProf("startYear", index)}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  max: maxDate,
                  autoComplete: "off",
                }}
                sx={{
                  width: { xs: "80px", sm: "100%" }, // Adjust width for mobile
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "0.75rem", sm: "1rem" }, // Adjust font size
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                error={!!errors.professional && !!errors.professional[index]}
                helperText={errors.professional && errors.professional[index]}
              />
            </Grid>

            {/* End Date */}
            <Grid item xs={6} sm={2}>
              {!(selected && index === 0) && (
                <TextField
                  fullWidth
                  type="date"
                  variant="standard"
                  disabled={!isEditing}
                  size={"small"}
                  label="End Date"
                  value={row.endYear}
                  onChange={handleInputChangeProf("endYear", index)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: maxDate,
                    autoComplete: "off",
                  }}
                  sx={{
                    width: { xs: "80px", sm: "100%" }, // Adjust width for mobile
                    "& .MuiInputBase-input": {
                      fontSize: { xs: "0.75rem", sm: "1rem" }, // Adjust font size
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  error={!!errors.professional && !!errors.professional[index]}
                  helperText={errors.professional && errors.professional[index]}
                />
              )}
            </Grid>

            {/* Still Working Checkbox */}
            {index === 0 && (
              <Grid item xs={6} sm={1}>
                <Stack>
                  <Typography
                    disabled={!isEditing}
                    sx={{
                      fontSize: "8px",
                      marginBottom: "-5px",
                      fontWeight: "bold",
                    }}
                  >
                    Still working
                  </Typography>
                  <FormControl sx={{ width: "10px" }}>
                    <FormControlLabel
                      disabled={!isEditing}
                      control={
                        <Radio checked={selected} onClick={handleToggle} />
                      }
                      labelPlacement="bottom"
                    />
                  </FormControl>
                </Stack>
              </Grid>
            )}

            {/* Remove Button */}
            {isEditing && index !== 0 && (
              <Grid item xs={6} sm={1}>
                <RemoveCircleOutlineIcon
                  onClick={() => handleDeleteProffesionalRow(index)}
                  sx={{ cursor: "pointer" }}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </Stack>

      <Stack sx={{ margin: { xs: "10px", sm: "20px" } }} direction={"column"}>
        {/* Certification Header */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ marginBottom: "10px" }}
        >
          <Typography sx={{ color: isEditing ? "inherit" : "gray" }}>
            Certification:
          </Typography>
          {isEditing && (
            <ControlPointIcon
              onClick={handelCertificationRow}
              sx={{ marginLeft: "10px" }}
            />
          )}
        </Stack>

        {/* Certification Fields */}
        {certificationRow.map((row, index) => (
          <Grid
            container
            key={index}
            spacing={2}
            alignItems="center"
            wrap="nowrap" // Prevent wrapping to a new line
            sx={{ marginBottom: "10px" }}
          >
            {/* Certification Name */}
            <Grid item xs={6} sm={2}>
              <TextField
                fullWidth
                variant="standard"
                disabled={!isEditing}
                label="Certification Name"
                size="small"
                value={row.certificateTitle}
                onChange={handleInputChangeCert("certificateTitle", index)}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "0.75rem", sm: "1rem" }, // Adjust font size
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            {/* Issuer */}
            <Grid item xs={6} sm={2}>
              <TextField
                fullWidth
                variant="standard"
                disabled={!isEditing}
                label="Issuer"
                size="small"
                value={row.issuer}
                onChange={handleInputChangeCert("issuer", index)}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "0.75rem", sm: "1rem" }, // Adjust font size
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            {/* Certification No. */}
            <Grid item xs={6} sm={2}>
              <TextField
                fullWidth
                variant="standard"
                disabled={!isEditing}
                label="Certification No."
                size="small"
                value={row.certificateNo}
                onChange={handleInputChangeCert("certificateNo", index)}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "0.75rem", sm: "1rem" }, // Adjust font size
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>

            {/* Issue Date */}
            <Grid item xs={6} sm={2}>
              <TextField
                fullWidth
                type="date"
                variant="standard"
                disabled={!isEditing}
                label="Issue Date"
                size="small"
                value={row.issuedate}
                onChange={handleInputChangeCert("issuedate", index)}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  max: maxDate,
                  autoComplete: "off",
                }}
                sx={{
                  width: { xs: "80px", sm: "100%" }, // Adjust width for mobile
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "0.75rem", sm: "1rem" }, // Adjust font size
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                error={
                  !!errors.certifications && !!errors.certifications[index]
                }
                helperText={
                  errors.certifications && errors.certifications[index]
                }
              />
            </Grid>

            {/* Expire Date */}
            <Grid item xs={6} sm={2}>
              <TextField
                fullWidth
                type="date"
                variant="standard"
                disabled={!isEditing}
                label="Expire Date"
                size="small"
                value={row.expireDate}
                onChange={handleInputChangeCert("expireDate", index)}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  max: maxDate,
                  autoComplete: "off",
                }}
                sx={{
                  width: { xs: "80px", sm: "100%" }, // Adjust width for mobile
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "0.75rem", sm: "1rem" }, // Adjust font size
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                error={
                  !!errors.certifications && !!errors.certifications[index]
                }
                helperText={
                  errors.certifications && errors.certifications[index]
                }
              />
            </Grid>

            {/* Remove Button */}
            {isEditing && (
              <Grid item xs={6} sm={1}>
                {index !== 0 && (
                  <RemoveCircleOutlineIcon
                    onClick={() => handleDeleteCertificationRow(row.index)}
                    sx={{ cursor: "pointer" }}
                  />
                )}
              </Grid>
            )}
          </Grid>
        ))}
      </Stack>

      <Stack direction={"row"} gap={10} sx={{ margin: "20px" }}>
        <Stack sx={{ width: "42%" }}>
          <Stack>
            <Typography sx={{ color: isEditing ? "inherit" : "gray" }}>
              <span style={{ color: "red" }}> *</span>Current Role:
            </Typography>
          </Stack>
          <Stack sx={{ flex: "1" }}>
            <Autocomplete
              freeSolo
              disabled={!isEditing}
              options={suggestedRole.map((role) => role.name)}
              value={user.currentRole}
              onChange={(event, newValue) => handleRoleSelect(event, newValue)}
              sx={{ margin: "10px 0 0 0 " }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  error={!!errors.skills}
                  helperText={errors.skills}
                  size="small"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                />
              )}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={10} sx={{ margin: "20px" }}>
        <Stack sx={{ width: "42%" }}>
          <Stack>
            <Typography sx={{ color: isEditing ? "inherit" : "gray" }}>
              <span style={{ color: "red" }}> *</span>Current skills:
            </Typography>
          </Stack>
          <Stack sx={{ flex: "1" }}>
            <Autocomplete
              freeSolo
              disabled={!isEditing}
              multiple
              options={suggestedCurrentSkills}
              value={user.skills.map((skill) => skill.skill_name)}
              onChange={handleSkillSelect}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.skill_name
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const skill = user.skills.find(
                    (s) => s.skill_name === option
                  );
                  const skillLevel = skill ? skill.skill_level : 0;
                  return (
                    <Badge
                      key={index}
                      badgeContent={skillLevel}
                      color="error"
                      sx={{ marginRight: "4px" }}
                    >
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    </Badge>
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  error={!!errors.skills}
                  helperText={errors.skills}
                  size="small"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                />
              )}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={10} sx={{ margin: "20px" }}>
        <Stack sx={{ width: "42%" }}>
          <Stack>
            <Typography sx={{ color: isEditing ? "inherit" : "gray" }}>
              <span style={{ color: "red" }}> *</span>Desirable Role:
            </Typography>
          </Stack>
          <Stack sx={{ flex: "1" }}>
            <Autocomplete
              freeSolo
              disabled={!isEditing}
              options={suggestedRole.map((role) => role.name)}
              value={user.desirableRole}
              onChange={(event, newValue) =>
                handleDesirableRoleSelect(event, newValue)
              }
              sx={{ margin: "10px 0 0 0 " }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  error={!!errors.skills}
                  helperText={errors.skills}
                  size="small"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                />
              )}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack direction={"column"} sx={{ margin: "20px" }}>
        <Stack>
          <Typography sx={{ color: isEditing ? "inherit" : "gray" }}>
            <span style={{ color: "red" }}> *</span>Desirable skills:
          </Typography>
        </Stack>

        <Stack>
          <Autocomplete
            freeSolo
            disabled={!isEditing}
            multiple
            options={suggestedDesirabletSkills}
            value={user.professional_ambitions.map((skill) => skill.skill_name)}
            onChange={(event, newValue) =>
              handelAmbitionSelect(event, newValue)
            }
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.skill_name
            }
            sx={{
              maxWidth: "42%",
              border: "none",
              margin: "10px 0 0 0 ",
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const skill = user.professional_ambitions.find(
                  (s) => s.skill_name === option
                );
                return (
                  <Badge
                    key={index}
                    color="error"
                    badgeContent={skill ? skill.skill_level : 0}
                    sx={{ marginRight: "4px" }}
                  >
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  </Badge>
                );
              })
            }
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                error={!!errors.professional_ambitions}
                helperText={errors.professional_ambitions}
                size="small"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            )}
          />
        </Stack>
      </Stack>

      {!isEditing && (
        <Stack
          direction={"row"}
          sx={{
            margin: { xs: "10px", sm: "20px" },
            marginTop: { xs: "15px", sm: "30px" },
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          <Button
            variant="contained"
            onClick={handleEditClick}
            sx={{
              background: "#2f3348",
              color: "white",
              "&:hover": {
                background: "#2f3348",
              },
              padding: { xs: "8px 16px", sm: "10px 20px" },
              fontSize: { xs: "0.8rem", sm: "1rem" },
            }}
          >
            Update Profile
          </Button>
        </Stack>
      )}
      {isEditing && (
        <Stack
          direction={"row"}
          gap={2}
          sx={{
            margin: { xs: "10px", sm: "20px" },
            marginTop: { xs: "15px", sm: "30px" },
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          <Button
            variant="contained"
            onClick={handleCancelClick}
            sx={{
              background: "#2f3348",
              color: "white",
              "&:hover": {
                background: "#2f3348",
              },
              padding: { xs: "8px 16px", sm: "10px 20px" },
              fontSize: { xs: "0.8rem", sm: "1rem" },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveClick}
            sx={{
              background: "#2f3348",
              color: "white",
              "&:hover": {
                background: "#2f3348",
              },
              padding: { xs: "8px 16px", sm: "10px 20px" },
              fontSize: { xs: "0.8rem", sm: "1rem" },
            }}
          >
            Save
          </Button>
        </Stack>
      )}
    </Container>
  );
};

export default ProfilePage;
