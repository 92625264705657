import { create } from 'zustand';

const usePlanpath = create((set) => ({
    path_data: [], 
    add_row: (details, course, date,randomId) => {
        const currDate = new Date(date);
        const monthIndex = currDate.getMonth();
        const year = currDate.getFullYear().toString().slice(-2);
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = monthNames[monthIndex];
        const output = month + year;
        set(state => {
            const existingRow = state.path_data.find(row => row.Details === details);
            if (existingRow) {
                const updatedRow = { ...existingRow, [output]: course };
                const updatedData = state.path_data.map(row => (row.Details === details ? updatedRow : row));
                const newState = { path_data: updatedData };
                console.log("Existing row updated:", newState);
                return newState;
            } else {
                let new_element = {
                    "Details": details,
                    "Jan24": "",
                    "Feb24": "",
                    "Mar24": "",
                    "Apr24": "",
                    "May24": "",
                    "Jun24": "",
                    "Jul24": "",
                    "Aug24": "",
                    "Sep24": "",
                    "Oct24": "",
                    "Nov24": "",
                    "Dec24": "",
                    "plan_path_id":randomId
                };
                new_element[output] = course;
                const newState = { path_data: [new_element, ...state.path_data] };
                console.log("New state after adding row:", newState);
                return newState;
            }
        });
    },
    update_row: (details, course, date) => {
        set(state => ({
            path_data: state.path_data.map(row => {
                if (row.Details === details) {
                    const currDate = new Date(date);
                    const monthIndex = currDate.getMonth();
                    const year = currDate.getFullYear().toString().slice(-2);
                    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                    const month = monthNames[monthIndex];
                    const output = month + year;
                    return {
                        ...row,
                        [output]: course
                    };
                }
                return row;
            })
        }));
    },
    delete_row: (details) => {
        set(state => ({
            path_data: state.path_data.filter(row => row.Details !== details)
        }));
    },
    set_path_data: (data) => {
        set({ path_data: data });
    }
}));

export default usePlanpath;
export { usePlanpath };
