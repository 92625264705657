import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Box, Snackbar, Alert } from "@mui/material";
import Login from "../LoginForm/Login";
import Signup from "../LoginForm/Signup";
import useAdminStore from "../../store/Admin_session";
// import { verifyEmail } from '../../api/user_related_service';
import { Link, useNavigate } from "react-router-dom";
import { verifyEmail } from "../../api/user_related_service";

function LoginForm() {
  const { isAdmin, setIsAdmin } = useAdminStore();
  const [isLogin, setIsLogin] = useState(true);
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [url, setURL] = useState(window.location.href);
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  useEffect(() => {
    const URLObj = new URL(url);
    console.log(URLObj);

    console.log(URLObj.searchParams.get("authToken"));
    const token = URLObj.searchParams.get("authToken");

    // for (const [key, value] of URLObj) {
    //     console.log(key);

    //     if(key == "authToken"){
    //         flag = true;
    //     }
    // }
    const getURL = async () => {
      const response = await verifyEmail({
        active_status: true,
        message_desc: url,
      });
      // setIsVerified(response.active_status)
      if (response.active_status) {
        setIsVerified(true);
        setOpen(true);
        //   navigate("/"); // navigate to login page
        setTimeout(() => {
          window.location.href = "http://localhost:3000";
        }, 2000);

      } else {
        setURL(response.message_desc);
      }
    };

    if (token) {
      getURL();
    }
  }, []);

  const HandleAdmin = () => {
    setIsAdmin(true);
    localStorage.setItem("isAdmin", true);
  };

  const HandleUser = () => {
    setIsAdmin(false);
    localStorage.setItem("isAdmin", false);
  };
  return (
    <Grid container style={{ height: "100vh" }}>
      {/* Advertisement Section */}
      {isLogin && !isAdmin ? (
        <>
          <Grid item xs={12} sm={6}>
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: "100%" }}
              >
                Your Email verified successfully !!
              </Alert>
            </Snackbar>
            <Paper style={{ height: "100%" }}>
              {/* <h2>Advertisement</h2>
                            <p>Ad content goes here...</p> */}
              <img
                src="/tussp.webp"
                alt="Advertisement"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper style={{ height: "100%", padding: "20px" }}>
              <Login />
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" align="center">
                  Don't have an account?{" "}
                  <span
                    style={{
                      color: "#2f3348",
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                    onClick={toggleForm}
                  >
                    Create an account
                  </span>
                </Typography>
                <Typography variant="body2" align="center">
                  Are you Admin?{" "}
                  <span
                    style={{ color: "#2f3348", cursor: "pointer" }}
                    onClick={HandleAdmin}
                  >
                    Login By Admin
                  </span>
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </>
      ) : isLogin && isAdmin ? (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: "100vh" }}
        >
          <Paper style={{ height: "100%", padding: "20px" }}>
            <Login />
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" align="center">
                Don't have an account?{" "}
                <span
                  style={{
                    color: "#2f3348",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                  onClick={toggleForm}
                >
                  Create an account
                </span>
              </Typography>
              <Typography variant="body2" align="center">
                Are you User?{" "}
                <span
                  style={{ color: "#2f3348", cursor: "pointer" }}
                  onClick={HandleUser}
                >
                  Login By User
                </span>
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <Paper style={{ height: "100%" }}>
              <img
                src="/tussp.webp"
                alt="Advertisement"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper style={{ height: "100%", padding: "20px" }}>
              <Signup toggleForm={toggleForm} />
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" align="center">
                  Already have an account?{" "}
                  <span
                    style={{ color: "#2f3348", cursor: "pointer" }}
                    onClick={toggleForm}
                  >
                    Login
                  </span>
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default LoginForm;
