import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { dataset, valueFormatter } from '../KPI/data.ts';
import { LineChart } from '@mui/x-charts';
import { PieChart } from '@mui/x-charts/PieChart';
import { mobileAndDesktopOS, valueFormatters } from '../KPI/data_2.ts';
import { Satisfaction, valueFormat } from '../KPI/data_3.ts';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { EventNoteOutlined } from '@mui/icons-material';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';




const chartSetting = {
    yAxis: [
        {
            label: 'Hours',
        },
    ],
    series: [{ dataKey: 'seoul', label: 'Total Hours of Training' }],
    height: 300,
    sx: {
        [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
            transform: 'translateX(-10px)',
        },
    },
};

const data = [
    { agent: 'Julie B.', averageScore: 4.9 },
    { agent: 'Aziz R.', averageScore: 4.8 },
    { agent: 'Josh L.', averageScore: 4.6 },
    { agent: 'Christina A.', averageScore: 4.6 },
    { agent: 'Tamara T.', averageScore: 4.3 },
];

const Mentee_KPI = () => {
    const [radius, setRadius] = React.useState(60);
    const [itemNb, setItemNb] = React.useState(5);
    const [skipAnimation, setSkipAnimation] = React.useState(false);

    // const handleItemNbChange = (event, newValue) => {
    //     if (typeof newValue !== 'number') {
    //         return;
    //     }
    //     setItemNb(newValue);
    // };
    // const handleRadius = (event, newValue) => {
    //     if (typeof newValue !== 'number') {
    //         return;
    //     }
    //     setRadius(newValue);
    // };


    return (
        <>
            <Stack>
                <Typography variant="h4" sx={{ margin: "20px 0 0 20px" }}>
                    KPI Dasboard
                </Typography>
                <Typography
                    variant="subtitle2"
                    sx={{ margin: "0 0 20px 20px", color: "grey" }}
                >
                    Dashboard to track Mentor & Employee engagement.
                </Typography>
            </Stack>
            <Stack display={"flex"} flexDirection={"column"} margin={5} justifyContent={'center'} alignItems={'center'}>

                <Stack
                    display={"flex"}
                    flexDirection={"row"}
                    // spacing={1}
                    marginRight={5}
                >
                    <Stack
                        display={"flex"}
                        flexDirection={"column"}
                        // spacing={4}
                        marginRight={5}
                        marginBottom={5}
                    >
                        <Paper>
                            <Paper
                                sx={{
                                    background: "#FAF9F6",
                                    width: "300px",
                                    height: "280px",

                                }}
                            >
                                <Stack marginLeft={2} marginTop={2}>
                                    <Typography variant="h5" gutterBottom>
                                        <EventNoteOutlined fontSize="medium" /> Total Session Info.
                                    </Typography>
                                </Stack>

                                <Divider />
                                <Card
                                    sx={{
                                        background: "#e2edf9",
                                        height: "80%",
                                        width: "100%"
                                    }}
                                >
                                    <CardContent
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                        }}
                                    >

                                        <Typography variant="h1" color="#3A4856">
                                            5
                                        </Typography>
                                        <Typography fontSize="medium">
                                            <b>From:</b>
                                            m-d-y <b>To:</b>
                                            m-d-y
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Paper>
                            <Paper
                                sx={{
                                    background: "#FAF9F6",
                                    width: "300px",
                                    height: "250px",
                                    marginTop: "50px"

                                }}
                            >
                                <Stack marginLeft={2} marginTop={2}>
                                    <Typography variant="h5" gutterBottom>
                                        <PersonOutlineIcon fontSize="medium" /> Total Participants
                                    </Typography>
                                </Stack>

                                <Divider />
                                <Card
                                    sx={{
                                        background: "#f8f8a7",
                                        height: "100%",
                                    }}
                                >
                                    <CardContent
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                        }}
                                    >


                                        <Typography variant="h1" color="#3A4856">
                                            5
                                        </Typography>

                                    </CardContent>
                                </Card>
                            </Paper>
                        </Paper>
                    </Stack>
                    <Stack
                        display={"flex"}
                        flexDirection={"column"}
                        spacing={4}
                        marginRight={5}
                    >
                        <Paper
                            sx={{
                                background: "#FAF9F6",
                                width: "800px",
                                height: "300px",
                                marginRight: "40px",
                                marginLeft: "30px",
                            }}
                        >
                            <Stack marginLeft={2} marginTop={2}>
                                <Typography variant="h5" gutterBottom>
                                    < EqualizerOutlinedIcon fontSize="medium" /> No. of Sessions Scheduled (in Hours)
                                </Typography>
                            </Stack>
                            <Divider />
                            <BarChart
                                dataset={dataset}
                                xAxis={[
                                    { scaleType: 'band', dataKey: 'month' },
                                ]}
                                {...chartSetting}

                                height={260}
                            />
                        </Paper>
                        <Paper
                            sx={{
                                background: "#FAF9F6",
                                width: "800px",
                                height: "300px",
                                marginRight: "40px",
                                marginLeft: "30px",
                            }}
                        >
                            <LineChart

                                xAxis={[{
                                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                                    label: "Months"
                                }]}
                                series={[
                                    {
                                        data: [20, 50, 45, 62, 85, 15, 75, 89, 100, 120, 115],
                                        label: "No of Participants",
                                    },
                                ]}
                                width={800}
                                height={300}
                            />
                        </Paper>
                    </Stack>
                    {/* <Paper
                    sx={{
                        background: "#FAF9F6",
                        width: "500px",
                        height: "380px",
                        marginRight: "40px",
                        marginLeft: "30px",
                    }}
                >
                    No. of sponsor
                </Paper> */}

                </Stack>


                <Stack
                    display={"flex"}
                    flexDirection={"row"}
                    marginTop={5}
                >
                    <Paper
                        sx={{
                            background: "#FAF9F6",
                            width: "320px",
                            height: "380px",
                            marginRight: "50"
                        }}
                    >
                        <Stack marginLeft={2} marginTop={2}>
                            <Typography variant="h5" gutterBottom>
                                < InsightsOutlinedIcon fontSize="medium" />  Top Performing Mentors
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack>
                            <TableContainer>
                                <Table >
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#2f3348' }}>
                                            <TableCell sx={{ color: 'white' }}>Agent</TableCell>
                                            <TableCell sx={{ color: 'white' }}>Average Score</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row) => (
                                            <TableRow key={row.agent}>
                                                <TableCell component="th" scope="row">
                                                    {row.agent}
                                                </TableCell>
                                                <TableCell >{row.averageScore}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Paper>
                    <Paper
                        sx={{
                            background: "#FAF9F6",
                            width: "460px",
                            height: "380px",
                            marginLeft: "50px"
                        }}
                    >
                        <Stack marginLeft={2} marginTop={2}>
                            <Typography variant="h5" gutterBottom>
                                < AvTimerOutlinedIcon fontSize="medium" />   Missed sessions
                            </Typography>
                        </Stack>
                        <Divider />

                        <PieChart
                            height={280}
                            series={[
                                {
                                    data: mobileAndDesktopOS.slice(0, itemNb),
                                    innerRadius: radius,
                                    // arcLabel: (params) => params.label ?? '',
                                    arcLabelMinAngle: 20,
                                    valueFormatters,
                                },
                            ]}
                            skipAnimation={skipAnimation}
                        />

                    </Paper>
                    <Paper
                        sx={{
                            background: "#FAF9F6",
                            width: "560px",
                            height: "380px",
                            marginLeft: "50px",
                            
                        }}
                    >
                        <Stack marginLeft={2} marginTop={2}>
                            <Typography variant="h5" gutterBottom>
                                < LocalActivityOutlinedIcon fontSize="medium" />   Mentee Satisfaction
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack>
                            <PieChart
                                height={280}
                                series={[
                                    {
                                        data: Satisfaction.slice(0, itemNb),
                                        innerRadius: radius,
                                        // arcLabel: (params) => params.label ?? '',
                                        arcLabelMinAngle: 20,
                                        valueFormat,
                                    },
                                ]}
                                skipAnimation={skipAnimation}
                            />
                        </Stack>
                    </Paper>

                </Stack>
            </Stack>
        </>
    )
}

export default Mentee_KPI
