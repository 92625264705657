// Data derived from https://gs.statcounter.com/os-market-share/desktop/worldwide/2023
// And https://gs.statcounter.com/os-market-share/mobile/worldwide/2023
// And https://gs.statcounter.com/platform-market-share/desktop-mobile-tablet/worldwide/2023
// For the month of December 2023

// export const desktopOS = [
//     {
//       label: 'Somewhat satisfied',
//       value: 72.72,
//     },
//     {
//       label: 'OS X',
//       value: 16.38,
//     },
//     {
//       label: 'Linux',
//       value: 3.83,
//     },
//     {
//       label: 'Chrome OS',
//       value: 2.42,
//     },
//     {
//       label: 'Other',
//       value: 4.65,
//     },
//   ];

export const mobileOS = [
    {
        label: 'Verify Satisfied',
        value: 68.48,
    },
    {
        label: 'Somewhat satisfied',
        value: 72.72,
    },
    {
        label: 'Neutral',
        value: 58.71,
    },
    {
        label: 'Disappointed',
        value: 18.71,
    },
    {
        label: 'Raised Ticket',
        value: 2.71,
    },
];

export const platforms = [
    {
        label: 'Mobile',
        value: 59.12,
    },
];

const normalize = (v: number, v2: number) => Number.parseFloat(((v * v2) / 100).toFixed(2));

export const Satisfaction = [
    ...mobileOS.map((v) => ({
        ...v,
        label: v.label === 'Other' ? 'Other (Mobile)' : v.label,
        value: normalize(v.value, platforms[0].value),
        style: {
            fontSize: 8, // set font size to 12 pixels
            fontWeight: 'normal', // set font weight to normal
        },
    })),
    // ...desktopOS.map((v) => ({
    //   ...v,
    //   label: v.label === 'Other' ? 'Other (Desktop)' : v.label,
    //   value: normalize(v.value, platforms[1].value),
    // })),
];

export const valueFormat = (item: { value: number }) => `${item.value}%`;
