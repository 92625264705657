import * as React from "react";
import {
  Box,
  Button,
  Card,
  Tooltip,
  Typography,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";
import DrawIcon from "@mui/icons-material/Draw";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Horizontal_slider.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/system";
import {
  Book_courses,
  fetchRecommendedCourses,
} from "../../api/recommendation_related_service";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';

function HoriCourseSlide() {
  const [course, setCourse] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const user_token = Cookies.get("connectedMe")

  React.useEffect(() => {
    fetchRecommendedCourseData();
  }, []);

  const fetchRecommendedCourseData = async () => {
    try {
      const response = await fetchRecommendedCourses({ token: user_token });
      setCourse(response || []);
      console.log("course");
      console.log(response);
    } catch (error) {
      console.error("Error fetching recommended courses:", error);
    }
  };

  const handelBook = async (courseId) => {
    if (user_token) {
      try {
        await Book_courses({ course_id: courseId, token: user_token }); // Booking the course
        setOpenSnackbar(true); // Show snackbar on successful booking
        await fetchRecommendedCourseData(); // Fetch updated recommended courses

        // Optionally call handelBook again if needed
        // For example, to allow booking another course right after:
        // handelBook(courseId); // Uncomment this line if you want to allow rebooking
      } catch (error) {
        console.error("Error booking the course:", error);
      }
    } else {
      console.error("User token is missing when booking the course.");
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const PreviousBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowBackIosNewIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
      </div>
    );
  };

  const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowForwardIosIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
      </div>
    );
  };

  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const slideshow = isMobileScreen ? 1 : isSmallScreen ? 3 : 5;

  const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(1),
    border: "1px solid #2f3348",
    borderRadius: theme.spacing(1),
    boxShadow: "0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)",
    width: isMobileScreen ? "280px" : "400px",
    margin: isMobileScreen ? "0 10px" : "0 20px",
    display: "flex",
    flexDirection: "column",
    height: "350px",
    position: "relative",
  }));

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideshow,
    slidesToScroll: 1,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    centerMode: isMobileScreen,
    centerPadding: "40px",
  };

  return (
    <Box
      sx={{
        width: "85vw",
        marginLeft: isMobileScreen ? "0px" : "60px",
      }}
    >
      <Slider {...settings}>
        {course.length === 0 ? (
          <StyledCard
            sx={{
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                position: "relative",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <DrawIcon
                sx={{ fontSize: 64, color: "green", marginBottom: "20px" }}
              />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#333", marginBottom: "10px" }}
              >
                No Courses Available
              </Typography>
            </Box>
          </StyledCard>
        ) : (
          course.map((courseData, index) => (
            <StyledCard key={index}>
              {/* Header Section */}
              <Box sx={{ p: 2, borderBottom: "1px solid #2f3348" }}>
                <Tooltip title={courseData.course_name}>
                  <Typography variant="h5" component="div" noWrap>
                    {courseData.course_name}
                  </Typography>
                </Tooltip>
              </Box>

              {/* Description Section - Scrollable */}
              <Box
                sx={{
                  flex: 1,
                  overflow: "auto",
                  p: 2,
                  mb: 15,
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  {courseData.course_details.length > 250
                    ? `${courseData.course_details.substring(0, 250)}...`
                    : courseData.course_details}
                </Typography>
              </Box>

              {/* Button Section - Fixed at bottom */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  p: 1.5,
                  borderTop: "1px solid #D3D3D3",
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                  backgroundColor: "white",
                }}
              >
                <Tooltip title={"BOOK COURSE"}>
                  <Button
                    variant="outline"
                    sx={{
                      border:
                        courseData.status === "booked"
                          ? "none"
                          : "1px solid #2f3348",
                      fontSize: "0.8rem",
                    }}
                    disabled={courseData.status == "booked"}
                    onClick={() => handelBook(courseData.course_id)}
                  >
                    <BookmarkIcon />
                  </Button>
                </Tooltip>
                <Button
                  variant="contained"
                  component={Link}
                  to={courseData.course_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    backgroundColor: "#2f3348",
                    fontSize: "0.75rem",
                    padding: "6px 12px",
                    "&:hover": { backgroundColor: "#3b3f55" },
                  }}
                >
                  Start Course
                </Button>
              </Box>
            </StyledCard>
          ))
        )}
      </Slider>

      {/* Snackbar for Course Booking */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Course successfully booked!
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default HoriCourseSlide;
