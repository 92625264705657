import network_conn from "../utils/network_config";

export const post_notification = async (request) => {
    try {
      const response = await fetch(
        network_conn.api_endpoint_v1 + "notification/post_notification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
        }
      );
  
      if (response.ok) {
        const responseData = await response.json();
        console.log("Data inserted successfully:", responseData);
      } else {
        throw new Error("Failed to send data: " + response.statusText);
      }
    } catch (error) {
      throw error;
    }
  };

  export const markNotificationAsRead = async (request) => {
    try {
      const response = await fetch(
        network_conn.api_endpoint_v1 + "notification/post_notification_status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
        }
      );
  
      if (response.ok) {
        const responseData = await response.json();
        console.log("Data inserted successfully:", responseData);
      } else {
        throw new Error("Failed to send data: " + response.statusText);
      }
    } catch (error) {
      throw error;
    }
  };

  export const fetchNotification = async (fetchreq) => {
    try {
        const response = await fetch(network_conn.api_endpoint_v1 + 'notification/fetch_notification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(fetchreq),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch notification');
        }
        const data = await response.json();
        console.log(data)
        return data;
        
    } catch (error) {
        console.error('Error fetching profile:', error);
        throw error;
    }
};

export const clearNotification = async (fetchreq) => {
  try {
    const response = await fetch(`${network_conn.api_endpoint_v1}notification/clear_notification`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fetchreq),
    });

    if (!response.ok) {
      const errorDetail = await response.json();
      throw new Error(`Failed to clear notification: ${response.status} ${response.statusText} - ${errorDetail.message}`);
    }

    const data = await response.json();
    console.log("Notification clear response:", data);
    return data;
  } catch (error) {
    console.error(`Error clearing notification for user ${fetchreq.user_uuid}:`, error.message);
    throw error;
  }
};



export const RescheduleMailResponse = async (request) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "calender_service/reschedule_mail_response",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      console.log("Data inserted successfully:", responseData);
    } else {
      throw new Error("Failed to send data: " + response.statusText);
    }
  } catch (error) {
    throw error;
  }
};