import {
  Badge,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import SearchEle from "../components/Menu/searchComp";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import { useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import Cookies from 'js-cookie';
import StarBorderIcon from "@mui/icons-material/StarBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import {
  fetchRecommendedCourses,
  fetchRecommendedMentors,
} from "../api/recommendation_related_service";
import { Link } from "react-router-dom";

function UserRecom() {
  const user_token = Cookies.get("connectedMe")
  const [value, setValue] = React.useState(0);

  const [mentor, setMentor] = React.useState([]);
  const [course, setCourse] = React.useState([]);

  useEffect(() => {
    // console.log("useEffect is called");
    fetchRecommendedMentorData();
  }, []);
  useEffect(() => {
    // console.log("useEffect is called");
    fetchRecommendedCourseData();
  }, []);

  const fetchRecommendedMentorData = async () => {
    try {
      // console.log("Fetching recommended mentors...");
      const response = await fetchRecommendedMentors({
        token: user_token,
      });

      // console.log(response);

      setMentor(response || []);
      console.log(response);
    } catch (error) {
      console.error("Error fetching recommended mentors:", error);
    }
  };
  const fetchRecommendedCourseData = async () => {
    try {
      // console.log("Fetching recommended mentors...");
      const response = await fetchRecommendedCourses({
        token: user_token,
      });

      // console.log(response);

      setCourse(response || []);
      console.log(response);
    } catch (error) {
      console.error("Error fetching recommended mentors:", error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const skills = [
    { name: "React", number: 1 },
    { name: "Java", number: 6 },
    { name: "Python", number: 3 },
    { name: "Node.js", number: 2 },
    { name: "C++", number: 5 },
  ];
  const numberOfStars = Math.floor(Math.random() * 5) + 1;

  return (
    <>
      <Stack>
        <Stack>
          <Typography variant="h4" sx={{ margin: "20px 0 0 20px" }}>
            Recommendation
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ margin: "0 0 20px 20px", color: "grey" }}
          >
            Discover Highly Recommended Picks: Expert-Approved Selections to
            Elevate Your Experience.
          </Typography>
        </Stack>
        <Stack direction={"row"}>
          <SearchEle />
        </Stack>
        <Divider />
        <Stack
          sx={{ margin: "0 0 20px 20px", color: "grey", display: "flex" }}
          direction={"row"}
        >
          <Typography mt={1} variant="subtitle1">
            Discover Your Perfect Matched
          </Typography>
          <Box>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Mentors" />
              <Tab label="Courses" />
            </Tabs>
          </Box>
        </Stack>
        <Stack sx={{ margin: "20px" }}>
          {value === 0 && (
            <Typography variant="body1">
              <Grid container spacing={3}>
                {mentor.map((mentorData, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card
                      sx={{
                        maxWidth: 345,
                        height: 360,
                        border: "1px solid black",
                        position: "relative", // Make the card the reference for absolute positioning
                      }}
                    >
                      <CardHeader
                        sx={{
                          backgroundColor: "#2F3348",
                          color: "#fff",
                        }}
                        avatar={
                          <Link
                            to={`/d/dashboard/mentorProfile`}
                            state={{ mentorData }}
                            style={{ textDecoration: "none" }}
                          >
                            <Avatar
                              aria-label="recipe"
                              src={
                                mentorData.image ? mentorData.image : undefined
                              }
                            />
                          </Link>
                        }
                        title={`${mentorData.first_name} ${mentorData.last_name}`}
                        subheader={mentorData.professional_info}
                        subheaderTypographyProps={{ sx: { color: "#808080" } }}
                      />
                      <Divider />
                      <CardContent>
                        <div>
                          {mentorData.skills.map((skill, skillIndex) => (
                            <Badge
                              key={skillIndex}
                              badgeContent={skill.skill_level}
                              color="error"
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              sx={{
                                "& .MuiBadge-badge": {
                                  right: 10,
                                  top: 10,
                                  border: `2px solid white`,
                                  padding: "0 4px",
                                },
                              }}
                            >
                              <Chip
                                label={skill.skill_name}
                                sx={{ margin: 1 }}
                              />
                            </Badge>
                          ))}
                        </div>
                      </CardContent>

                      {/* Divider and CardActions positioned absolutely at the bottom */}
                      <Divider
                        sx={{
                          position: "absolute", // Absolute positioning
                          bottom: 50, // Positioned just above the CardActions
                          left: 0,
                          right: 0,
                        }}
                      />
                      <CardActions
                        disableSpacing
                        sx={{
                          position: "absolute", // Absolute positioning
                          bottom: 0, // Align at the bottom of the card
                          left: 0,
                          right: 0,
                          padding: "8px 16px", // Adjust padding as needed
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ width: "100%" }}
                        >
                          <Stack direction="row" spacing={0.5}>
                            {[0, 1, 2, 3, 4].map((starIndex) =>
                              starIndex < mentorData.rating ? (
                                <StarIcon
                                  key={starIndex}
                                  sx={{ color: "#ffd700" }}
                                />
                              ) : (
                                <StarBorderIcon key={starIndex} />
                              )
                            )}
                          </Stack>
                          <Button
                            variant="contained"
                            sx={{ backgroundColor: "#2f3348" }}
                          >
                            Book
                          </Button>
                        </Stack>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Typography>
          )}
          {value === 1 && (
            <Typography variant="body1">
              <Grid container spacing={4}>
                {course.map((courseData, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Card
                        sx={{
                          maxWidth: 345,
                          height: 360,
                          border: "1px solid black",
                          display: "flex",
                          flexDirection: "column",
                          position: "relative", // Ensure the Card is the reference point for absolute positioning
                        }}
                      >
                        <Stack
                          sx={{
                            flexGrow: 1, // Allows the Stack to grow and take up available space
                          }}
                        >
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {courseData.course_name}
                            </Typography>
                          </CardContent>
                          <Divider />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {courseData.course_details.length > 320
                                ? `${courseData.course_details.substring(
                                    0,
                                    320
                                  )}...`
                                : courseData.course_details}
                            </Typography>
                          </CardContent>
                        </Stack>
                        <Divider />
                        <CardActions
                          sx={{
                            justifyContent: "center",
                            position: "absolute", // Position the button absolutely
                            bottom: 0, // Align it to the bottom of the card
                            left: 0,
                            right: 0,
                            mb: 1, // Optional: Adds a small margin at the bottom if needed
                          }}
                        >
                          <Button
                            variant="contained"
                            component={Link}
                            to={courseData.course_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              backgroundColor: "#2f3348",
                              "&:hover": {
                                backgroundColor: "#3b3f55", // A slightly lighter shade for the fade effect
                              },
                            }}
                          >
                            Continue Learning
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
}

export default UserRecom;
