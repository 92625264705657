import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
} from "react-router-dom";
import UserDash from "./views/user_dashboard";
import User_analytics_dash from "./views/user_sub_dash";
import UserPlanPath from "./views/user_planpath";
import UserSessionPlt from "./views/user_upcoming_session";
import UserCourse from "./views/user_course";
import UserRecom from "./views/user_recom";
import MyProfile from "./components/Profile/MyProfile";
import LoginForm from "./components/LoginForm/LoginForm";
import UserCalendar from "./views/user_calendar";
import UserCardInfo from "./views/user_session_det";
import UserSponsor from "./views/user_sponsor";
import PrivateRoutes from "./utils/auth_provider";
import Job from "./components/Job/Job";
import Admin from "./components/Admin/Admin";
import Jobinfo from "./components/Job/Jobinfo";
import Card_info from "./components/Menu/Card_info";
import Admincourse from "./components/AdminCourse/Admincourse";
import MentorProfilePage  from "./components/Profile/mentors_profile"
import Mentor_skill from "./components/Profile/mentor_skill"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Verify_Email from "./components/Forgot_Password/Verify_Email";
import Set_Password from "./components/Forgot_Password/Set_Password";
import Verify_otp from "./components/Forgot_Password/Verify_otp";
import Mentee_KPI from "./components/KPI/Mentee_KPI";
import Feedback from "./components/KPI/Feedback";
import Admin_Feedback from "./components/Admin/Admin_Feedback";

// import App from './App';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<LoginForm />} />
      <Route path="mentee-feedback" element={<Feedback/>} />
      <Route path="/forgot_pass" element={<Verify_Email/>}/>
      <Route path="/forgot_pass/:emailInput" element={<Verify_otp/>}/>
      <Route path="/forgot_pass/:emailInput/generate_pass" element={<Set_Password/>}/>
      
      <Route element={<PrivateRoutes />}>
        <Route path="/d" element={<UserDash />}>
          <Route path="dashboard" element={<User_analytics_dash />} />
          <Route path="dashboard/:meeting_id" element={<Card_info />} />
          <Route path="dashboard/mentorProfile" element={<MentorProfilePage />} />
          <Route path="dashboard/mentorProfile/mentorProfile" element={<Mentor_skill />} />
          <Route path="calendar" element={<UserCalendar />} />
          <Route path="UserSess" element={<UserSessionPlt />} />
          <Route path="course" element={<UserPlanPath />} />
          <Route path="UserCourse" element={<UserCourse />} />
          <Route path="UserRecom" element={<UserRecom />} />
          <Route path="MyProfile" element={<MyProfile />} />
          <Route path="UserSess/:cardD" element={<UserCardInfo />} />
          <Route path="Sponsor" element={<UserSponsor />} />
          <Route path="KPI" element={<Mentee_KPI/>}/>
          <Route path="Admin" element={<Admin />} />
          <Route path="Job" element={<Job />} />
          <Route path="Admincourse" element={<Admincourse />} />
          <Route path="Job/:advertisementId" element={<Jobinfo />} />
          <Route path="create-feedback-form" element={<Admin_Feedback />} />
          <Route path="mentee-feedback" element={<Feedback/>} />
        </Route>
      </Route>
    </Route>
  )
);
const theme = createTheme({
  palette: {
    primary: {
      main: '#2f3348',
    },
  },
});
root.render(
  <React.StrictMode>
    {/* <App /> */}
    {/* <ResponsiveAppBar /> */}
    <ThemeProvider theme={theme}>
    <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);
