import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  TextField,
  ButtonGroup,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Badge,
  Avatar,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
// import "react-circular-progressbar/dist/styles.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ArrowOutwardRoundedIcon from "@mui/icons-material/ArrowOutwardRounded";
import "./../components/Menu/HorizontalMenu.css";
import HoriSlide from "../components/Menu/horizontal_slider";
import HoriAISlide from "../components/Menu/horizontal_mentor_slider";
import HoriSlide_mentor from "../components/Menu/horizontal_mentor";
import HoriSlideStatic from "../components/Menu/horizontal_slidder_static";
import SkillCard from "../components/flash_card/flash_card";
// import { BarPlot } from "@mui/x-charts/BarChart";
// import { LinePlot } from "@mui/x-charts/LineChart";
// import { ChartContainer } from "@mui/x-charts/ChartContainer";
// import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
// import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BarChart } from "@mui/x-charts/BarChart";
import { fetchAppointments } from "../api/scheduler_related_services";
import SkillSelectionPopup from "../components/skill_selection/SkillSelectionPopup";
import { fetchSkillGap } from "../api/skill_catalog";
import userSess from "../store/user_session_control";
import { fetchProfile } from "../api/profile_related_service";
import useUserPrefSess from "../store/dash_session";
import { fetchDashSession } from "../api/session_service";
import HoriMentorSlide from "../components/Menu/horizontal_mentor_slider";
import HoriCourseSlide from "../components/Menu/horizontal_course_slider";
import Cookies from 'js-cookie';

function User_analytics_dash(isSmallScreen) {
  //Drop down
  const [selectedSkill, setSelectedSkill] = useState("");
  const [professionalAmbitions, setProfessionalAmbitions] = useState([]);
  const [professionalAmbitionsLevel, setProfessionalAmbitionsLevel] = useState(
    []
  );
  const [skillGap, setSkillGap] = useState([]);
  const [totalDesirableSkills, setTotalDesirableSkills] = useState([]);

  //x={skillGap} y={totalDesirableSkills}
  useEffect(() => {
    const fetchAmbition = async () => {
      const profile = await fetchProfile({ user_token: user_token });
      const skills = profile.professional_ambitions.map(
        (ambition) => ambition.skill_name
      );
      const levels = profile.professional_ambitions.map(
        (ambition) => ambition.skill_level
      );
      setProfessionalAmbitions(skills);
      setProfessionalAmbitionsLevel(levels);
    };
    fetchAmbition();
  }, []);
  useEffect(() => {
    if (selectedSkill && user_token) {
      const skillLevelGap = async () => {
        const gap = await fetchSkillGap({ user_token, skill: selectedSkill });
        const level_gap = gap.skill_level_gap;
        const total_desirable_skill = gap.total_desirable_skills;
        setSkillGap(level_gap);
        setTotalDesirableSkills(total_desirable_skill);
      };
      skillLevelGap();
    } else {
      setSkillGap(0);
      setTotalDesirableSkills(0);
    }
  }, [selectedSkill]);

  const handleChange = (event) => {
    setSelectedSkill(event.target.value);
    setIsCardOpen(true);
  };

  const steps = [
    "Level 1",
    "Level 2",
    "Level 3",
    "Level 4",
    "Level 5",
    "Level 6",
    "Level 7",
  ];

  const series = [
    {
      type: "bar",
      stack: "",
      yAxisKey: "eco",
      data: [2, 5, 3, 4, 1],
    },
    {
      type: "bar",
      stack: "",
      yAxisKey: "eco",
      data: [5, 6, 2, 8, 9],
    },
    {
      type: "line",
      yAxisKey: "pib",
      color: "red",
      data: [1000, 1500, 3000, 5000, 10000],
    },
  ];
  function StepperCpm({ skillGap }) {
    const completedSteps = steps.length - skillGap;
    return (
      <>
        <Stepper
          activeStep={1}
          alternativeLabel
          sx={{
            "& .MuiStepLabel-label": {
              fontSize: { xs: "0.7rem", sm: "1rem" }, // Smaller font on small screens
            },
            "& .MuiStepIcon-root": {
              fontSize: { xs: "1.3rem", sm: "2rem" }, // Smaller step icon on small screens
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </>
    );
  }

  function RadioCpm({ skillGap }) {
    const completedLevels = 7 - skillGap;
    return (
      <>
        {Array.from({ length: 7 }).map((_, index) => (
          <Radio
            key={index}
            checked={index < completedLevels}
            value={`level-${index + 1}`}
            name="radio-buttons"
            inputProps={{ "aria-label": `Level ${index + 1}` }}
          />
        ))}
      </>
    );
  }

  //MENTOR MENTEE BUTTONS
  const [openM, setOpenM] = React.useState(true);
  const handleHoriMenu = (toggle) => () => {
    setOpenM(toggle);
  };
  const buttonStylesMentor = {
    color: openM ? `#FFFFFF` : `#808080`,
    backgroundColor: openM ? `rgba(246,90,76,1)` : "#FFFFFF",
    borderColor: openM ? "#FFA500" : "#808080",
    "&:hover": {
      backgroundColor: openM ? `rgba(246,90,76,1)` : `rgba(246,90,76,0.2)`,
      borderColor: openM ? "#FFA500" : "#808080",
    },
  };
  const buttonStylesMentee = {
    color: openM ? `#808080` : `#FFFFFF`,
    backgroundColor: openM ? "#FFFFFF" : `rgba(246,90,76,1)`,
    borderColor: openM ? "#808080" : "#FFA500",
    "&:hover": {
      backgroundColor: openM ? `rgba(246,90,76,0.2)` : `rgba(246,90,76,1)`,
      borderColor: openM ? "#808080" : "#FFA500",
    },
  };

  const chartsData = [
    {
      xAxis: [{ scaleType: "band", data: ["group A", "group B", "group C"] }],
      series: [{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }],
      about:
        "This chart1 represents the comparison between different groups (group A, group B, and group C) based on their respective data points. For example, group A has values of 4, 3, and 5 in the series data. Similarly, group B has values of 1, 6, and 3, and group C has values of 2, 5, and 6. This comparison helps in understanding the trends and variations within each group over a specific time period.",
    },
    {
      xAxis: [
        { scaleType: "band", data: ["group AA", "group BB", "group CC"] },
      ],
      series: [{ data: [1, 2, 3] }, { data: [1, 5, 3] }, { data: [2, 9, 6] }],
      about:
        "This chart2 showcases the comparison between different categories (group AA, group BB, and group CC) based on their respective data points. For instance, group AA has values of 1, 2, and 3 in the series data. Likewise, group BB has values of 1, 5, and 3, and group CC has values of 2, 9, and 6. Understanding these comparisons can provide insights into the performance and trends across various categories.",
    },
    {
      xAxis: [
        {
          scaleType: "band",
          data: ["group Aaaa", "group Bbbbbbbbb", "group cccccC"],
        },
      ],
      series: [{ data: [4, 2, 5] }, { data: [1, 6, 4] }, { data: [2, 5, 6] }],
      about:
        "This chart3 illustrates the relationship between different entities (group Aaaa, group Bbbbbbbbb, and group cccccC) based on their corresponding data points. For instance, group Aaaa has values of 4, 2, and 5 in the series data. Similarly, group Bbbbbbbbb has values of 1, 6, and 4, and group cccccC has values of 2, 5, and 6. Analyzing these relationships can uncover patterns and correlations that impact decision-making and strategic planning.",
    },
    // Add more chart data objects as needed
  ];
  const [currentChartIndex, setCurrentChartIndex] = React.useState(0);

  const handleNextChart = () => {
    setCurrentChartIndex((prevIndex) => (prevIndex + 1) % chartsData.length);
  };

  const handlePrevChart = () => {
    setCurrentChartIndex((prevIndex) =>
      prevIndex === 0 ? chartsData.length - 1 : prevIndex - 1
    );
  };

  // const { user_token, updateSess } = userSess()
  const [appEventLength, setAppEventLength] = React.useState(0);
  const [start, setStart] = React.useState("");
  const [end, setEnd] = React.useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [skills, setSkills] = useState([]);
  const user_token = Cookies.get("connectedMe")
  const handleUpdateSkillsClick = () => {
    setOpenSkillSelection(true);
    setOpenPopup(false);
  };

  const handleSkillSelectionClose = () => {
    setOpenSkillSelection(false);
  };

  const [openSkillSelection, setOpenSkillSelection] = useState(false);
  React.useEffect(() => {
    const handleClick_cal = async () => {
      const app_events = await fetchAppointments({ token: user_token });
      if (app_events.length === 0) {
        setStart("0/0/0000");
        setEnd("0/0/0000");
        setAppEventLength(0);
      }
      const start =
        app_events.startDate !== undefined
          ? app_events[0].startDate
          : new Date().getTime();
      setStart(handleDate(start));
      const end =
        app_events.endDate !== undefined
          ? app_events[app_events.length - 1].endDate
          : new Date().getTime();
      setEnd(handleDate(end));
      setAppEventLength(app_events.length);
    };
    const fetchSkills = async () => {
      const profile = await fetchProfile({ user_token: user_token });
      setSkills(profile.skills);

      if (profile.skills.length === 0) {
        setTimeout(() => {
          setOpenPopup(true);
        }, 2000);
      }
    };

    handleClick_cal();
    fetchSkills();
  }, []);

  const handleDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };
  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const isXsScreen = useMediaQuery("(max-width:900px)");
  const smallScreen = useMediaQuery("(max-width:1200px)");

  const [limit, setLimit] = useState(smallScreen ? 3 : 5);
  const [page, setPage] = useState(0);
  const { sess, setuseUserPrefSess } = useUserPrefSess();

  const handleClick_ana = async () => {
    const body = {
      limit: limit,
      page: page,
    };
    try {
      const app_events = await fetchDashSession({
        token: user_token,
        ...body,
      });
      setuseUserPrefSess(app_events);
    } catch (error) {
      console.error("Failed to fetch session:", error);
    }
  };
  React.useEffect(() => {
    handleClick_ana();
  }, [limit, page]);

  const refreshData = async () => {
    if (sess.length - 1 == 0) {
      console.log("hi");
      setPage(0);
    }
    handleClick_ana();
  };
  const [showAll, setShowAll] = useState(false);
  const [open, setOpen] = useState(false);
  const handleToggleShowAll = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setShowAll((prev) => !prev);
    setOpen(true);
  };
  const [isCardOpen, setIsCardOpen] = useState(false);

  const handleOpenCard = () => {
    setIsCardOpen(true);
  };

  const handleCloseCard = () => {
    setIsCardOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid2 container direction={"column"} sx={{ padding: "25px" }}>
        <Stack spacing={2}>
          <Stack spacing={2} direction="row">
            <FormControl variant="standard" fullWidth lg={10} md={10.6} xs={12}>
              <InputLabel id="professional-ambitions-select-label">
                Your skill gap
              </InputLabel>
              <Select
                labelId="professional-ambitions-select-label"
                id="professional-ambitions-select"
                value={selectedSkill}
                onChange={handleChange}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                MenuProps={{
                  PaperProps: {
                    onClick: (e) => e.stopPropagation(),
                  },
                }}
              >
                {professionalAmbitions
                  .slice(0, showAll ? professionalAmbitions.length : 4)
                  .map((ambition, index) => (
                    <MenuItem key={index} value={ambition}>
                      <Badge
                        color="error"
                        badgeContent={professionalAmbitionsLevel[index]}
                      >
                        {ambition}
                      </Badge>
                    </MenuItem>
                  ))}
                {professionalAmbitions.length > 4 && (
                  <MenuItem
                    onClick={handleToggleShowAll}
                    style={{ textAlign: "center" }}
                  >
                    <Button color="primary" fullWidth>
                      {showAll ? "Show Less" : "Show More"}
                    </Button>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Stack>
          {selectedSkill &&
            skillGap !== null &&
            totalDesirableSkills !== null && (
              <Stack>
                <SkillCard
                  x={skillGap}
                  y={totalDesirableSkills}
                  skill_name={selectedSkill}
                  open={isCardOpen}
                  onClose={handleCloseCard}
                />
              </Stack>
            )}
          <Box sx={{ flexGrow: 1 }} />
          {isSmallScreen ? (
            <StepperCpm skillGap={skillGap} />
          ) : (
            <RadioCpm skillGap={skillGap} />
          )}
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
        <Stack direction="column" spacing={2}>
          <Divider orientation="horizontal" flexItem />
          <Typography
            variant="h4"
            color="#2f3348"
            sx={{
              fontWeight: "medium",
              paddingLeft: "15px",
              fontSize: {
                xs: "1rem", // Smaller font size for extra-small screens (mobile)
                sm: "h4.fontSize", // Default font size for small screens (sm) and above
              },
            }}
          >
            My Progress (review)
          </Typography>

          <Stack
            direction={{ xs: "column", sm: "row" }} // Stack vertically on small screens, row on larger screens
            spacing={3}
            padding={2}
          >
            <Stack
              sx={{ width: { xs: "100%", sm: "85%" }, position: "relative" }}
            >
              <Card
                sx={{
                  background: "#FAF9F6",
                  borderRadius: "0",
                }}
              >
                <Stack
                  height={{ xs: 150, sm: 200 }} // Smaller height for small screens, keep original height for desktop
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* Arrow for previous chart */}
                  <ArrowBackIosIcon
                    onClick={handlePrevChart}
                    sx={{
                      color: "#f65a4c",
                      cursor: "pointer",
                      position: "absolute",
                      left: 10,
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1, // Ensure the arrow is above other elements
                    }}
                  />
                  <Stack
                    sx={{ height: "100%" }}
                    width={{ xs: 280, sm: 500 }} // Responsive width for small screens
                  >
                    <BarChart
                      xAxis={chartsData[currentChartIndex].xAxis}
                      series={chartsData[currentChartIndex].series}
                      // width={500} // Keep original width for desktop
                      // height={200} // Keep original height for desktop
                    />
                  </Stack>

                  {/* Arrow for next chart */}
                  <ArrowForwardIosIcon
                    onClick={handleNextChart}
                    sx={{
                      color: "#f65a4c",
                      cursor: "pointer",
                      position: "absolute",
                      right: 10,
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1, // Ensure the arrow is above other elements
                    }}
                  />
                </Stack>
              </Card>
              <Divider />
              <Card
                sx={{
                  background: "#2f3348",
                  borderRadius: "0",
                }}
              >
                <Stack
                  sx={{
                    height: { xs: "150px", sm: "170px" }, // Adjust height for small screens
                    overflowY: "auto", // Allow vertical scrolling
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide the scrollbar for WebKit browsers (e.g., Chrome, Safari)
                    },
                    msOverflowStyle: "none", // Hide scrollbar for Internet Explorer and Edge
                    scrollbarWidth: "none", // Hide scrollbar for Firefox
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      "margin-left": "10px",
                      "margin-right": "10px",
                      fontSize: { xs: "0.8rem", sm: "1rem" }, // Adjust font size for small screens
                    }}
                  >
                    <p>{chartsData[currentChartIndex].about}</p>
                  </Typography>
                </Stack>
              </Card>
            </Stack>

            {/* Adjust layout of cards for small screens */}
            <Stack
              direction={{ xs: "row", sm: "column" }} // Change to row direction on small screens
              spacing={1}
              sx={{
                width: { xs: "100%", sm: "15%" },
                marginTop: { xs: 2, sm: 0 },
              }} // Full width on small screens
            >
              <Card
                sx={{
                  background: "#dfece0",
                  height: { xs: "auto", sm: "50%" }, // Auto height for small screens
                  width: { xs: "50%", sm: "100%" }, // Adjust width for horizontal layout on small screens
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Stack>
                    <Typography fontSize="small" fontWeight="medium">
                      Total Session Attendant
                    </Typography>
                  </Stack>
                  <Typography
                    variant="h1"
                    color="#4A6771"
                    fontSize={{ xs: "1.5rem", sm: "2.5rem" }}
                  >
                    {appEventLength}
                  </Typography>
                  <Typography fontSize="small">
                    <b>From:</b>
                    {start} <b>To:</b>
                    {end}
                  </Typography>
                </CardContent>
              </Card>

              <Card
                sx={{
                  background: "#e2edf9",
                  height: { xs: "auto", sm: "50%" }, // Auto height for small screens
                  width: { xs: "50%", sm: "100%" }, // Adjust width for horizontal layout on small screens
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Stack>
                    <Typography fontSize="small" fontWeight="medium">
                      Total Session Scheduled
                    </Typography>
                  </Stack>

                  <Typography
                    variant="h1"
                    color="#3A4856"
                    fontSize={{ xs: "1.5rem", sm: "2.5rem" }}
                  >
                    {appEventLength}
                  </Typography>
                  <Typography fontSize="small">
                    <b>From:</b>
                    {start} <b>To:</b>
                    {end}
                  </Typography>
                </CardContent>
              </Card>
            </Stack>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
        </Stack>

        <Divider orientation="horizontal" flexItem />

        <Stack sx={{ marginBottom: "20px" }}>
          <Stack
            direction="column"
            sx={{ marginTop: "10px", marginRight: "20px" }}
          >
            <Stack direction="row" sx={{ marginBottom: "20px" }}>
              <Typography
                variant={isXsScreen ? "h6" : "h4"}
                color="#2f3348"
                sx={{ fontWeight: "medium", paddingLeft: "15px" }}
              >
                Recommended Session
              </Typography>
              <Stack direction="row" sx={{ marginLeft: "auto" }}>
                {/* <ButtonGroup
                  variant="outlined"
                  size={isXsScreen ? "small" : "medium"}
                >
                  <Button
                    sx={{
                      ...buttonStylesMentor,
                      padding: isXsScreen ? "4px 8px" : "6px 16px",
                      fontSize: isXsScreen ? "0.75rem" : "1rem", 
                    }}
                    onClick={handleHoriMenu(true)}
                  >
                    <Typography
                      sx={{ fontSize: isXsScreen ? "0.75rem" : "1rem" }}
                    >
                      Mentee
                    </Typography>
                  </Button>
                  <Button
                    sx={{
                      ...buttonStylesMentee,
                      padding: isXsScreen ? "4px 8px" : "6px 16px", // Adjust padding
                      fontSize: isXsScreen ? "0.75rem" : "1rem", // Adjust font size
                    }}
                    onClick={handleHoriMenu(false)}
                  >
                    <Typography
                      sx={{ fontSize: isXsScreen ? "0.75rem" : "1rem" }}
                    >
                      Mentor
                    </Typography>
                  </Button>
                </ButtonGroup> */}
              </Stack>
            </Stack>
            {openM ? (
              <HoriSlide
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                refreshData={refreshData}
              />
            ) : (
              <HoriSlide_mentor />
            )}
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ marginTop: "20px" }}
            >
              <Link
                component={RouterLink}
                to="/d/UserSess"
                sx={{ textAlign: "center" }}
              >
                <Button variant="contained" sx={{ marginBottom: 2 }}>
                  More items...
                </Button>
              </Link>
            </Stack>
            <Divider />
          </Stack>
        </Stack>

        <Stack sx={{ marginBottom: "20px" }}>
          <Stack direction="column" gap={3}>
            <Typography
              variant={isXsScreen ? "h6" : "h4"} // Adjust variant based on screen size
              color="#2f3348"
              sx={{ fontWeight: "medium", paddingLeft: "15px" }}
            >
              Recommended Mentors
            </Typography>
            <HoriMentorSlide />
          </Stack>
          <Stack
            direction="row"
            justifyContent="center" // Center "More items..."
            sx={{ marginTop: "20px" }}
          >
            <Link
              component={RouterLink}
              to="/d/UserRecom"
              sx={{
                textAlign: "center",
                fontSize: isXsScreen ? "0.875rem" : "1rem",
              }} // Adjust font size for small screens
            >
              <Button variant="contained" sx={{ marginBottom: 2 }}>
                More items...
              </Button>
            </Link>
          </Stack>
          <Divider />
        </Stack>

        <Stack sx={{ marginBottom: "20px" }}>
          <Stack direction="column" gap={3}>
            <Stack
              direction={isXsScreen ? "column" : "row"} // Change direction for small screens
              alignItems={isXsScreen ? "flex-start" : "center"}
              spacing={isXsScreen ? 0 : 1}
            >
              <Typography
                variant={isXsScreen ? "h6" : "h4"} // Adjust font size for small screens
                color="#2f3348"
                sx={{ fontWeight: "medium", paddingLeft: "15px" }}
              >
                Recommended Courses
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                // sx={{ paddingTop: isXsScreen ? "10px" : "0" }} // Add spacing for small screens
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    display: "flex",

                    paddingLeft: isXsScreen ? "15px" : "10px",
                    fontSize: isXsScreen ? "0.75rem" : "1rem",
                  }}
                >
                  Powered by
                </Typography>
                <img
                  alt="Allison"
                  src="/alison.svg" // Ensure the logo path is correct
                  style={{
                    width: isXsScreen ? "40px" : "60px",
                    height: isXsScreen ? "30px" : "50px",
                  }} // Adjust image size for small screens
                />
              </Stack>
            </Stack>
            <HoriCourseSlide />
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{ marginTop: "20px" }}
          >
            <Link
              component={RouterLink}
              to="/d/UserCourse"
              sx={{
                textAlign: "center",
                fontSize: isXsScreen ? "0.875rem" : "1rem",
              }} // Adjust font size for the link
            >
              <Button variant="contained" sx={{ marginBottom: 2 }}>
                More items...
              </Button>
            </Link>
          </Stack>
          <Divider />
        </Stack>
      </Grid2>
      <Dialog open={openPopup} onClose={() => {}}>
        <DialogTitle>Update Your Skills</DialogTitle>
        <DialogContent>
          <DialogContentText>
            It looks like you haven't updated your Role yet. Please update your
            role to get the most out of our platform.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUpdateSkillsClick}
            variant="contained"
            sx={{
              backgroundColor: "#2f3348",
              "&:hover": { backgroundColor: "#1c1f29" },
            }}
          >
            Update Role
          </Button>
        </DialogActions>
      </Dialog>
      <SkillSelectionPopup
        open={openSkillSelection}
        handleClose={handleSkillSelectionClose}
      />
    </>
  );
}
export default User_analytics_dash;
