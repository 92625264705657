import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Fab, TextField, Button, Typography, SpeedDial, SpeedDialAction, SpeedDialIcon
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Add } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddNewPlanPath from './AddNewPlanPath';
import UpdateExisting from './UpdateExisting';
import './PlanPath.css';
import usePlanpath from '../../store/PlanPath_data';
import { deleteplanPath, addPlan } from '../../api/planPath_related_services'
import userSess from '../../store/user_session_control'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetch_plan_path } from "../../api/planPath_related_services";
import useProfile from "../../store/profile_data";
import Cookies from 'js-cookie';

function CourseDetails() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopup2Open, setIsPopup2Open] = useState(false);
    const [editMode, setEditMode] = useState({ rowIndex: null, columnName: null });
    const [editedData, setEditedData] = useState({});
    const [tableData, setTableData] = useState([]);
    const data = usePlanpath((state) => (state.path_data));
    const deleteRow = usePlanpath((state) => (state.delete_row));
    const setPathData = usePlanpath((state) => (state.set_path_data));
    const scrollableRef = useRef(null);
    // const { user_token } = userSess()
    const user_token = Cookies.get("connectedMe")
    const [value, setValue] = React.useState(0);
    const { setUser } = useProfile();
    useEffect(() => {
        const fetchUserSessions = async () => {
            const plan_path = await fetch_plan_path({ token: user_token });
            setUser(plan_path);
            plan_path.forEach((path) => {
                const { details, course, date } = path;
                usePlanpath.getState().add_row(details, course, date);
            });
        };

        fetchUserSessions();
    }, [value, setUser, user_token]);


    // const plan_path = await fetch_plan_path({ token: user_token });
    // setUser(plan_path);
    // plan_path.forEach((path) => {
    //   const { details, course, date } = path;
    //   usePlanpath.getState().add_row(details, course, date);
    // });

    useEffect(() => {
        setPathData(data); // Initialize with current data
    }, []);

    useEffect(() => {
        setTableData(data);
    }, [data]);

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    const handlePopup2Open = () => {
        setIsPopup2Open(true);
    };

    const handlePopup2Close = () => {
        setIsPopup2Open(false);
    };

    const handleAddCourse = (newCourse) => {
        setTableData([...tableData, newCourse]);
        handlePopupClose();
    };

    const handleAddCourse2 = (newCourse) => {
        setTableData([...tableData, newCourse]);
        handlePopup2Close();
    };

    const handleEdit = (rowIndex, columnName, value) => {
        setEditMode({ rowIndex, columnName });
        setEditedData({ ...editedData, [rowIndex]: { ...editedData[rowIndex], [columnName]: value } });
    };

    const handleSave = async () => {
        const updatedTableData = [...tableData];
        Object.keys(editedData).forEach(rowIndex => {
            Object.keys(editedData[rowIndex]).forEach(columnName => {
                updatedTableData[rowIndex][columnName] = editedData[rowIndex][columnName];
            });
        });
        setTableData(updatedTableData);
        setEditMode({ rowIndex: null, columnName: null });
        setEditedData({});
        const monthMap = {
            Jan: '01',
            Feb: '02',
            Mar: '03',
            Apr: '04',
            May: '05',
            Jun: '06',
            Jul: '07',
            Aug: '08',
            Sep: '09',
            Oct: '10',
            Nov: '11',
            Dec: '12'
        };
        // Call the addPlan API for each edited row
        Object.keys(editedData).forEach(async rowIndex => {
            const row = updatedTableData[rowIndex];
            const { Details: details, plan_path_id } = row;
            Object.keys(editedData[rowIndex]).forEach(async columnName => {
                const course = editedData[rowIndex][columnName];
                const year = parseInt(columnName.slice(-2)) + 2000; 
                const monthAbbreviation = columnName.slice(0, 3); 
                const month = monthMap[monthAbbreviation]; 
                const date = `${year}-${month}-01`;
                try {
                    await addPlan({
                        details,
                        course,
                        date,
                        plan_path_id,
                        token: user_token,
                    });
                    console.log('PlanPath data saved successfully');
                } catch (error) {
                    console.error('Failed to save plan data:', error);
                }
            });
        });
    };
    const handleCancel = () => {
        setEditMode({ rowIndex: null, columnName: null });
        setEditedData({});
    };

    const handleDelete = async (rowIndex) => {
        const deletedDetails = tableData[rowIndex]["Details"];
        try {
            await deleteplanPath({ 'skill_to_plan': deletedDetails, 'token': user_token })
            console.log('PlanPath data deleted successfully');
            deleteRow(deletedDetails);
        } catch (error) {
            console.error('Failed to delete plan data:', error);
        }
        console.log("Deleted Row Details:", deletedDetails);
        setTableData(tableData.filter((_, index) => index !== rowIndex));
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2f3348',
            },
        },
    });

    const handleScroll = () => {
        if (scrollableRef.current) {
            const { scrollLeft } = scrollableRef.current;
            if (scrollLeft > 0) {
                scrollableRef.current.querySelectorAll('td:first-child').forEach(td => {
                    td.style.transform = `translateX(${scrollLeft}px)`;
                    td.style.left = '0';
                    td.style.zIndex = '1000';
                    td.style.backgroundColor = 'white';
                });
            } else {
                scrollableRef.current.querySelectorAll('td:first-child').forEach(td => {
                    td.style.transform = '';
                    td.style.left = '';
                    td.style.zIndex = '';
                    td.style.backgroundColor = '';
                });
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            handleScroll();
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Grid2 component={Paper} direction="column" >
                <Box sx={{ height: '100%' }}>
                    {tableData.length > 0 ? (
                        <TableContainer onScroll={handleScroll} ref={scrollableRef}>
                            <Table className="fullWidthTable" sx={{ minWidth: 3500 }} style={{ fontSize: '3rem' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold', width: '190px', height: '50px', borderRight: '1px solid #e0e0e0', position: 'sticky', left: '0', backgroundColor: 'white' }}>Details</TableCell>
                                        {[...Array(12)].map((_, index) => (
                                            <TableCell key={index} style={{ fontWeight: 'bold', width: '200px', height: '50px', borderRight: '1px solid #e0e0e0' }}>{['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][index] + '24'}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData.map((row, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            {Object.entries(row).map(([columnName, value]) => (
                                                <TableCell key={columnName} style={{ width: '190px', height: '100px', borderRight: '1px solid #e0e0e0' }}>
                                                    {editMode.rowIndex === rowIndex && editMode.columnName === columnName ? (
                                                        <TextField
                                                            value={editedData[rowIndex]?.[columnName] || value}
                                                            onChange={(e) => setEditedData({ ...editedData, [rowIndex]: { ...editedData[rowIndex], [columnName]: e.target.value } })}
                                                            inputProps={{ style: { width: '100%' }, maxLength: 50 }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {typeof value === 'string' && value.match(/.{1,18}/g)?.map((chunk, index) => (
                                                                <div key={index}>{chunk}</div>
                                                            ))}
                                                            {columnName === 'Details' && (
                                                                <DeleteIcon
                                                                    className="deleteIcon"
                                                                    sx={{ fontSize: 16, cursor: 'pointer' }}
                                                                    onClick={() => handleDelete(rowIndex)}
                                                                />
                                                            )}
                                                            <ModeEditIcon
                                                                className="editIcon"
                                                                sx={{ fontSize: 16, cursor: 'pointer' }}
                                                                onClick={() => handleEdit(rowIndex, columnName, value)}
                                                            />
                                                        </>
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                            <Typography variant="h5">Please add career path</Typography>
                        </Box>

                    )}
                    {editMode.rowIndex !== null && (
                        <Box sx={{ position: 'fixed', bottom: '25px', right: '100px' }}>
                            <Button onClick={handleSave}>Save</Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </Box>
                    )}
                    <Box sx={{ position: 'fixed', bottom: '20px', right: '40px' }}>
                        <SpeedDial
                            ariaLabel="Actions"
                            sx={{ position: 'fixed', bottom: '20px', right: '40px' }}
                            icon={<SpeedDialIcon />}
                        >
                            <SpeedDialAction
                                key="AddCourse"
                                icon={<Add />}
                                tooltipTitle="Add New Plan"
                                onClick={handlePopup2Open}
                            />
                            <SpeedDialAction
                                key="EditTable"
                                icon={<EditNoteIcon />}
                                tooltipTitle="Update Existing"
                                onClick={handlePopupOpen}
                            />
                        </SpeedDial>
                    </Box>
                </Box>
                <AddNewPlanPath open={isPopup2Open} onClose={handlePopup2Close} onAddCourse={handleAddCourse2} />
                <UpdateExisting open={isPopupOpen} onClose={handlePopupClose} onAddCourse={handleAddCourse} />
            </Grid2>
        </ThemeProvider>
    );
}

export default CourseDetails;
