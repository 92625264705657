import { React, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Chip,
  Stack,
  Typography,
  Modal,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Horizontal_slider.css";
import PeopleIcon from "@mui/icons-material/People";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/system";
import { post_notification } from "../../api/notification_related_service";
import { fetchRecommendedMentors } from "../../api/recommendation_related_service";
import { sendMentorBookingMail } from "../../api/mail_related_service";
import { Book_mentor } from "../../api/recommendation_related_service";
import Cookies from 'js-cookie';

function HoriMentorSlide() {
  const [mentor, setMentor] = useState([]);
  const [mentorUseruuid, setMentorUseruuid] = useState("");
  const user_token = Cookies.get("connectedMe")
  const [open, setOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchRecommendedMentorData();
  }, []);

  const fetchRecommendedMentorData = async () => {
    try {
      const response = await fetchRecommendedMentors({
        token: user_token,
      });
      setMentor(response || []);
      console.log(response);
    } catch (error) {
      console.error("Error fetching recommended mentors:", error);
    }
  };

  const handleMentorClick = async (mentorData) => {
    try {
      await post_notification({
        token: user_token,
        mentor_user_uuid: mentorData.mentor_user_uuid,
        read_status: false,
      });
      // Navigate to mentor profile
      navigate(`/d/dashboard/mentorProfile`, { state: { mentorData } });
    } catch (error) {
      console.error("Error posting notification:", error);
    }
  };
  const handleSessionSelect = (session) => {
    if (selectedSession?.session_start_date === session.session_start_date) {
      setSelectedSession(null);
    } else {
      setSelectedSession(session);
    }
  };
  const convertToLocalDate = (utrDateString) => {
    const utcDate = new Date(utrDateString);
    const localDate = utcDate.toLocaleDateString();
    const localTime = utcDate.toLocaleTimeString();
    return `${localDate} ${localTime}`;
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleCloseConfirm = () => {
    const payload = {
      mentor_uuid: mentorUseruuid,
      start_date: selectedSession.session_start_date,
      end_date: selectedSession.session_end_date,
    };
    sendMentorBookingMail({ ...payload, user_token: user_token });
    Book_mentor({ mentor_uuid: mentorUseruuid, token: user_token });
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleBook = (data) => {
    // console.log("mentor Booked");
    setMentorUseruuid(data);
    setOpen(true);
  };

  const PreviousBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowBackIosNewIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
      </div>
    );
  };
  const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowForwardIosIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
      </div>
    );
  };

  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const slideshow = isMobileScreen ? 1 : isSmallScreen ? 3 : 5;

  const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(1),
    border: "1px solid #2f3348",
    borderRadius: theme.spacing(1),
    boxShadow: "0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)",
    width: isMobileScreen ? "280px" : "400px",
    margin: isMobileScreen ? "0 10px" : "0 20px",
  }));
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideshow,
    slidesToScroll: 1,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    centerMode: isMobileScreen,
    centerPadding: "40px",
  };
  return (
    <Box
      sx={{
        width: "85vw",
        marginLeft: isMobileScreen ? "0px" : "60px",
      }}
    >
      <Slider {...settings}>
        {mentor.length === 0 ? (
          <StyledCard
            sx={{
              width: "400px",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                position: "relative",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <PeopleIcon
                sx={{ fontSize: 64, color: "green", marginBottom: "20px" }}
              />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#333", marginBottom: "10px" }}
              >
                No Mentor Available
              </Typography>
            </Box>
          </StyledCard>
        ) : (
          mentor.map((mentorData, index) => (
            <StyledCard
              key={index} // Ensure each card has a unique key
              sx={{
                width: "400px",
                height: "350px",
                display: "flex",
                border: "1px solid black",
              }}
            >
              <Stack
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between", // This will push the child elements to the top and bottom
                }}
              >
                <Stack
                  sx={{
                    height: "100%",
                    overflowY: "auto",
                    display: "flex",
                    justifyContent: "center",
                    padding: "auto",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      height: "75%",
                    }}
                  >
                    {mentorData.skills.map((skill, skillIndex) => (
                      <Badge
                        key={skillIndex}
                        badgeContent={skill.skill_level}
                        color="error"
                        sx={{
                          "& .MuiBadge-badge": {
                            right: 7, // Set to 0 to touch the right edge of the chip
                            top: 7, // Set to 0 to touch the top edge of the chip
                            border: `2px solid white`,
                            padding: "0 2px",
                          },
                        }}
                      >
                        <Chip
                          label={skill.skill_name}
                          sx={{
                            margin: 1,
                            fontSize: "0.75rem", // Reduce the font size of the chip
                            height: "24px", // Reduce the height of the chip
                            "& .MuiChip-label": {
                              padding: "0 8px", // Adjust padding inside the chip
                            },
                          }}
                        />
                      </Badge>
                    ))}
                  </Stack>
                </Stack>

                <Stack>
                  <Stack sx={{ margin: "10px" }} gap={1}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleBook(mentorData.mentor_user_uuid);
                      }}
                      disabled={mentorData.sessions.length === 0}
                    >
                      BOOK
                    </Button>
                  </Stack>
                  <Box>
                    <Modal open={open} onClose={handleClose}>
                      <Box sx={style}>
                        <Typography variant="h6">
                          Confirm Your Booking
                        </Typography>
                        <Typography sx={{ mt: 2, mb: 2 }}>
                          Select the meeting date
                        </Typography>
                        <Stack
                          spacing={1}
                          direction="row"
                          sx={{ flexWrap: "wrap", overflowX: "auto" }} // Added wrap and scroll behavior
                        >
                          {mentorData.sessions.map((session, index) => (
                            <Chip
                              key={index}
                              label={convertToLocalDate(
                                session.session_start_date
                              )}
                              clickable
                              onClick={() => handleSessionSelect(session)}
                              sx={{
                                backgroundColor:
                                  selectedSession?.session_start_date ===
                                  session.session_start_date
                                    ? "#2f3348"
                                    : "default",
                                color:
                                  selectedSession?.session_start_date ===
                                  session.session_start_date
                                    ? "#ffffff"
                                    : "default",
                              }}
                            />
                          ))}
                        </Stack>

                        <Stack
                          sx={{ marginTop: "20px" }}
                          direction="row"
                          spacing={2}
                        >
                          <Button
                            variant="contained"
                            onClick={handleCloseConfirm}
                            disabled={!selectedSession}
                          >
                            Confirm
                          </Button>
                          <Button variant="outlined" onClick={handleClose}>
                            Cancel
                          </Button>
                        </Stack>
                      </Box>
                    </Modal>
                  </Box>
                  <Stack
                    direction={"row"}
                    gap={1}
                    sx={{
                      width: "100%",
                      background: "#1d1933",
                    }}
                    onClick={() => handleMentorClick(mentorData)}
                  >
                    <Avatar sx={{ margin: "5px" }} src={mentorData.image} />
                    <Stack>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#ffffff",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="body2">
                          {mentorData.first_name} {mentorData.last_name}
                        </Typography>
                        <Typography variant="body4">
                          {mentorData.professional_info}
                        </Typography>
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </StyledCard>
          ))
        )}
      </Slider>
    </Box>
  );
}

export default HoriMentorSlide;
