import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, Grid, MenuItem, Typography } from '@mui/material';
import usePlanpath from '../../store/PlanPath_data';
import {addExistingPlan} from '../../api/planPath_related_services'
import userSess from '../../store/user_session_control'
import Cookies from 'js-cookie';

function UpdateExisting({ open, onClose, onAddcourse }) {
  const [details, setDetails] = useState('');
  const [course, setCourse] = useState('');
  const [date, setDate] = useState('');
  const dataItems = usePlanpath((state) => state.path_data);
  const update_row = usePlanpath((state) => state.update_row);
  const user_token = Cookies.get("connectedMe")

  const handleSubmit = async() => {
    if (details && course && date) {
      update_row(details, course, date);
      onClose();
      setDetails('');
      setCourse('');
      setDate('');
      try {
        await addExistingPlan({ details, course, date, 'token': user_token })
        console.log('PlanPath data saved successfully');
      } catch (error) {
        console.error('Failed to save plan data:', error);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const handleCancel = () => {
    setDetails('');
    setCourse('');
    setDate('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Update Existing</DialogTitle>
      <DialogContent sx={{ width: '800px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ marginBottom: 2 }}>
            <TextField
              select
              label="Details"
              placeholder="Add Existing"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              sx={{ marginTop: "10px", width: '100%' }}
            >
              {dataItems.map((detail) => (
                <MenuItem key={detail.Details} value={detail.Details}>
                  {detail.Details}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={8} sx={{ marginBottom: 2 }}>
            <TextField
              label="Course"
              value={course}
              onChange={(e) => setCourse(e.target.value)}
              sx={{ width: '100%' }}
              required
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={4} sx={{ marginBottom: 2 }}>
            <TextField
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              sx={{ width: '100%' }}
              required
            />
          </Grid>

          <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'end', marginBottom: 2 }}>
            <Grid item xs={3}>
              <Button variant="outlined" onClick={handleCancel} sx={{ width: '100%', mt: 2 }}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" onClick={handleSubmit} sx={{ width: '100%', mt: 2 }}>
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>

  );
}

export default UpdateExisting;
