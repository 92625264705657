import React, { useRef, useState } from "react";
import Jobform from "./Jobform";
import Optionbar from "../Optionbar/Optionbar";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  NativeSelect,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  addJob,
  getJob,
  update_status,
} from "../../api/job_advertisement_related_service";
import userSess from "../../store/user_session_control";
import ArchiveJobs from "./ArchiveJobs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ActiveJobs from "./ActiveJobs";
import CloseJobs from "./CloseJobs";
import AppliedJobs from "./AppliedJobs";
import BreadCrumbs from "./BreadCrumbs";
import Cookies from 'js-cookie';

const Job = () => {
  const [isPopup2Open, setIsPopup2Open] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [Jobevents, SetJobevents] = useState();
  // const { user_token, updateSess } = userSess()
  const user_token = Cookies.get("connectedMe")
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState("Open");
  const [value, setValue] = React.useState("Active Jobs");

  const handleClick = (event, id, job_status) => {
    // setArchived({ advertisement_id: id, status: job_status })
    setAnchorEl(event.currentTarget);
    // console.log(Archived);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (event, id) => {
    let value = event.target.value;
    console.log(value, id);
    const body = {
      advertisement_id: id,
      status: value,
    };
    const response = await update_status({ ...body, token: user_token });
    console.log(response);
    if (response.status === 200) {
      const app_events = await getJob(user_token);
      SetJobevents(app_events);
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#2f3348",
      },
    },
  });

  const handlePopup2Open = () => {
    setIsPopup2Open(true);
  };

  const handlePopup2Close = () => {
    setIsPopup2Open(false);
  };

  const [count, SetCount] = useState(0);
  const handleSubmit = () => {
    SetCount(count + 1);
  };

  React.useEffect(() => {
    const handleClick_cal = async () => {
      const app_events = await getJob(user_token);
      SetJobevents(app_events);
    };
    handleClick_cal();
  }, [count, value]);
  // console.log(role);

  const handleTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid2 component={Paper} direction="column" sx={{ height: "100%" }}>
          <Box>
            <Stack sx={{ margin: "10px" }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "medium",
                  paddingLeft: "15px",
                  color: "#2f3348",
                }}
              >
                Details
              </Typography>

              <Divider />
              {/* <BreadCrumbs/> */}
            </Stack>

            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleTab}
                    aria-label="lab API tabs example"
                    defaultValue="Active Jobs"
                  >
                    <Tab label="Active Jobs" value="Active Jobs" />
                    <Tab label="Archive Jobs" value="Archive Jobs" />
                    <Tab label="Applied Jobs" value="Applied Jobs" />
                    <Tab label="Closed Jobs" value="Closed Jobs" />
                  </TabList>
                </Box>
                <TabPanel value="Active Jobs">
                  <ActiveJobs
                    Jobevents={Jobevents}
                    handleChange={handleChange}
                    handleClick={handleClick}
                  />
                </TabPanel>

                <TabPanel value="Archive Jobs">
                  <ArchiveJobs
                    Jobevents={Jobevents}
                    handleChange={handleChange}
                    handleClick={handleClick}
                  />
                </TabPanel>

                <TabPanel value="Applied Jobs">
                  <AppliedJobs
                    Jobevents={Jobevents}
                    handleChange={handleChange}
                    handleClick={handleClick}
                  />
                </TabPanel>
                <TabPanel value="Closed Jobs">
                  <CloseJobs
                    Jobevents={Jobevents}
                    handleChange={handleChange}
                    handleClick={handleClick}
                  />
                </TabPanel>
                <Optionbar handlePopup2Open={handlePopup2Open} />
                <Jobform
                  open={isPopup2Open}
                  onClose={handlePopup2Close}
                  submit={handleSubmit}
                />
              </TabContext>
            </Box>
          </Box>

          {/* <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            // {/* <MenuItem onClick={handleClose}>Edit</MenuItem>
            // <MenuItem onClick={handleClose}>Delete</MenuItem> */}
          {/* </Menu> */} 

        </Grid2>
      </ThemeProvider>
    </>
  );
};
export default Job;
