import { ButtonGroup, IconButton, Stack, Typography } from "@mui/material";
import GridOnIcon from "@mui/icons-material/GridOn";
import ViewListIcon from "@mui/icons-material/ViewList";
import CardGridView from "../components/Menu/card_grid_view";
import CardListView from "../components/Menu/card_list_view";
import * as React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchEle from "../components/Menu/searchComp";
import { fetchAppointments } from "../api/scheduler_related_services";
import useEventDataStore from "../store/SchedulerData";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import UserSessionMenu from "../components/appointments/user_hist_app";
import BookedMentors from "../components/Menu/Booked_mentors";
import { fetchUserMentSession } from "../api/session_service";
import useUserMentorSess from "../store/user_mentor_session";
import HoriCourseSlide from "../components/Menu/horizontal_course_slider";
import BookedCourses from "../components/Menu/Booked_courses";
import Cookies from 'js-cookie';

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

function UserSessionPlt() {
  const [openL, setOpenL] = React.useState(true);
  const handlemenuList = (toggle) => () => {
    setOpenL(toggle);
  };

  const [value, setValue] = React.useState(0);
  const [sessions, setSessions] = useState(true);
  const [showBookedMentors, setShowBookedMentors] = useState(false);
  const [showBookedCourses, setShowBookedCourses] = useState(false);
  const { setAppointments } = useEventDataStore();
  const user_token = Cookies.get("connectedMe")
  const { setuseUserMentorSess } = useUserMentorSess();
  useEffect(() => {
    const fetchSessions = async () => {
      const updatedSessions = await fetchUserMentSession({ token: user_token });
      setuseUserMentorSess(updatedSessions);
    };

    fetchSessions();
  }, [value, setuseUserMentorSess, user_token]);

  useEffect(() => {
    const fetchUserSessions = async () => {
      const app_events = await fetchAppointments({ token: user_token });
      setAppointments(app_events);
    };

    fetchUserSessions();
  }, [value, setAppointments, user_token]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setSessions(true);
      setShowBookedMentors(false);
    } else if (newValue === 1) {
      setSessions(false);
      setShowBookedMentors(true);
    } else if (newValue === 2) {
      setSessions(false);
      setShowBookedMentors(false);

      setShowBookedCourses(true);
    } else if (newValue === 3) {
      setSessions(false);
      setShowBookedMentors(false);
      setShowBookedCourses(false);
    }
  };

  return (
    <>
      <Stack direction={"column"}>
        <Typography variant="h4" sx={{ margin: "20px 0 0 20px" }}>
          My Bookings
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ margin: "0 0 20px 20px", color: "grey" }}
        >
          Join Us for Exciting Booked Sessions: Explore, Learn, and Connect
          with Industry Leaders!
        </Typography>
      </Stack>

      <Stack sx={{ margin: "0 20px" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ bgcolor: "#fff" }}>
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label="ant example"
              key={value}
            >
              <AntTab label="Your Booked Sessions" />
              <AntTab label="Your Booked Mentors" />
              <AntTab label="Your Booked Courses" />
              <AntTab label="Your Created Sessions" />
            </AntTabs>
            <Box />
          </Box>
        </Box>
      </Stack>

      {sessions ? (
        <Stack>
          <Stack sx={{ margin: "5px 0 " }}>
            <Stack direction={"row"}>
              <SearchEle />
              <ButtonGroup
                sx={{
                  "margin-left": "auto",
                }}
              >
                <IconButton
                  onClick={handlemenuList(true)}
                  sx={{
                    backgroundColor: openL ? "#2f3348" : "#ffffff",
                  }}
                >
                  <GridOnIcon
                    sx={{
                      color: openL ? "#ffffff" : "#757575",
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={handlemenuList(false)}
                  sx={{
                    backgroundColor: openL ? "#ffffff" : "#2f3348",
                  }}
                >
                  <ViewListIcon
                    sx={{
                      color: openL ? "#757575" : "#ffffff",
                    }}
                  />
                </IconButton>
              </ButtonGroup>
              <IconButton sx={{ color: "#f65a4c" }}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Stack>
            <Stack sx={{ margin: "5px 0" }}>
              {openL ? <CardGridView /> : <CardListView />}
            </Stack>
          </Stack>
        </Stack>
      ) : showBookedMentors ? (
        <Stack sx={{ margin: "20px 0", display: "flex", alignItems: "center" }}>
          {/* Display Booked Mentors */}
          <BookedMentors />
        </Stack>
      ) : showBookedCourses ? (
        <Stack sx={{ margin: "20px 0", display: "flex", alignItems: "center" }}>
          {/* Display Booked Mentors */}
          {/* <BookedMentors />  */}
          <BookedCourses />
        </Stack>
      ) : (
        <Stack sx={{ margin: "20px 0", display: "flex", alignItems: "center" }}>
          <UserSessionMenu />
        </Stack>
      )}
    </>
  );
}

export default UserSessionPlt;
