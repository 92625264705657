
import React, { useRef, useState } from 'react'
import Jobform from './Jobform';
import Optionbar from '../Optionbar/Optionbar';
import { Alert, Box, Breadcrumbs, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, IconButton, InputLabel, Menu, MenuItem, NativeSelect, Paper, Radio, RadioGroup, Select, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import useStore from '../../store/sponsor_data';
import { Delete, ModeEdit, MoreVert } from '@mui/icons-material';
import { addJob, getJob, updateJob, update_status } from '../../api/job_advertisement_related_service';
import userSess from '../../store/user_session_control';
import ArchiveJobs from './ArchiveJobs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Jobinfo from './Jobinfo';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const ActiveJobs = (props) => {
    const Jobevents = props.Jobevents;
    console.log(Jobevents);
    const user_token = Cookies.get("connectedMe")
    const [isPopup2Open, setIsPopup2Open] = useState(false);
    const [date, setDate] = useState();
    const [id, setId] = useState();
    const [role, setRole] = useState();
    const [mode, setMode] = useState();
    const [dis, setDis] = useState();
    const [skill, setSkill] = useState();

    const col = ['Starting Date', 'Job Id', 'Role', 'Mode', 'Description', 'Skill Match', 'Status'];
    return (
        <>
           
            {/* {isPopup2Open ? (<Jobinfo handleChange={handleChange} onclose={handlePopup2Close} submit={handleSubmit} date={date} role={role} id={id} dis={dis} skill={skill} mode={mode} />) : ( */}

                <Stack>

                    <Table className="fullWidthTable" sx={{ minWidth: 350 }} style={{ fontSize: '3rem' }}>

                        <TableHead sx={{ backgroundColor: '#2f3348' }}>
                            <TableRow>

                                {[...Array(7)].map((_, index) => (
                                    <TableCell key={index} style={{ fontWeight: 'bold', width: '10px', height: '50px', borderRight: '1px solid #e0e0e0', color: 'white' }}>{col[index]}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {
                                Jobevents && Jobevents.map(index => {
                                    if (index.status === 'Open') {
                                        return (
                                            <TableRow >

                                                <TableCell style={{ verticalAlign: "top" }} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_date} </TableCell>

                                                <TableCell style={{ verticalAlign: "top" }} sx={{ borderRight: '1px solid #e0e0e0' }}>
                                                    <Link to={`/d/Job/${index.advertisement_id}`} underline="hover" color="inherit"  >
                                                        <Typography> {index.advertisement_id}</Typography>
                                                    </Link>
                                                </TableCell>

                                                <TableCell style={{ verticalAlign: "top" }} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_role}</TableCell>
                                                <TableCell style={{ verticalAlign: "top" }} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_mode}</TableCell>
                                                <TableCell style={{ verticalAlign: "top", maxWidth: '80px', maxHeight: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} sx={{ borderRight: '1px solid #e0e0e0' }}>{index.advertisement_description}</TableCell>
                                                <TableCell style={{ verticalAlign: "top", maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} sx={{ borderRight: '1px solid #e0e0e0' }}>


                                                    {index.advertisement_skill_match && index.advertisement_skill_match.split(',').map((skill, index) => (
                                                        <Chip key={index} label={skill} style={{ marginRight: 8 }} />
                                                    ))}

                                                </TableCell>

                                                <TableCell style={{ borderRight: '1px solid #e0e0e0', verticalAlign: "top" }}>
                                                    <Stack display={'fex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                        <Stack>
                                                            <FormControl fullWidth>
                                                                {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                                    Status
                                                                </InputLabel> */}
                                                                <NativeSelect
                                                                    defaultValue={index.status}

                                                                    inputProps={{
                                                                        name: 'Status',
                                                                        id: 'status',

                                                                    }}
                                                                    onChange={(event) => {
                                                                        let id = index.advertisement_id
                                                                        props.handleChange(event, id)
                                                                    }}
                                                                >
                                                                    <option value="Open" >Open</option>
                                                                    <option value="Close" >Close</option>
                                                                    <option value="Archive" >Archive</option>


                                                                </NativeSelect>
                                                            </FormControl>
                                                        </Stack>
                                                        <Stack display={'fex'} flexDirection={'row'}>

                                                            <IconButton
                                                                size="large"
                                                                aria-label="display more actions"
                                                                edge="end"
                                                                color="inherit"
                                                            >
                                                                <MoreVert onClick={(event) => {

                                                                    props.handleClick(event);
                                                                }} />
                                                            </IconButton>
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>

                                            </TableRow >
                                        )
                                    }

                                }

                                )
                            }


                        </TableBody>
                    </Table>
                </Stack>
            {/* )} */}
        </>
    )
}

export default ActiveJobs
