import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, Chip, TextField, Alert, InputAdornment, IconButton, Badge, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { fetchRoles, fetchCurrentSkills, fetchDesirableSkills } from "../../api/skill_catalog";
import { addSkill } from "../../api/profile_related_service";
import Cookies from 'js-cookie';

const SkillSelectionPopup = ({ open, handleClose }) => {
  const [newSkill, setNewSkill] = useState('');
  const [newSkillLevel, setNewSkillLevel] = useState(1);
  const [levelDescription, setLevelDescription] = useState('');
  const [desirableSkillLevels, setDesirableSkillLevels] = useState({});
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedCurrentRole, setSelectedCurrentRole] = useState(null);
  const [selectedDesirableRole, setSelectedDesirableRole] = useState(null);
  const [selectedDesirableRoleId, setSelectedDesirableRoleId] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedDesirableSkills, setSelecteddesirableSkills] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [desiredSkills, setDesiredSkills] = useState([]);
  const [filteredDesiredSkills, setFilteredDesiredSkills] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [step, setStep] = useState(1);
  const [alertMessage, setAlertMessage] = useState('');
  const [showNewSkillFields, setShowNewSkillFields] = useState(false);
  const user_token = Cookies.get("connectedMe")


  const getLevelDescriptions = (skill) => skill ? {
    1: skill.all_level_descriptions.level_1,
    2: skill.all_level_descriptions.level_2,
    3: skill.all_level_descriptions.level_3,
    4: skill.all_level_descriptions.level_4,
    5: skill.all_level_descriptions.level_5,
    6: skill.all_level_descriptions.level_6,
    7: skill.all_level_descriptions.level_7
  } : {};

  useEffect(() => {
    if (selectedSkill) {
      const descriptions = getLevelDescriptions(selectedSkill);
      setLevelDescription(descriptions[newSkillLevel]);
    }
  }, [newSkillLevel, selectedSkill]);

  useEffect(() => {
    const fetchRoleList = async () => {
      try {
        const roleList = await fetchRoles();
        setRolesList(roleList);
        setFilteredRoles(roleList);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoleList();
  }, []);

  useEffect(() => {
    const filtered = rolesList.filter(role => role.name.toLowerCase().includes(searchQuery.toLowerCase()));
    const sortedFiltered = filtered.sort((a, b) => {
      if (step === 1) {
        if (selectedRoleId === a.role_id && selectedRoleId !== b.role_id) {
          return -1;
        }
        if (selectedRoleId !== a.role_id && selectedRoleId === b.role_id) {
          return 1;
        }
      } else {
        if (selectedDesirableRoleId === a.role_id && selectedDesirableRoleId !== b.role_id) {
          return -1;
        }
        if (selectedDesirableRoleId !== a.role_id && selectedDesirableRoleId === b.role_id) {
          return 1;
        }
      }
      return 0;
    });
    setFilteredRoles(sortedFiltered);
  }, [searchQuery, rolesList, selectedRoleId, selectedDesirableRoleId, step]);



  useEffect(() => {
    if (step === 1) {
      const filteredSkills = suggestedSkills.filter(skill =>
        skill.skill_name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      const sortedFilteredSkills = filteredSkills.sort((a, b) => {
        if (selectedSkills.includes(a.skill_name) && !selectedSkills.includes(b.skill_name)) {
          return -1;
        }
        if (!selectedSkills.includes(a.skill_name) && selectedSkills.includes(b.skill_name)) {
          return 1;
        }
        return 0;
      });

      setFilteredSkills(sortedFilteredSkills);
    }
  }, [searchQuery, suggestedSkills, selectedSkills, step]);


  useEffect(() => {
    if (step === 2) {
      const filteredDesiredSkills = desiredSkills.filter(skill =>
        skill.skill_name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      const sortedFilteredDesiredSkills = filteredDesiredSkills.sort((a, b) => {
        if (selectedDesirableSkills.includes(a.skill_name) && !selectedDesirableSkills.includes(b.skill_name)) {
          return -1;
        }
        if (!selectedDesirableSkills.includes(a.skill_name) && selectedDesirableSkills.includes(b.skill_name)) {
          return 1;
        }
        return 0;
      });

      setFilteredDesiredSkills(sortedFilteredDesiredSkills);
    }
  }, [searchQuery, desiredSkills, selectedDesirableSkills, step]);

  const handleRoleToggle = async (roleId) => {
    setAlertMessage('');
    if (selectedRoleId === roleId) {
      setSelectedRoleId(null);
      setSuggestedSkills([]);
    } else {
      setSelectedRoleId(roleId);
      try {
        const skillsResponse = await fetchCurrentSkills(roleId);
        setSuggestedSkills(skillsResponse);
        const selectedRole = rolesList.find(role => role.role_id === roleId);
        setSelectedCurrentRole(selectedRole.name);
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    }
  };

  const handleSkillToggle = (skillName, skillLevel) => {
    if (selectedDesirableSkills.includes(skillName)) {
      setSelecteddesirableSkills(selectedDesirableSkills.filter((s) => s !== skillName));
      setDesirableSkillLevels(prevLevels => {
        const newLevels = { ...prevLevels };
        delete newLevels[skillName];
        return newLevels;
      });
    } else {
      setSelecteddesirableSkills([...selectedDesirableSkills, skillName]);
      setDesirableSkillLevels(prevLevels => ({
        ...prevLevels,
        [skillName]: skillLevel || 1
      }));
    }
  };


  const handleDesirableRoleToggle = async (roleId) => {
    setAlertMessage('');
    if (selectedDesirableRoleId === roleId) {
      setSelectedDesirableRoleId(null);
      setDesiredSkills([]);
    } else {
      setSelectedDesirableRoleId(roleId);
      try {
        const skillsResponse = await fetchDesirableSkills(roleId);
        setDesiredSkills(skillsResponse);
        const selectedRole = rolesList.find(role => role.role_id === roleId);
        setSelectedDesirableRole(selectedRole.name);
      } catch (error) {
        console.error("Error fetching desired skills:", error);
      }
    }
  }; const handleNext = () => {
    if (step === 1) {
      if (selectedRoleId === null || selectedRoleId === undefined) {
        setAlertMessage('Please select your current role.');
      } else {
        setStep(2);
      }
    }
  };

  const handlePrevious = () => {
    if (step === 2) {
      setStep(1);
    }
  };


  const handleSave = async () => {
    try {
      await addSkill({
        token: user_token,
        skills: selectedSkills.map(skill => ({ skill_name: skill, skill_level: newSkillLevel })),
        professional_ambitions: selectedDesirableSkills.map(skill => ({ skill_name: skill, skill_level: desirableSkillLevels[skill] || 1 })),
        currentRole: selectedCurrentRole,
        desirableRole: selectedDesirableRole,
      });
    } catch (error) {
      console.error("Error saving roles and ambitions:", error);
    }
    handleClose();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#2f3348',
      },
    },
  });
  const handleAddSkillClick = () => {
    setShowNewSkillFields(true);
  };
  const handleAddSkill = () => {
    if (newSkill && newSkillLevel > 0) {
      setFilteredSkills([...filteredSkills, { skill_name: newSkill, skill_level: newSkillLevel }]);
      setSuggestedSkills([...filteredSkills, { skill_name: newSkill, skill_level: newSkillLevel }]);
      setSelectedSkills(prevSelectedSkills => [...prevSelectedSkills, newSkill]);
      setNewSkill('');
      setNewSkillLevel(1);
      setShowNewSkillFields(false);
      console.log('New skill added:', newSkill, newSkillLevel);
    } else {
      console.log('Please enter a valid skill and level');
    }
  };

  const handleSkillLevelChange = (skillName) => {
    const skill = filteredSkills.find(s => s.skill_name === skillName);
    setSelectedSkill(skill);
    setNewSkillLevel(skill.skill_level);
  };

  const handleLevelUpdate = () => {
    if (selectedSkill && newSkillLevel > 0) {
      const updatedSkills = filteredSkills.map(skill =>
        skill.skill_name === selectedSkill.skill_name
          ? { ...skill, skill_level: newSkillLevel, level_description: getLevelDescriptions(skill)[newSkillLevel] }
          : skill
      );
      setFilteredSkills(updatedSkills);
      setSelectedSkills(prevSelectedSkills =>
        prevSelectedSkills.includes(selectedSkill.skill_name)
          ? prevSelectedSkills
          : [...prevSelectedSkills, selectedSkill.skill_name]
      );
      setSelectedSkill(null);
    } else {
      setAlertMessage('Please enter a valid level');
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={() => { }}
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
      >
        {step === 1 && (
          <>
            <DialogTitle>Select Your Current Role</DialogTitle>
            <DialogContent sx={{ overflowX: 'hidden', padding: 2 }}>
              {alertMessage && <Alert severity="error" sx={{ m: 2 }}>{alertMessage}</Alert>}
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <TextField
                  label="Search your current role"
                  variant="outlined"
                  fullWidth
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{ m: 2, width: '100%', maxWidth: '600px' }}
                  InputProps={{
                    style: {
                      borderColor: '#2f3348',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, p: 2, overflowY: 'auto', height: '100px', width: '100%' }}>
                {selectedRoleId == null ? (
                  filteredRoles.map((role) => (
                    <Chip
                      key={role.role_id}
                      label={role.name}
                      clickable
                      sx={{
                        backgroundColor: selectedRoleId === role.role_id ? '#2f3348' : 'default',
                        color: selectedRoleId === role.role_id ? '#ffffff' : 'default'
                      }}
                      onClick={() => handleRoleToggle(role.role_id)}
                    />
                  ))
                ) : (
                  filteredRoles
                    .filter((role) => role.role_id === selectedRoleId)
                    .map((role) => (
                      <Chip
                        key={role.role_id}
                        label={role.name}
                        clickable
                        sx={{
                          backgroundColor: '#2f3348',
                          color: '#ffffff'
                        }}
                        onClick={() => handleRoleToggle(null)}
                      />
                    ))
                )}
              </Box>

            </DialogContent>
            {selectedRoleId !== null && (
              <>
                <DialogTitle>Select Your Skills
                  {!showNewSkillFields && (
                    <IconButton
                      color="primary"
                      onClick={handleAddSkillClick}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </DialogTitle>
                <DialogContent sx={{ overflowX: 'hidden', padding: 2 }}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, p: 2, overflowX: 'hidden', width: '100%' }}>
                    {filteredSkills.map((skill) => (
                      <Tooltip
                        key={skill.skill_name}
                        title={
                          <Typography variant="body2">
                            <strong>Skill level {skill.skill_level} description:</strong>
                            <br />
                            {skill.level_description || 'No description available'}
                          </Typography>
                        }
                      >
                        <Badge color="error" badgeContent={skill.skill_level} onClick={() => handleSkillLevelChange(skill.skill_name)}>
                          <Chip
                            label={skill.skill_name}
                            clickable
                            sx={{
                              backgroundColor: selectedSkills.includes(skill.skill_name) ? '#2f3348' : 'default',
                              color: selectedSkills.includes(skill.skill_name) ? '#ffffff' : 'default'
                            }}
                          />
                        </Badge>
                      </Tooltip>
                    ))}
                  </Box>
                  {/* Dialog for updating skill level */}
                  {selectedSkill && (
                    <Dialog open={Boolean(selectedSkill)} onClose={() => setSelectedSkill(null)}>
                      <DialogTitle>Update Skill Level</DialogTitle>
                      <DialogContent>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          <strong>Current Skill Level Description:</strong>
                          <br />
                          {levelDescription || 'No description available'}
                        </Typography>
                        <TextField
                          label="New Skill Level"
                          type="number"
                          variant="standard"
                          value={newSkillLevel}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10);
                            if (value >= 1 && value <= 7) {
                              setNewSkillLevel(value);
                            }
                          }}
                          inputProps={{ min: 1, max: 7 }}
                          sx={{ m: 2, width: '100px' }}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setSelectedSkill(null)} color="primary">Cancel</Button>
                        <Button onClick={handleLevelUpdate} color="primary">Update</Button>
                      </DialogActions>
                    </Dialog>
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
                    {showNewSkillFields && (
                      <>
                        <TextField
                          label="New Skill"
                          variant="outlined"
                          value={newSkill}
                          onChange={(e) => setNewSkill(e.target.value)}
                          sx={{ m: 2 }}
                        />
                        <TextField
                          label="Level"
                          type="number"
                          variant="outlined"
                          value={newSkillLevel}
                          onChange={(e) => setNewSkillLevel(e.target.value)}
                          sx={{ m: 2, width: '100px' }}
                        />
                        <IconButton
                          onClick={handleAddSkill}
                          color="primary"
                          sx={{ m: 2 }}
                        >
                          <AddIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </DialogContent>
              </>
            )}

            <DialogActions>
              <Button onClick={handleNext}
                variant="contained"
                sx={{ backgroundColor: '#2f3348', '&:hover': { backgroundColor: '#1c1f29' } }}
              >Next</Button>
            </DialogActions>
          </>
        )}


        {step === 2 && (
          <>
            <DialogTitle>Select Your Desirable Role</DialogTitle>
            <DialogContent sx={{ overflowX: 'hidden', padding: 2 }}>
              {alertMessage && <Alert severity="error" sx={{ m: 2 }}>{alertMessage}</Alert>}
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <TextField
                  label="Search your desirable role"
                  variant="outlined"
                  fullWidth
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{ m: 2, width: '100%', maxWidth: '600px' }}
                  InputProps={{
                    style: {
                      borderColor: '#2f3348',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, p: 2, overflowY: 'auto', height: '100px', width: '100%' }}>
                {selectedDesirableRoleId == null ? (
                  filteredRoles.map((role) => (
                    <Chip
                      key={role.role_id}
                      label={role.name}
                      clickable
                      sx={{
                        backgroundColor: selectedDesirableRoleId === role.role_id ? '#2f3348' : 'default',
                        color: selectedDesirableRoleId === role.role_id ? '#ffffff' : 'default'
                      }}
                      onClick={() => handleDesirableRoleToggle(role.role_id)}
                    />
                  ))
                ) : (
                  filteredRoles
                    .filter((role) => role.role_id === selectedDesirableRoleId)
                    .map((role) => (
                      <Chip
                        key={role.role_id}
                        label={role.name}
                        clickable
                        sx={{
                          backgroundColor: '#2f3348',
                          color: '#ffffff'
                        }}
                        onClick={() => handleDesirableRoleToggle(null)}
                      />
                    ))
                )}
              </Box>

            </DialogContent>
            {selectedDesirableRoleId !== null && (
              <>
                <DialogTitle>Select your desired skills</DialogTitle>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, p: 2, overflowX: 'hidden', width: '100%' }}>
                  {filteredDesiredSkills.map((skill) => (
                    <Tooltip
                      key={skill.skill_name}
                      title={
                        <Typography variant="body2">
                          <strong>Skill level {skill.skill_level}  description:</strong>
                          <br />
                          {skill.level_description || 'No description available'}
                        </Typography>
                      }>
                      <Badge key={skill.skill_name} color="error" badgeContent={skill.skill_level}>
                        <Chip
                          label={skill.skill_name}
                          clickable
                          sx={{
                            backgroundColor: selectedDesirableSkills.includes(skill.skill_name) ? '#2f3348' : 'default',
                            color: selectedDesirableSkills.includes(skill.skill_name) ? '#ffffff' : 'default'
                          }}
                          onClick={() => handleSkillToggle(skill.skill_name, skill.skill_level)}
                        />
                      </Badge>
                    </Tooltip>
                  ))}
                </Box>
              </>
            )}

            <DialogActions>
              <Button onClick={handlePrevious}
                variant="contained"
                sx={{ marginRight: 'auto', backgroundColor: '#2f3348', '&:hover': { backgroundColor: '#1c1f29' } }}
              >Previous</Button>
              <Button onClick={handleSave}
                variant="contained"
                sx={{ backgroundColor: '#2f3348', '&:hover': { backgroundColor: '#1c1f29' } }}
              >Save</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default SkillSelectionPopup;