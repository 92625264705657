import * as React from "react";
import useEventDataStore from "../../store/SchedulerData";
import { Card, Grid, Stack, Typography, Button } from "@mui/material";
import { styled } from '@mui/system';
import { Margin } from "@mui/icons-material";

const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(1),
    border: '1px solid #2f3348',
    borderRadius: theme.spacing(1),
    boxShadow: '0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)',
    width: '250px',
    height: '200px',
}));

function UserSessionMenu() {
    const { appointments } = useEventDataStore();
    console.log("Fetching appointment");
    const convertToLocalDate = (utrDateString) => {
        const utcDate = new Date(utrDateString);
        const localDate = utcDate.toLocaleDateString();
        const localTime = utcDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return `${localDate} ${localTime}`;
    };

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: "50vh" }}>
            {appointments && appointments.length > 0 ? (
                appointments.map(function (appointment) {
                    return (
                        <Grid item key={appointment.id}>
                            <StyledCard>
                                <Stack sx={{ margin: '10px' }}>
                                    <Typography variant="h6" sx={{ marginBottom: "10px" }}>{appointment.title.length > 20 ? `${appointment.title.substring(0, 20)}...` : appointment.title}</Typography>
                                    <Typography sx={{ marginBottom: "5px" }}>Start at: {convertToLocalDate(appointment.startDate)}</Typography>
                                    <Typography sx={{ marginBottom: "5px" }}>End at: {convertToLocalDate(appointment.endDate)}</Typography>
                                    <Typography variant="caption" sx={{ marginBottom: "5px" }}>Description: {appointment.notes.length > 20 ? `${appointment.notes.substring(0, 20)}...` : appointment.notes}</Typography>
                                    <Stack direction="row" alignItems="center" sx={{ backgroundColor: "#2f3348" }}>
                                        <Typography sx={{ color: "white", marginLeft: "10px", alignSelf: "center" }}>Location: {appointment.customField}</Typography>
                                        <Button variant="contained" sx={{ marginLeft: "auto", backgroundColor: "#2f3348" }}>START</Button>
                                    </Stack>
                                </Stack>
                            </StyledCard>
                        </Grid>
                    );
                })
            ) : (
                <Grid item xs={12} style={{ marginBottom: '30vh', textAlign: 'center' }}>
                    <Typography>No session created</Typography>
                </Grid>
            )}
        </Grid>
    );
}

export default UserSessionMenu;
