import React, { useState, useEffect } from 'react';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  Appointments,
  Toolbar,
  ViewSwitcher,
  MonthView,
  DayView,
  DateNavigator,
  TodayButton,
  CurrentTimeIndicator,
  AppointmentTooltip,
  AppointmentForm,
  ConfirmationDialog,
  DragDropProvider,
  AllDayPanel
} from '@devexpress/dx-react-scheduler-material-ui';
import { Grid, Box, Button, TextField, Backdrop, SpeedDial, SpeedDialAction, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, IconButton, Typography, Select, MenuItem } from '@mui/material';
import useEventDataStore from '../../store/SchedulerData';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { addAppointment, updateAppointment, deleteAppointment } from '../../api/scheduler_related_services'
import userSess from '../../store/user_session_control'
import { fetchProfile } from '../../api/profile_related_service';
import { useNavigate } from 'react-router';
import useProfile from "../../store/profile_data";
import { fetchUserMentSession } from '../../api/session_service';
import Cookies from 'js-cookie';

const actions = [
  { icon: <EventNoteIcon />, name: 'Add Event' },
  // { icon: <EditNoteIcon />, name: 'Edit' },
];

const initialEventState = {
  title: '',
  startDate: '',
  endDate: '',
  moreInfo: '',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#2f3348',
    },
  },
});
const dragDisableIds = new Set([3, 8, 10, 12]);
const allowDrag = ({ id }) => !dragDisableIds.has(id);
// const appointmentComponent = (props) => {
//   if (allowDrag(props.data)) {
//     return <Appointments.Appointment {...props} />;
//   } return <Appointments.Appointment {...props} style={{ ...props.style, cursor: 'not-allowed' }} />;
// };
const TextEditor = (props) => {
  if (props.type === 'multilineTextEditor') {
  }
  return <AppointmentForm.TextEditor {...props} />;
};

const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
  const onCustomFieldChange = (nextValue) => {
    onFieldChange({ customField: nextValue });

  };

  return (
    <>
      <AppointmentForm.BasicLayout
        appointmentData={appointmentData}
        onFieldChange={onFieldChange}
        {...restProps}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mt={2} mb={2} />
          </Grid>
          <Grid item xs={12}>
            <AppointmentForm.Label text="Add Location " type="title" />
            <AppointmentForm.TextEditor
              value={appointmentData.customField}
              onValueChange={onCustomFieldChange}
              placeholder="Add location of your meeting"
            />
          </Grid>
        </Grid>
      </AppointmentForm.BasicLayout>
    </>
  );
};



const SchedulerComponent = () => {
  const { appointments = [], setAppointments, mentorSessions = [], setMentorSessions } = useEventDataStore();// Get the setAppointments function from Zustand
  // const { user_token } = userSess()
  const user_token = Cookies.get("connectedMe")
  useEffect(() => {
    const fetchMentorData = async () => {
      const mentorSessionsData = await fetchUserMentSession({ token: user_token });
      setMentorSessions(mentorSessionsData);
    };

    fetchMentorData();
  }, [setMentorSessions, user_token]);
  const combinedData = [
    ...appointments,
    ...mentorSessions.map(session => ({
      ...session,
      color: 'green', // Set green color for mentor sessions
      isMentorSession: true // Mark as mentor session
    }))
  ];
  const [currentViewName, setCurrentViewName] = useState('Day');
  const [isEditingEvent, setIsEditingEvent] = useState(false);
  const [newEvent, setNewEvent] = useState({ ...initialEventState });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useProfile()
  const [isDialogOpen, setIsDialogOpen] = useState(false);


  const commitChanges = async ({ added, changed, deleted }) => {
    let newData = [...appointments];
    const currentDateTime = new Date();
    if (added) {
      const addedStartDate = new Date(added.startDate);
      if (!added.title || added.title.trim() === '') {
        alert('Title is required.');
        return;
      }
      if (addedStartDate < currentDateTime) {
        alert('Start date cannot be in the past.');
        return;
      }
      if (added.startDate >= added.endDate) {
        alert('Start date must be less than end date.');
        return;
      }
      const randomId = Math.floor(Math.random() * 9000) + 1000;
      const newAppointment = { id: randomId, ...added };

      if (added && added.customField && added.customField.trim()) {
        newData = [...newData, newAppointment];
      } else {
        alert('Location is required.');
        return;
      }
      const profile = await fetchProfile({ "user_token": user_token });
      const hasNonEmptyExperience = profile.professional_experience && profile.professional_experience.some(exp => exp.jobTitle || exp.organization || exp.startYear || exp.endYear);
      if (!hasNonEmptyExperience) {
        setIsDialogOpen(true);
        return;
      }
      let new_event = newData[newData.length - 1];
      addAppointment({ ...new_event, 'token': user_token });
      setAppointments(newData);
      setAppointments(newData);;
    }

    if (changed) {
      newData = newData.map((appointment) => {
        if (changed[appointment.id]) {
          const updatedAppointment = { ...appointment, ...changed[appointment.id] };
          if (updatedAppointment.startDate < currentDateTime) {
            alert('Start date cannot be in the past.');
            return appointment;
          }
          if (updatedAppointment.startDate >= updatedAppointment.endDate) {
            alert('Start date must be less than end date.');
            return appointment;
          }
          return updatedAppointment;
        }
        return appointment;
      });

      const updatedAppointment = newData.find(appointment => changed[appointment.id]);
      updateAppointment({ ...updatedAppointment, 'token': user_token });
    }

    if (deleted !== undefined) {
      newData = newData.filter((appointment) => appointment.id !== deleted);
      deleteAppointment({ id: deleted, token: user_token });
    }
    setAppointments(newData)
  };
  const handleDialogConfirm = async () => {
    setIsDialogOpen(false);
    const profile = await fetchProfile({ "user_token": user_token });
    setUser(profile);
    navigate("/d/MyProfile");
  };
  const currentViewNameChange = (currentViewName) => {
    setCurrentViewName(currentViewName);
  };

  const handleEditEvent = (event) => {
    setIsEditingEvent(true);
    setNewEvent(event);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveEvent = () => {
    let updatedData = [];

    if (isEditingEvent) {
      const eventIndex = updatedData.findIndex((item) => item.id === newEvent.id);
      if (eventIndex !== -1) {
        updatedData[eventIndex] = newEvent;
      }
    } else {
      newEvent.id = Date.now();
      updatedData.push(newEvent);
    }

    setAppointments(updatedData);
    setIsEditingEvent(false);
    setNewEvent({ ...initialEventState });
    closePopup();
    // Update Zustand store with new appointments
    setAppointments(updatedData);
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const Appointment = ({ data, ...restProps }) => (
    <Appointments.Appointment
      {...restProps}
      data={data}
      style={{
        ...restProps.style,
        backgroundColor: data.isMentorSession ? 'green' : undefined, // Apply green color to mentor sessions
        cursor: data.isMentorSession ? 'not-allowed' : 'pointer' // Disable pointer for mentor sessions
      }}
    />
  );
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isDialogOpen}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update Professional Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You should update your professional details.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogConfirm} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={isPopupOpen} sx={{ backgroundColor: 'white', zIndex: 0 }} onClick={closePopup} />
      <Dialog open={isPopupOpen} onClose={closePopup}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <IconButton onClick={closePopup}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end" sx={{ marginBottom: 2 }}>
              <Button variant="contained" onClick={handleSaveEvent}>
                Save
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight="bold">
                Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Title"
                name="title"
                value={newEvent.title}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Start Date"
                type="datetime-local"
                name="startDate"
                value={newEvent.startDate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="End Date"
                type="datetime-local"
                name="endDate"
                value={newEvent.endDate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight="bold">
                More Information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Notes"
                name="moreInfo"
                value={newEvent.moreInfo}
                onChange={handleInputChange}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        sx={{ position: 'fixed', bottom: '20px', right: '40px', color: 'red' }}
        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => {
              if (action.name === 'Add Event') openPopup();
            }}
          />
        ))}
      </SpeedDial>
      <Box height={"80vh"}>
        <Scheduler data={combinedData} height="100%">
          <ViewState
            defaultCurrentDate={new Date().toISOString()}
            currentViewName={currentViewName}
            onCurrentViewNameChange={currentViewNameChange}
          />
          <WeekView startDayHour={7} endDayHour={20} />
          <WeekView
            name="work-week"
            displayName="Work Week"
            excludedDays={[0, 6]}
            startDayHour={8}
            endDayHour={19}
          />
          <MonthView />
          <DayView startDayHour={0} endDayHour={24} />
          <Toolbar />
          <ViewSwitcher />
          <Appointments appointmentComponent={Appointment} />
          <DateNavigator />
          <EditingState onCommitChanges={commitChanges} />
          <IntegratedEditing />
          <TodayButton />
          <AppointmentTooltip showCloseButton showOpenButton />
          <AllDayPanel />
          <DragDropProvider
            allowDrag={allowDrag}
          />
          <AppointmentForm
            basicLayoutComponent={BasicLayout}
            textEditorComponent={TextEditor} />
          <ConfirmationDialog />
          <CurrentTimeIndicator />
        </Scheduler>
      </Box>
    </ThemeProvider>
  );
};

export default SchedulerComponent;