import React, { useState } from 'react';
// import { TextField, Button, Grid, Typography } from '@material-ui/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Avatar, Grid, InputAdornment, Paper, Stack, TextField, Button, Typography, Alert } from '@mui/material';

import LockIcon from "@mui/icons-material/Lock";
import { blue } from '@mui/material/colors';
import { validateEmail, validateOTP } from '../../api/user_related_service';

const Verify_otp = (props) => {
    const { emailInput } = useParams();
    const [OTPinput, setOTPinput] = useState([0, 0, 0, 0]);
    const [errorMessage, setErrorMessage] = useState()
    // const [verified, setVerified] = useState(false)
    const [timerCount, setTimer] = React.useState(60);
    const [disable, setDisable] = useState(true);
    const navigate = useNavigate();

    const handleClick = async () => {
        try {
            const otp = parseInt(OTPinput.join(""))
            const response = await validateOTP({
                email: emailInput,
                otp: otp
            })
            if (response["message"] == "OTP is valid") {
                console.log(response["message"]);
                setErrorMessage()
                navigate(`/:forgot_pass/${emailInput}/:generate_pass`)

            } else {
                console.log(response["message"]);
                setErrorMessage(response["message"])
                console.log(errorMessage);
            }
            console.log(errorMessage);
        } catch (error) {
            console.log(error.message);
        }
    }

    const handleResendOTP = async () => {
        try {
            await validateEmail({
                email: emailInput,
                // flag_verify: "New OTP"
            });
            setTimer(60);
            setDisable(true);
        } catch (error) {
            console.error('Error fetching user:', error);
            return;
        }
    }

    React.useEffect(() => {
        let interval = setInterval(() => {
            setTimer((lastTimerCount) => {
                lastTimerCount <= 1 && clearInterval(interval);
                if (lastTimerCount <= 1) setDisable(false);
                if (lastTimerCount <= 0) return lastTimerCount;
                return lastTimerCount - 1;
            });
        }, 1000); //each count lasts for a second
        //cleanup the interval on complete
        return () => clearInterval(interval);
    }, [disable]);
    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Paper style={{ height: "100%", padding: "20px" }}>
                        <h2>Advertisement</h2>
                        <p>Ad content goes here...</p>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper style={{ height: "100%", padding: "20px" }}>
                        <Grid container alignItems="center" direction="column">
                            <Avatar sx={{ bgcolor: "#2F3348" }}>
                                <LockIcon />
                            </Avatar>
                            <Typography
                                variant="h5"
                                align="center"
                                sx={{ marginBottom: "1rem" }}
                            >
                                OTP-Verification
                            </Typography>
                            <Stack sx={{ marginBottom: "1rem" }}>
                                <Typography style={{ color: '#808080' }}>{`We have send a 4-digit OTP to your Email ${emailInput}`}</Typography>
                            </Stack>
                        </Grid>
                        <Typography variant="body1" margin={'10px'}>
                            Enter 4-digit OTP
                        </Typography>
                        <Stack display={"flex"} margin={"5px"} >
                            <Stack display={"flex"} flexDirection={"row"} justifyContent={'space-around'} sx={{ marginBottom: "2rem" }}>
                                <TextField
                                    variant="outlined"
                                    inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                    style={{ width: '70px' }}
                                    // value={otp}
                                    onChange={(e) =>
                                        setOTPinput([
                                            e.target.value,
                                            OTPinput[1],
                                            OTPinput[2],
                                            OTPinput[3],
                                        ])
                                    }
                                // error={error}
                                />
                                <TextField
                                    variant="outlined"
                                    inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                    style={{ width: '70px' }}
                                    // value={otp}
                                    onChange={(e) =>
                                        setOTPinput([
                                            OTPinput[0],
                                            e.target.value,
                                            OTPinput[2],
                                            OTPinput[3],
                                        ])
                                    }
                                // error={error}
                                />
                                <TextField
                                    variant="outlined"
                                    inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                    style={{ width: '70px' }}
                                    // value={otp}
                                    onChange={(e) =>
                                        setOTPinput([
                                            OTPinput[0],
                                            OTPinput[1],
                                            e.target.value,
                                            OTPinput[3],
                                        ])
                                    }
                                // error={error}
                                />
                                <TextField
                                    variant="outlined"
                                    inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                    style={{ width: '70px' }}
                                    // value={otp}
                                    onChange={(e) =>
                                        setOTPinput([
                                            OTPinput[0],
                                            OTPinput[1],
                                            OTPinput[2],
                                            e.target.value,
                                        ])
                                    }
                                // error={error}
                                />
                            </Stack>

                            {errorMessage && (<Alert severity="error">
                                <Stack display={'flex'} direction={'row'}>
                                    {errorMessage}
                                </Stack>
                            </Alert>)}


                            <Stack display={"flex"} flexDirection={"row"} justifyContent={'space-around'} margin={"10px"}>
                                {/* <Link to={`/:forgot_pass/:verify_otp/:generate_pass`}> */}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleClick}
                                    >
                                        Verify
                                    </Button>
                                {/* </Link> */}
                                <Link to={`/`}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                    // onClick={handleVerify}
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                            </Stack>
                        </Stack>
                        <Stack display={"flex"} flexDirection={"row"} justifyContent={'center'} margin={'20px'}>
                            <Typography>Didn't recieve code?</Typography>{" "}
                            <span

                                style={{
                                    color: disable ? "gray" : "blue",
                                    cursor: disable ? "none" : "pointer",
                                    textDecorationLine: disable ? "none" : "underline",
                                }}
                                onClick={handleResendOTP}
                            >
                                {disable ? `Resend OTP in ${timerCount}s` : "Resend OTP"}
                            </span>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid >

        </>
    )
}

export default Verify_otp
