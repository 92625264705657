import { ArrowBackOutlined } from '@mui/icons-material';
import { Box, Divider, IconButton, Stack, Typography, Avatar } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import userSess from '../../store/user_session_control';
import useUserPrefSess from '../../store/dash_session';
import Cookies from 'js-cookie';

const Card_info = () => {
    const { meeting_id } = useParams();
    const navigate = useNavigate();
    const user_token = Cookies.get("connectedMe")
    const { sess, setuseUserPrefSess } = useUserPrefSess();
    const [info, setInfo] = useState({});

    // Fetch session information based on meeting_id
    useEffect(() => {
        const updatedSess = sess.filter(s => s.meeting_id == meeting_id);
        setInfo(updatedSess[0]);
    }, [meeting_id], sess);
    console.log("information", info)
    const startDate = info?.startDate || '';
    const date = new Date(startDate);
    const formattedDate = startDate
        ? date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
        : 'Start Date';
    const formattedTime = startDate
        ? date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' })
        : 'Start Time';

    return (
        <>
            <Typography variant="h4" sx={{ fontWeight: 'medium', paddingLeft: '15px', marginTop: '15px', color: '#2f3348' }}>
                <IconButton aria-label="back" size="large" onClick={() => navigate(-1)}>
                    <ArrowBackOutlined fontSize="large" />
                </IconButton>
                About Session
            </Typography>
            <Divider />

            <Box
                sx={{
                    maxWidth: '800px',
                    margin: '0 auto',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'white',
                    mt: 2,
                }}
            >
                <Stack
                    direction="row"
                    gap={1}
                    sx={{
                        height: '16%',
                        width: '100%',
                        background: '#1d1933',
                        alignItems: 'center',
                        padding: '10px 0',
                        borderRadius: '8px',
                    }}
                >
                    <Avatar
                        sx={{ margin: '10px' }}
                        alt={info?.first_name || 'Session Author'}
                        src={info?.image || '/path/to/placeholder/avatar'}
                    />
                    <Stack>
                        <Typography variant="body2" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                            {info?.Session_author || 'Author Name'}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#ffffff' }}>
                            {info?.Session_auth_job_title || 'Job Title'}
                        </Typography>
                    </Stack>
                </Stack>

                <Box sx={{ textAlign: 'left', mt: 3 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                        Date: <Typography component="span">{formattedDate}</Typography>
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                        Time: <Typography component="span" >{formattedTime}</Typography>
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                        Title: <Typography component="span" >{info?.title || 'Session Title'}</Typography>
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                        Location: <Typography component="span" >{info?.location_name || 'Location Name'}</Typography>
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '10px', }}>
                        Description: <Typography component="span">{info?.Desc || 'No Description Provided'}</Typography>
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default Card_info;
