import { Button, Dialog, DialogTitle, Slide, Stack, Typography } from '@mui/material'
import React from 'react'

const Confirmation = (props) => {

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    return (
        <>
            <Dialog aria-describedby="alert-dialog-slide-description" TransitionComponent={Transition} open={props.open} onClose={props.onClose} maxWidth="lg"

            >
                <Typography variant="h6" sx={{ margin: "20px 0 20px 0", padding: '10px 20px 10px 20px' }}>Are you Sure?</Typography>
                <Stack sx={{ margin: "10px 0 10px 0", padding: '10px 20px 10px 20px' }} direction={'row'} style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <Button
                        variant="contained"
                        // onClick={handleCancelClick}
                        sx={{
                            padding: ' 10px 35px',
                            background: "#2f3348",
                            color: "white",
                            marginRight: "20px",
                            "&:hover": {
                                background: "#2f3348",

                            },
                        }}
                        onClick={
                            props.submit
                        }
                    // onClick={() => {
                    //     props.publish();
                    //     props.submit();
                    // }
                    // }

                    >
                        Yes
                    </Button>

                    <Button
                        variant="contained"
                        // onClick={handleCancelClick}
                        sx={{
                            padding: ' 10px 35px',
                            background: "#2f3348",
                            color: "white",
                            "&:hover": {
                                background: "#2f3348",
                            },
                        }}
                        onClick={props.onClose}

                    >
                        No
                    </Button>

                </Stack>
            </Dialog>
        </>
    )
}

export default Confirmation
