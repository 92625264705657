import * as React from "react";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Horizontal_slider.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useUserPrefSess from "../../store/dash_session";
import { addSession } from "../../api/session_service";
import userSess from "../../store/user_session_control";
import useUserMentorSess from "../../store/user_mentor_session";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { Opacity } from "@mui/icons-material";
import Cookies from 'js-cookie';

function HoriSlide(props) {
  const PreviousBtn = (props) => {
    const { className, onaction } = props;
    return (
      <div className={className} onClick={onaction}>
        <ArrowBackIosNewIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
      </div>
    );
  };
  const NextBtn = (props) => {
    const { className, onaction } = props;
    return (
      <div className={className} onClick={onaction}>
        <ArrowForwardIosIcon style={{ color: "#f65a4c", fontSize: "30px" }} />
      </div>
    );
  };

  const { sess, setuseUserPrefSess } = useUserPrefSess();
  // const { user_token } = userSess()
  const user_token = Cookies.get("connectedMe")

  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const slideshow = isMobileScreen ? 1 : isSmallScreen ? 3 : 5;
  const [open, setOpen] = React.useState(false);

  const handle_book = async (met_id) => {
    await addSession({ meeting_id: met_id, token: user_token });
    setOpen(true);
  };
  const handleCardRemove = (met_id) => {
    const updatedSess = sess.filter((s) => s.meeting_id !== met_id);
    setuseUserPrefSess(updatedSess);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const convertToLocalDate = (utrDateString) => {
    const utcDate = new Date(utrDateString);
    const localDate = utcDate.toLocaleDateString();
    const localTime = utcDate.toLocaleTimeString();
    return `${localDate} ${localTime}`;
  };
  const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(1),
    border: "1px solid #2f3348",
    borderRadius: theme.spacing(1),
    boxShadow: "0 3px 6px 0 rgba(0,0,0,0.16), 0 3px 6px 0 rgba(0,0,0,0.23)",
    width: isMobileScreen ? "280px" : "400px",
    margin: isMobileScreen ? "0 10px" : "0 20px", // Add margin for mobile view
    // transition: 'transform 0.3s, opacity 0.3s',

    // '&:hover': {
    //     // no styles needed here, as we want the hovered card to remain unchanged
    // },

    // '&:hover ~ &': {
    //     opacity: 0.7,
    //     transform: 'scale(0.85)'
    //   }

    /* Sibling card styles (applied conditionally) */
    // '&.zoom - out': {
    //     transform: 'scale(0.9)', /* Zoom out for non-hovered cards */
    // },
  }));

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideshow,
    slidesToScroll: 1,
    prevArrow: (
      <PreviousBtn
        onaction={() => {
          if (props.page === 0) {
            return;
          } else {
            props.setPage(props.page - 1);
          }
        }}
      />
    ),
    nextArrow: (
      <NextBtn
        onaction={() => {
          props.setPage(props.page + 1);
        }}
      />
    ),
    centerMode: isMobileScreen, // Enable center mode to make the next card visible on mobile
    centerPadding: isMobileScreen ? "40px" : "0px", // Add padding only for mobile to show part of the next card
  };

  return (
    <Box
      sx={{
        width: "85vw",
        marginLeft: isMobileScreen ? "0px" : "60px",
      }}
    >
      <Slider {...settings}>
        {sess.length === 0 ? (
          <StyledCard
            sx={{
              width: "400px",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                position: "relative",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <CheckCircleIcon
                sx={{ fontSize: 64, color: "green", marginBottom: "20px" }}
              />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#333", marginBottom: "10px" }}
              >
                Done for the day
              </Typography>
            </Box>
          </StyledCard>
        ) : (
          sess.map(
            (s) => (
              // <Link to={`/d/dashboard/cardinfo`} underline="hover" color="inherit"  >
              <StyledCard sx={{ width: "240px" }} key={s.meeting_id}>
                <Stack sx={{ margin: "10px" }} gap={1}>
                  <Link
                    to={`/d/dashboard/${s.meeting_id}`}
                    underline="hover"
                    color="inherit"
                  >
                    <Box sx={{ height: "50px" }}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {s.title.length > 35
                          ? `${s.title.substring(0, 35)}...`
                          : s.title}
                      </Typography>
                    </Box>
                  </Link>

                  <Box sx={{ height: "80px" }}>
                    <Typography variant="subtitle1">
                      {s.Desc.length > 40
                        ? `${s.Desc.substring(0, 40)}...`
                        : s.Desc}
                    </Typography>
                  </Box>
                  <Typography variant="overline" sx={{ color: "#f65a4c" }}>
                    {convertToLocalDate(s.startDate)}
                  </Typography>
                  <Button
                    key={s.meeting_id}
                    variant="contained"
                    onClick={() => {
                      handle_book(s.meeting_id).then(() => {
                        handleCardRemove(s.meeting_id);
                        props.refreshData();
                      });
                    }}
                  >
                    BOOK
                  </Button>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  sx={{
                    height: "16%",
                    width: "100%",
                    background: "#1d1933",
                  }}
                >
                  <Avatar
                    sx={{ margin: "5px" }}
                    src={s.image ? s.image : undefined}
                  />
                  <Stack>
                    <Typography variant="body2" sx={{ color: "#ffffff" }}>
                      {s.Session_author}
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#ffffff" }}>
                      {s.Session_auth_job_title}
                    </Typography>
                  </Stack>
                </Stack>
              </StyledCard>
            )
            // </Link>
          )
        )}
      </Slider>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Session Booked Successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default HoriSlide;
