// import { Search } from '@mui/icons-material'
import { Alert, Button, Checkbox, Container, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputBase, InputLabel, Menu, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ArrowBackOutlined, MoreVert } from '@mui/icons-material';

import SendIcon from '@mui/icons-material/Send';
import {  postFeedback_question } from '../../api/session_service';
import Confirmation from '../Optionbar/Confirmation';
import Cookies from 'js-cookie';

const Admin_Feedback = () => {
    const [dialogBox, setDialogBox] = useState(false)
    const [question, setQuestion] = useState('');
    const [questionType, setQuestionType] = useState('');
    const [questionsList, setQuestionsList] = useState([]);
    const [choices, setChoices] = useState([]); // For storing multiple choice options
    const [newChoice, setNewChoice] = useState('');
    const [showsubmit, setShowSubmit] = useState(false);
    const [showsearchresults, setShowSearchResults] = useState(true)
    const [isdefault, setIsdefault] = useState(false);
    const [meetingData, setMeetingData] = useState({ questionsList: [], questionType });
    const user_token = Cookies.get("connectedMe")
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [editIndex, setEditIndex] = useState(null); // Track index of the question being edited
    const [editQuestion, setEditQuestion] = useState(''); // Track edited question text
    const [editChoices, setEditChoices] = useState([]);
    const [confirmatonpopup, setConfirmationpopup] = useState(false);
    const [formtitle, setFormtitle] = useState("");
    const [openalert, setOpenAlert] = React.useState(false);
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);

    };

    const open = Boolean(anchorEl);
    const handleMenuClick = (event, index) => {
        console.log(open)
        setAnchorEl(event.currentTarget);
        setSelectedIndex(index);
    }
    const handleClose = () => {
        setAnchorEl(null);
        setSelectedIndex(null);
    };
    const handleDelete = () => {
        const newQuestionsList = questionsList.filter((_, idx) => idx !== selectedIndex);
        setQuestionsList(newQuestionsList);
        handleClose();

    }

    const handleClosepopup =() =>{
        setConfirmationpopup(false)
    }


    const handleDialogClose = () => {
        setDialogBox(false);
        setChoices([]);

    }

    const handleEdit = () => {
        const questionToEdit = questionsList[selectedIndex];
        setEditIndex(selectedIndex);
        setEditQuestion(questionToEdit.question);
        setEditChoices(questionToEdit.choices || []);
        setQuestionType(questionToEdit.type);
        setDialogBox(true);
        handleClose();
    };

    const handleAddChoice = () => {
        // Add new choice to the choices list
        setChoices(prev => [...prev, newChoice]);
        setNewChoice('');
    };

    const handleSession = () => {
        setFormtitle("Session Form")
        setDialogBox(true)
        setShowSearchResults(false)
    }

    const handlePlatform = () =>{
        setFormtitle("Platform Form")
        setDialogBox(true)
        setShowSearchResults(false)
    }
    const handleAddQuestion = () => {

        // const newQuestion = {
        //     question,
        //     type: questionType,
        //     choices: questionType === "multiple_choice" ? choices : [],
        //     isdefault:isdefault,
        // };

        // // Update questionsList state
        // setQuestionsList(prevList => [...prevList, newQuestion]);

        // setMeetingData({
        //     ...meetingData,
        //     questionsList: [...questionsList, newQuestion],

        // });
        // console.log("meetingdata:"+JSON.stringify(meetingData))
        const newQuestion = {
            question,
            type: questionType,
            choices: questionType === "multiple_choice" ? choices : [],
            isdefault: isdefault,
        };

        if (editIndex !== null) {
            setQuestionsList((prevList) =>
                prevList.map((q, idx) => (idx === editIndex ? newQuestion : q))
            );
            setEditIndex(null);
        } else {
            setQuestionsList((prevList) => [...prevList, newQuestion]);
        }

        setMeetingData({
            ...meetingData,
            questionsList: [...questionsList, newQuestion],

        });
        console.log("meetingdata:" + JSON.stringify(meetingData))

        handleDialogClose()
        setQuestion('');
        setQuestionType('');
        setChoices([]);
        setShowSubmit(true)
        setIsdefault(false);
        handleDialogClose();
    };

    // console.log("meetingdata:" + JSON.stringify(meetingData))


    const handleBackButtonClick = () => {
        setQuestion('');
        setQuestionType('');
        setChoices([]);
        setQuestionsList([]);
        setShowSearchResults(true);
        setShowSubmit(false);
    };

    const handleSubmit = async() =>{

        const response = await postFeedback_question({
            user_token: user_token,
            form_title: formtitle,
            meeting_data:meetingData
        })
        setConfirmationpopup(false);
        setOpenAlert(true);
        handleBackButtonClick();
        
    }


    return (

        <Grid style={{ height: "100vh" }}>
        <Snackbar open={openalert} autoHideDuration={3000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert
                        onClose={handleAlertClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Form created successfully!!
                    </Alert>
                </Snackbar>
            <Stack sx={{ display: "flex", flexDirection: "row", margin: "20px 0 0 20px" }}>
                {!showsearchresults && (
                    <>
                        <Stack >
                            <IconButton aria-label="back" size="large"
                                onClick={handleBackButtonClick}>
                                <ArrowBackOutlined fontSize="large" />
                            </IconButton>
                        </Stack>
                    </>
                )

                }

                <Stack>
                    <Typography variant="h4" >
                        Create Feedback Form.
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{ color: "grey" }}
                    >
                        Enhance Mentor & Employee Interaction through Feedback.
                    </Typography>
                </Stack>

            </Stack>

            {/* display={"flex"} alignItems={"center"} */}
            <Stack >
                <Stack sx={{ margin: '50px', display: "flex", flexBasis: "30%" }}>
                    {showsearchresults && (
                        <>
                            <Paper sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '15px', padding: '5px', alignItems: 'center' }}>
                                <Stack display={"flex"} flexDirection={"column"}>
                                    <Typography sx={{ margin: "10px 0 5px 20px" }} variant='h5'>Platform Form</Typography>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ margin: "0 0 20px 20px", color: "grey" }}
                                    >
                                        Make Our Platform Better: create feedback form.
                                    </Typography>
                                </Stack>
                                <Divider orientation="vertical" />
                                <Button
                                    sx={{
                                        borderRadius: '100px',
                                        width: '50px',
                                        height: '50px',
                                        minWidth: '50px',
                                        minHeight: '50px',
                                    }}
                                    variant="contained"
                                    onClick={() => handlePlatform()}
                                ><SendIcon /></Button>
                            </Paper>

                            <Paper sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '15px', padding: '5px', alignItems: 'center' }}>
                                <Stack display={"flex"} flexDirection={"column"}>
                                    <Typography sx={{ margin: "10px 0 5px 20px" }} variant='h5'>Session Form</Typography>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ margin: "0 0 20px 20px", color: "grey" }}
                                    >
                                        Make Our Session Better: create feedback form.
                                    </Typography>
                                </Stack>
                                <Divider orientation="vertical" />
                                <Button
                                    sx={{
                                        borderRadius: '100px',
                                        width: '50px',
                                        height: '50px',
                                        minWidth: '50px',
                                        minHeight: '50px',
                                    }}
                                    variant="contained"
                                    onClick={() => handleSession()}
                                ><SendIcon /></Button>
                            </Paper>
                        </>
                    )}


                    {showsubmit && (
                        <Stack sx={{ display: "flex", alignItems: "center" }} >
                            <Stack sx={{ width: "75%", margin: "15px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Button variant="contained" onClick={()=>{setConfirmationpopup(true)}} >
                                    Submit Form
                                </Button>
                                <Button variant="contained" onClick={() => setDialogBox(true)}>
                                    Add Question
                                </Button>
                            </Stack>


                            <Stack sx={{ margin: "20px", width: "75%" }}>
                                <Paper>
                                    <Typography variant="h5" sx={{ textAlign: 'center', margin: "20px 0px 0 20px" }}>
                                        Feedback Form
                                    </Typography>
                                    <Divider />
                                    {questionsList.map((q, index) => (
                                        <React.Fragment key={index}>
                                            {q.type === "text" && (
                                                <Stack sx={{ display: "flex", flexDirection: "column", margin: "5px", padding: "25px" }}>
                                                    <Stack direction="row" justifyContent={'space-between'}>
                                                        <Typography>Ques.{index + 1} <strong>{q.question}</strong> </Typography>
                                                        <Tooltip title="More Options">
                                                            <IconButton
                                                                size="large"
                                                                aria-label="display more actions"
                                                                edge="end"
                                                                color="inherit"
                                                                onClick={(e) => handleMenuClick(e, index)}
                                                            >
                                                                <MoreVert />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'basic-button',
                                                            }}
                                                        >
                                                            <MenuItem onClick={handleEdit}>Edit</MenuItem>
                                                            <MenuItem onClick={handleDelete}>Delete</MenuItem>

                                                        </Menu>
                                                    </Stack>
                                                    <TextField
                                                        multiline
                                                        disabled
                                                        rows={2}
                                                        fullWidth
                                                        margin="normal"
                                                        // label={q.question} // Set the label to the question text
                                                        variant="outlined"
                                                    />
                                                </Stack>
                                            )}
                                            {q.type === "multiple_choice" && (
                                                <Stack sx={{ display: "flex", flexDirection: "column", margin: "5px", padding: "25px" }}>
                                                    <FormControl component="fieldset" margin="normal">
                                                        <Stack direction="row" justifyContent={'space-between'}>
                                                            <Typography>Ques.{index + 1} <strong>{q.question}</strong> </Typography>
                                                            <Tooltip title="More Options">
                                                                <IconButton
                                                                    size="large"
                                                                    aria-label="display more actions"
                                                                    edge="end"
                                                                    color="inherit"
                                                                    onClick={(e) => handleMenuClick(e, index)}
                                                                >
                                                                    <MoreVert />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Menu
                                                                id="basic-menu"
                                                                anchorEl={anchorEl}
                                                                open={open}
                                                                onClose={handleClose}
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'basic-button',
                                                                }}
                                                            >
                                                                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                                                                <MenuItem onClick={handleDelete}>Delete</MenuItem>

                                                            </Menu>
                                                        </Stack>
                                                        <RadioGroup>
                                                            {q.choices.map((choice, idx) => (
                                                                <FormControlLabel
                                                                    key={idx}
                                                                    value={choice}
                                                                    disabled
                                                                    control={<Radio />}
                                                                    label={choice}
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            )}
                                            {/* You can add other types of questions rendering here */}
                                        </React.Fragment>

                                    ))}
                                </Paper>
                            </Stack>
                        </Stack>)}
                    {/* <Stack>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search Meeting id..."
                                    inputProps={{ 'aria-label': 'search' }}
                                    value={input}
                                    onChange={(e) => handleChange(e.target.value)}
                                />
                            </Search>

                        </Stack> */}


                    {/* {showsearchresults && (
                        <Container sx={{ width: "100%", display: "flex", flexDirection: "column", maxHeight: "100px", marginTop: "4px", overflowY: "scroll", border: "0px solid black", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                            {searchresults.map((result, id) => {
                                return <Button keu={id} onClick={() => handleMeetingId({
                                    meeting_id: result.meeting_id,
                                    form_id: result.form_id,
                                    session_title: result.session_title,
                                    meeting_uuid: result.meeting_uuid
                                })}>
                                    {result.meeting_id}
                                </Button>
                            })}
                        </Container>)} */}
                </Stack>


            </Stack>

            {confirmatonpopup && (
                <Confirmation submit={handleSubmit} open={confirmatonpopup} onClose={handleClosepopup}/>
            )}
            
            <Dialog open={dialogBox} disableBackdropClick>
                <DialogTitle>Add Question</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the details for the feedback question.
                    </DialogContentText>

                    {/* Add form elements like TextField here */}
                    <TextField
                        label="Question"
                        fullWidth
                        margin="normal"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                    />

                    {/* Question Type Select */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel >Question Type</InputLabel>
                        <Select
                            labelId="question-type-label"
                            value={questionType}
                            label="Question Type"
                            onChange={(e) => setQuestionType(e.target.value)}
                        >
                            <MenuItem value="text">Text</MenuItem>
                            <MenuItem value="multiple_choice">Multiple Choice</MenuItem>
                        </Select>
                        <FormControlLabel control={<Checkbox />} label="Default" onClick={() => setIsdefault(true)} />
                    </FormControl>
                    {questionType === 'multiple_choice' && (
                        <>
                            <Stack direction="row" spacing={1} marginBottom={2}>
                                <TextField
                                    label="Add a Choice"
                                    fullWidth
                                    value={newChoice}
                                    onChange={(e) => setNewChoice(e.target.value)}
                                />
                                {newChoice.length > 0 && (
                                    <Button variant="outlined" onClick={handleAddChoice}>
                                        Add Choice
                                    </Button>)
                                }

                            </Stack>

                            {/* Display the list of added choices */}
                            {choices.length > 0 && (
                                <Stack>
                                    {choices.map((choice, index) => (
                                        <Typography key={index}>
                                            {index + 1}. {choice}
                                        </Typography>
                                    ))}
                                </Stack>
                            )}
                        </>
                    )}

                    {/* Add Button */}
                    <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
                        <Button variant="contained" onClick={handleAddQuestion}>
                            Add Question
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </Grid>


    )
}

export default Admin_Feedback
