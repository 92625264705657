import network_conn from "../utils/network_config";
export const addJob = async (job) => {
  console.log(job)
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/post_job",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(job),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      console.log("Data sent successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to send data: " + response.statusText);
    }
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};

export const addMentee = async (Mentee) => {
  console.log(Mentee)
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/insert_job_application",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Mentee),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      console.log("Mentee sent successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to add Mentee: " + response.statusText);
    }
  } catch (error) {
    console.error("Error add Mentee:", error);
    throw error;
  }
};

export const getJob = async (user_token) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "sponsor_service/fetch_sponsor",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "user_token": user_token,

        }),
      }
    );
    if (response.ok) {
      const responseData = await response.json();
      console.log("Data sent successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to send data: " + response.statusText);
    }
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};

export const update_status = async (status) => {
  console.log(status)
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/update_status",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(status),
      }
    );
    let responseData =null;
    if (response.ok) {
      responseData=  await response.json();
      console.log("Status update successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to update status: " + response.statusText);
    }
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};

// export const getMentee = async (user_token) => {
//   try {
//     const response = await fetch(
//       network_conn.api_endpoint_v1 + "sponsor_service/fetch_mentee",
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           "user_token": user_token,

//         }),
//       }
//     );
//     if (response.ok) {
//       const responseData = await response.json();
//       console.log("Mentee fetch successfully:", responseData);
//       return responseData;
//     } else {
//       throw new Error("Failed to Fetch Mentee: " + response.statusText);
//     }
//   } catch (error) {
//     console.error("Error Fetching data:", error);
//     throw error;
//   }
// };

export const getMenteDetails = async (user_token) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/fetch_job_application",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "user_token": user_token,

        }),
      }
    );
    if (response.ok) {
      const responseData = await response.json();
      console.log("Mentee fetch successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to Fetch Mentee: " + response.statusText);
    }
  } catch (error) {
    console.error("Error Fetching data:", error);
    throw error;
  }
};

export const getSponsor = async (data) => {
  console.log(data)
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/fetch_sponsor_data",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      console.log("sponsor fetch successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to fetch sponsor: " + response.statusText);
    }
  } catch (error) {
    console.error("Error fetch sponsor:", error);
    throw error;
  }
};

export const updateJob = async (data) => {
  console.log(data)
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/update_jobdetails",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      console.log("update Job data successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to update Job: " + response.statusText);
    }
  } catch (error) {
    console.error("Error update Job details:", error);
    throw error;
  }
};

export const getJobByID = async (data) => {
  console.log(data)
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "job_advertisement/fetch_job_byid",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      console.log("Data fetch successfully:", responseData);
      return responseData;
    } else {
      throw new Error("Failed to fetch Job Data: " + response.statusText);
    }
  } catch (error) {
    console.error("Error fetch Job:", error);
    throw error;
  }
};
