import network_conn from "../utils/network_config";

export const sendMentorBookingMail = async (mail) => {
  try {
    const response = await fetch(
      network_conn.api_endpoint_v1 + "mail_service/mentor_mail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mail),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData);
    } else {
      throw new Error("Failed to send data: " + response.statusText);
    }
  } catch (error) {
    throw error;
  }
};
