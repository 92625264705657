import network_conn from "../utils/network_config";

export const delete_account = async (fetchreq) => {
  try {
    const response = await fetch(`${network_conn.api_endpoint_v1}delete_account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fetchreq),
    });

    if (!response.ok) {
      let errorDetail;
      try {
        errorDetail = await response.json();
      } catch (parseError) {
        throw new Error(`Failed to delete account: ${response.status} ${response.statusText} - Unable to parse error response.`);
      }
      throw new Error(`Failed to delete account: ${response.status} ${response.statusText} - ${errorDetail.message}`);
    }

    const data = await response.json();
    console.log("Account deletion response:", data);
    return data;

  } catch (error) {
    console.error(`Error deleting account for user ${fetchreq.user_uuid}:`, error.message);
    throw error;  // Re-throw the error so the caller can handle it
  }
};
