import network_conn from "../utils/network_config";
export const addProfile = async (profile) => {
    try {
        const response = await fetch(network_conn.api_endpoint_v1 + 'profile_service/insert_profile', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(profile)
        });

        if (response.ok) {
            const responseData = await response.json();
            console.log('Data sent successfully:', responseData);
        } else {
            throw new Error('Failed to send data: ' + response.statusText);
        }
    } catch (error) {
        console.error('Error sending data:', error);
        throw error;
    }
};
// // Function to fetch all profile data
export const fetchProfile = async (fetchreq) => {
    console.log(fetchreq);
    try {
        const response = await fetch(network_conn.api_endpoint_v1 + 'profile_service/fetch_profile', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(fetchreq),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch profile');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching profile:', error);
        throw error;
    }
};
export const addProfileImage = async (profile) => {
    try {
        const formData = new FormData();
        formData.append('token', profile.token);
        formData.append('image', profile.image);

        const response = await fetch(network_conn.api_endpoint_v1 + 'profile_service/insert_profile_img', {
            method: 'POST',
            body: formData
        });

        if (response.ok) {
            const responseData = await response.json();
            console.log('Data sent successfully:', responseData);
            return responseData;
        } else {
            throw new Error('Failed to send data: ' + response.statusText);
        }
    } catch (error) {
        console.error('Error sending data:', error);
        throw error;
    }
};

export const fetchProfileImage = async (profile) => {
    try {
        const response = await fetch(network_conn.api_endpoint_v1 + 'profile_service/fetch_profile_img', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(profile)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const imageBlob = await response.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        return imageUrl;
    } catch (error) {
        console.error('Error fetching profile image:', error);
        throw error;
    }
};

export const deleteProfileImg = async (deleteImg) => {
    try {

        const response = await fetch(network_conn.api_endpoint_v1+'profile_service/delete_profile_img', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(deleteImg),
        });
        if (!response.ok) {
            throw new Error('Failed to delete appointment');
        }
        const deletedAppointment = await response.json();
        return deletedAppointment;
    } catch (error) {
        console.error('Error deleting appointment:', error.message);
        return false;
    }
};

export const addSkill = async (profile) => {
    try {
        const response = await fetch(network_conn.api_endpoint_v1 + 'profile_service/insert_skills', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(profile)
        });

        if (response.ok) {
            const responseData = await response.json();
            console.log('Data sent successfully:', responseData);
        } else {
            throw new Error('Failed to send data: ' + response.statusText);
        }
    } catch (error) {
        console.error('Error sending data:', error);
        throw error;
    }
};