import React from 'react';
import { Typography, Stack } from '@mui/material';
import PlanPath from '../components/CareerPath/PlanPath';


function UserPlanPath() {
    return (
        <>
             <Stack>
                    <Typography variant="h4" sx={{ margin: '20px 0 0 20px' }} >
                        Career Path
                    </Typography>
                    <Typography variant="subtitle2" sx={{ margin: '0 0 20px 20px', color: 'grey' }}>
                        Forge Your Career Path: Navigate Opportunities and Reach New Heights
                    </Typography>
                </Stack>
            <PlanPath/>
        </>
    )
}

export default UserPlanPath;